import classes from "./style/PublishForm.module.scss";
import Tag from "../../../../components/base/Tag";
import BaseButton from "../../../../components/base/BaseButton";
import { useEffect, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { useParams } from "react-router-dom";
import { Option, RepoType } from "../../../../types/sharedTypes";
import { useDispatch, useSelector } from "react-redux";
import { loadingActions } from "../../../../store/loading-slice";
import { errorActions } from "../../../../store/error-slice";
import { RepoFactory } from "../../../../baseRepository/Factory";
import { ApplicationAction } from "../../store/application-slice";
import { RootState } from "../../../../store";
import Input from "../../../../components/base/Input";
import { diagramActions } from "../../../Diagram/store/diagram-slice";
import SelectInput from "../../../../components/base/SelectInput";
const ApplicationRepository = RepoFactory.get(RepoType.App);
const DiagramSRepository = RepoFactory.get(RepoType.Diagram);
interface MyComponent {
  onClose: (status: boolean) => void;
}

const PublishForm = (props: MyComponent) => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const selectedWorkSpaceId = useSelector(
    (state: RootState) => state.auth.selectedWorkSpaceId
  );
  const selectedAppDetail = useSelector(
    (state: RootState) => state.application.selectedAppDetail
  );
  const selectedDiagram = useSelector(
    (state: RootState) => state.diagram.selectedDiagram
  );
  const [selectedVersion, setSelectedVersion] = useState<Option>();
  const [stackTemplate, setStackTemplate] = useState({
    architecture: 0,
    caching: 0,
    dataBase: 0,
    messaging: 0,
    scheduler: 0,
  });
  const [repositoryName, setRepositoryName] = useState("");

  const databaseList = [
    {
      id: 1,
      title: "Mongo",
    },
    {
      id: 2,
      title: "Postgres",
    },
    {
      id: 3,
      title: "SqlServer",
    },
  ];
  const msgPlatfrom = [
    {
      id: 2,
      title: "Rabbitmq",
    },
    {
      id: 3,
      title: "Kafka",
    },
  ];
  const arcApproach = [
    {
      id: 1,
      title: "Monolithic",
    },
    {
      id: 2,
      title: "Microservices",
    },
  ];
  const caching = [
    {
      id: 2,
      title: "InMemory",
    },
    {
      id: 3,
      title: "Redis",
    },
  ];
  const scheduler = [
    {
      id: 2,
      title: "Hangfire",
    },
    {
      id: 3,
      title: "Internal",
    },
  ];
  const closeHandler = () => {
    if (props.onClose) return props.onClose(false);
  };
  const repositoryNameHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setRepositoryName(e.currentTarget.value);
  };
  console.log(selectedVersion);

  const addDeployment = (diagramId: string | number) => {
    if (!repositoryName.length) {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          message: "Please enter project name",
          statusCode: 500,
        })
      );
      return;
    }
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));

    let body = {
      projectId: projectId,
      tenantId: selectedWorkSpaceId,
      stackTemplate: stackTemplate,
      name: repositoryName,
      diagramId: diagramId,
      diagramVersion: selectedVersion ? selectedVersion.value : 1,
    };

    ApplicationRepository.addDeployment(body)
      .then((res: AxiosResponse<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: "added member Successfuly",
            statusCode: 200,
          })
        );
        closeHandler();
        getProjectById();
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data?.Reasons[0]?.Message,
            statusCode: 500,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const getProjectById = () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));

    ApplicationRepository.getProjectById(projectId)
      .then((res: AxiosResponse<any>) => {
        dispatch(ApplicationAction.setSelectedAppDetail(res.data.value));
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };

  const getDigramsVersionHistory = async () => {
    if (selectedAppDetail.project.deployments.length) {
      dispatch(loadingActions.setHasLoading(true));
      dispatch(errorActions.setHasError(false));
      dispatch(errorActions.setError(""));
      await DiagramSRepository.getDigramsVersionHistory(
        selectedAppDetail.project.diagramId
      )
        .then((res: AxiosResponse) => {
          dispatch(diagramActions.setSelectedDiagram(res.data.value.diagram));
        })
        .catch((error: AxiosError<any>) => {
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: error.response?.data
                ? error.response.data.Reasons[0].Message
                : error.message,
              statusCode: error.response?.status,
            })
          );
        })
        .finally(() => {
          dispatch(loadingActions.setHasLoading(false));
        });
    }
  };

  useEffect(() => {
    getDigramsVersionHistory();
  }, [selectedAppDetail]);

  return (
    <div className={classes.formLayout}>
      <div className={classes.header}>
        <span className={classes.headerTitle}>Set up configuration</span>
      </div>
      <div className={classes.formBody}>
        <div className={classes.formBox}>
          <div className={classes.itemBox}>
            <Input
              name="Project name"
              placeholder="Project name"
              value={repositoryName}
              handleChange={(e) => repositoryNameHandler(e)}
            />
          </div>
          <div className={classes.itemBox}>
            <SelectInput
              hasBlock={true}
              placeHolder="Select Version"
              options={
                selectedDiagram.models &&
                selectedDiagram.models.length > 0 &&
                selectedAppDetail.project.deployments.length
                  ? selectedDiagram.models.map((history: any, index) => {
                      return {
                        value: history.version,
                        title: `${history.data.name} - V ${history.version}`,
                      };
                    })
                  : [{ value: "1", title: "V1" }]
              }
              defaultValue={selectedVersion}
              handleselectedOption={(e) => {
                setSelectedVersion(e);
              }}
            />
          </div>
          <div className={classes.itemBox}>
            <span className={classes.itemTitle}>What is your Database?</span>
            <div className={classes.tagBox}>
              {databaseList.map((item, index) => {
                return (
                  <Tag
                    onClick={(e) => {
                      setStackTemplate({ ...stackTemplate, dataBase: item.id });
                    }}
                    title={item.title}
                    id={item.id}
                    rounded={true}
                    selected={stackTemplate.dataBase === item.id}
                    clickable={stackTemplate.dataBase !== item.id}
                    key={index}
                  />
                );
              })}
            </div>
          </div>
          {/** messaging platform */}

          <div className={classes.itemBox}>
            <span className={classes.itemTitle}>
              What is your messaging platform?
            </span>
            <div className={classes.tagBox}>
              {msgPlatfrom.map((item, index) => {
                return (
                  <Tag
                    onClick={(e) => {
                      setStackTemplate({
                        ...stackTemplate,
                        messaging: item.id,
                      });
                    }}
                    selected={stackTemplate.messaging === item.id}
                    clickable={stackTemplate.messaging !== item.id}
                    title={item.title}
                    id={item.id}
                    key={index}
                    rounded={true}
                  />
                );
              })}
            </div>
          </div>
        </div>
        {/** architecture approach */}
        <div className={classes.itemBox}>
          <span className={classes.itemTitle}>
            What is your architecture approach?
          </span>
          <div className={classes.tagBox}>
            {arcApproach.map((item, index) => {
              return (
                <Tag
                  onClick={(e) => {
                    setStackTemplate({
                      ...stackTemplate,
                      architecture: item.id,
                    });
                  }}
                  selected={stackTemplate.architecture === item.id}
                  clickable={stackTemplate.architecture !== item.id}
                  title={item.title}
                  id={item.id}
                  key={index}
                  rounded={true}
                />
              );
            })}
          </div>
        </div>
        {/** caching */}
        <div className={classes.formItem}>
          <span className={classes.itemTitle}>What is your Caching?</span>
          <div className={classes.tagBox}>
            {caching.map((item, index) => {
              return (
                <Tag
                  onClick={(e) => {
                    setStackTemplate({
                      ...stackTemplate,
                      caching: item.id,
                    });
                  }}
                  selected={stackTemplate.caching === item.id}
                  clickable={stackTemplate.caching !== item.id}
                  title={item.title}
                  id={item.id}
                  key={index}
                  rounded={true}
                />
              );
            })}
          </div>
        </div>
        {/** scheduler */}
        <div className={classes.formItem}>
          <span className={classes.itemTitle}>What is your Scheduler?</span>
          <div className={classes.tagBox}>
            {scheduler.map((item, index) => {
              return (
                <Tag
                  onClick={(e) => {
                    setStackTemplate({
                      ...stackTemplate,
                      scheduler: item.id,
                    });
                  }}
                  selected={stackTemplate.scheduler === item.id}
                  clickable={stackTemplate.scheduler !== item.id}
                  title={item.title}
                  id={item.id}
                  key={index}
                  rounded={true}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className={classes.formFooter}>
        <BaseButton
          size="sm"
          title="Close"
          color="secondary"
          onClickHandler={() => closeHandler()}
        />
        <BaseButton
          size="sm"
          title="Create"
          color="primary"
          onClickHandler={(e) => {
            addDeployment(selectedAppDetail.project.diagramId);
          }}
        />
      </div>
    </div>
  );
};
export default PublishForm;
