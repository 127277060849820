import classes from "./style/Setting.module.scss";
import AppSettingLayout from "../../../../components/layouts/AppSettingLayout";
import SettingComponent from "../../components/setting/Setting";
import { AxiosError, AxiosResponse } from "axios";
import { useParams } from "react-router-dom";
import { RepoType } from "../../../../types/sharedTypes";
import { useDispatch } from "react-redux";
import { loadingActions } from "../../../../store/loading-slice";
import { errorActions } from "../../../../store/error-slice";
import { RepoFactory } from "../../../../baseRepository/Factory";
import { ApplicationAction } from "../../store/application-slice";
import { useEffect } from "react";
import { menuAction } from "../../../../store/menu-slice";
const ApplicationRepository = RepoFactory.get(RepoType.App);
const Setting = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const getProjectById = () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));

    ApplicationRepository.getProjectById(projectId)
      .then((res: AxiosResponse<any>) => {
        dispatch(ApplicationAction.setSelectedAppDetail(res.data.value));
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  useEffect(() => {
    getProjectById();
  }, []);
  useEffect(() => {
    dispatch(menuAction.setSelectedMenu("Information"));
  }, []);
  return (
    <AppSettingLayout>
      <div className={classes.settingLayout}>
        <SettingComponent />
      </div>
    </AppSettingLayout>
  );
};
export default Setting;
