import { createSlice } from "@reduxjs/toolkit";
import { number } from "prop-types";
const diagramSlice = createSlice({
  name: "diagram",
  initialState: {
    selectedDiagram: {
      id: 0,
      name: "string",
      description: "string",
      dataVariationId: 0,
      tenantId: 0,
      tables: 0,
      addedDate: "2024-05-26T06:55:06.248Z",
      lastUpdatedDate: "2024-05-26T06:55:06.248Z",
      totalViews: 0,
      bussinesUrl: "string",
      tags: "string",
      logo: "string",
      clones: 0,
      stars: 0,
      isPrivate: true,
      folderName: "string",
      folderId: 0,
      diagramCurrentVersion: 0,
      isFavoriate: true,
      models: [
        {
          version: 0,
          description: "",
          data: {
            name: "",
          },
        },
      ],
    },
    diagramVariations: [],
    selectedDatabase: {
      id: "16022509476708352",
      name: "SQLServer",
      description: "Sql Server database document",
      dataBase: "SQLServer",
      metadatas: [],
    },
  },
  reducers: {
    setSelectedDiagram(state, action) {
      state.selectedDiagram = action.payload;
    },
    setDiagramVariations(state, action) {
      state.diagramVariations = action.payload;
    },
    setSelectedDatabse(state, action) {
      state.selectedDatabase = action.payload;
    },
  },
});
export const diagramActions = diagramSlice.actions;
export default diagramSlice.reducer;
