import classes from "./style/ApplicationTile.module.scss";
import StarIcon from "@atlaskit/icon/glyph/star";
import MoreIcon from "@atlaskit/icon/glyph/more";
import ApplicationOptions from "./ApplicationsOptions";
import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationAction } from "../store/application-slice";
import StarFilledIcon from "@atlaskit/icon/glyph/star-filled";
import { IconColor } from "../../../types/sharedTypes";
import { errorActions } from "../../../store/error-slice";
import { loadingActions } from "../../../store/loading-slice";
import { RepoFactory } from "../../../baseRepository/Factory";
import { RepoType } from "../../../types/sharedTypes";
import { RootState } from "../../../store";
import { AxiosError, AxiosResponse } from "axios";
import { useParams } from "react-router-dom";
import { SortApp } from "../../../types/sharedTypes";
import { folderActions } from "../../Folder/store/folder-slice";
import MoveToFolder from "./MoveToFolder";
import Modal from "../../../components/base/Modal";
import { menuAction } from "../../../store/menu-slice";
import Cup from "../../../assets/icons/appsBackground/Cup.png";
import Desktop from "../../../assets/icons/appsBackground/Desktop.png";
import Disk from "../../../assets/icons/appsBackground/Disk.png";
import Nucleus from "../../../assets/icons/appsBackground/Nucleus.png";
import Parrot from "../../../assets/icons/appsBackground/Parrot.png";
import Tool from "../../../assets/icons/appsBackground/Tool.png";
import Tube from "../../../assets/icons/appsBackground/Tube.png";
import Window from "../../../assets/icons/appsBackground/Window.png";
import Default from "../../../assets/icons/appsBackground/Default.png";
import Mobile from "../../../assets/icons/appsBackground/Mobile.png";
import Mountain from "../../../assets/icons/appsBackground/mountian.png";
const ApplicationRepository = RepoFactory.get(RepoType.App);
const FolderRepository = RepoFactory.get(RepoType.Folder);

interface MyComponent {
  app: {
    id: string;
    name?: string;
    type?: string;
    createAt?: string;
    coverImage?: string;
    isFavoriate?: boolean;
    projectId?: string;
    projectName?: string;
    deployments: [];
    modifyDate: string;
    created: string;
    contributers: [];
  };
}
const ApplicationTile = (props: MyComponent) => {
  const tileRef = useRef<HTMLDivElement>(null);
  const { folderId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [optionFlag, setOptionFlag] = useState<boolean>(false);
  const selectedWorkSpaceId = useSelector(
    (state: RootState) => state.auth.selectedWorkSpaceId
  );
  const sortType = useSelector(
    (state: RootState) => state.application.sortType
  );
  const [AppModalFlag, setAppModalFlag] = useState(false);
  const [appModal, setappModal] = useState("");
  const selectedDiagram = useSelector(
    (state: RootState) => state.application.selectedApp
  );

  const ImageSrcMapper = (imageName: string) => {
    if (imageName) {
      switch (imageName) {
        case "Mountain":
          return Mountain;
        case "Cup":
          return Cup;
        case "Desktop":
          return Desktop;
        case "Disk":
          return Disk;
        case "Mobile":
          return Mobile;
        case "Nucleus":
          return Nucleus;
        case "Parrot":
          return Parrot;
        case "Tool":
          return Tool;
        case "Tube":
          return Tube;
        case "Window":
          return Window;
        case "Default":
          return Default;
        default:
          return "";
      }
    } else {
      return "";
    }
  };

  const optionFlagHandler = () => {
    setOptionFlag(!optionFlag);
  };
  const getAppsListWithPageParams = () => {
    if (window.location.pathname.includes("folders") && folderId) {
      getAppByFolderId();
    }
    if (window.location.pathname.includes("fav")) {
      getFavoriteApp();
    } else {
      getAllApp();
    }
  };
  const getFavoriteApp = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await ApplicationRepository.getFavoriteApplication(selectedWorkSpaceId)
      .then((res: AxiosResponse<any>) => {
        dispatch(
          ApplicationAction.setFavoriteAppList(res.data.value.favorites)
        );
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const getAppByFolderId = () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    FolderRepository.getFolderById(folderId)
      .then((res: AxiosResponse<any>) => {
        dispatch(folderActions.setSelectedFolder(res.data.value.folder));
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const getAllApp = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await ApplicationRepository.getAllProjects(selectedWorkSpaceId)
      .then((res: AxiosResponse) => {
        dispatch(
          ApplicationAction.setApplist(
            SortApp(sortType, res.data.value.projects)
          )
        );
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const addToFavorite = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    let body = {
      projectId: props.app.id,
    };
    let tempApp = { ...props.app };
    await ApplicationRepository.addToFavorite(body)
      .then((res: AxiosResponse<any>) => {
        tempApp.isFavoriate = true;
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: "App Added To Favorites Successfuly",
            statusCode: 200,
          })
        );
        getAppsListWithPageParams();
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
        setOptionFlag(false);
      });
  };
  const unFavoriteApp = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));

    let tempApp = { ...props.app };
    await ApplicationRepository.unFavoriteApp(
      window.location.pathname.includes("fav")
        ? props.app.projectId
        : props.app.id
    )
      .then((res: AxiosResponse<any>) => {
        tempApp.isFavoriate = true;
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: "App  unFavorited Successfuly",
            statusCode: 200,
          })
        );
        getAppsListWithPageParams();
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
        setOptionFlag(false);
      });
  };
  const appDuplicated = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    let body = {
      projectId: props.app.id,
      name: "Copy of " + props.app.name,
    };
    await ApplicationRepository.cloneProject(body)
      .then((res: AxiosResponse) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: "App Duplicated Successfuly",
            statusCode: 200,
          })
        );
        getAppsListWithPageParams();
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
        setOptionFlag(false);
      });
  };
  const getApp = async () => {
    dispatch(ApplicationAction.setSelectedApp(props.app));
    setAppModalFlag(true);
  };
  const exitModal = (status: boolean) => {
    setAppModalFlag(status);
  };
  const modalHandler = () => {
    switch (appModal) {
      case "move":
        return (
          <Modal
            body={
              <div className={classes.moveToFolder}>
                <MoveToFolder exitAction={exitModal} app={selectedDiagram} />
              </div>
            }
          />
        );

      default:
        return null;
    }
  };
  const handleClickOutside = (event: MouseEvent) => {
    const { target } = event;
    if (tileRef.current && !tileRef.current.contains(target as HTMLElement)) {
      setOptionFlag(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [tileRef]);
  return (
    <div className={classes.tileContainer} ref={tileRef}>
      <div className={classes.header}>
        <div className={classes.appImage}>
          <img
            onClick={(e) => {
              dispatch(ApplicationAction.setSelectedApp(props.app));
              dispatch(menuAction.setSelectedMenu("Deployment"));
              navigate(
                `/app/deployment/${
                  window.location.pathname.includes("fav")
                    ? props.app.projectId
                    : props.app.id
                }`
              );
            }}
            src={ImageSrcMapper(
              props.app.coverImage ? props.app.coverImage : ""
            )}
            alt="app"
            className={classes.image}
          />
        </div>
        <span
          className={classes.appName}
          onClick={(e) => {
            dispatch(ApplicationAction.setSelectedApp(props.app));
            dispatch(menuAction.setSelectedMenu("Deployment"));
            navigate(
              `/app/deployment/${
                window.location.pathname.includes("fav")
                  ? props.app.projectId
                  : props.app.id
              }`
            );
          }}
        >
          {window.location.pathname.includes("fav")
            ? props.app.projectName
            : props.app.name}
        </span>
        <div
          className={classes.starIcon}
          onClick={(e) =>
            props.app.isFavoriate || window.location.pathname.includes("fav")
              ? unFavoriteApp()
              : addToFavorite()
          }
        >
          {props.app.isFavoriate || window.location.pathname.includes("fav") ? (
            <StarFilledIcon label="" primaryColor={IconColor.blue} />
          ) : (
            <StarIcon label="" primaryColor="#626F86" />
          )}
        </div>
        <div
          className={classes.tileOptionIcon}
          onClick={(e) => optionFlagHandler()}
        >
          <MoreIcon label="" primaryColor="#626F86" />
        </div>
      </div>
      <div className={classes.cardAction}>
        <div
          className={classes.options}
          onClick={(e) => {
            dispatch(ApplicationAction.setSelectedApp(props.app));
            dispatch(menuAction.setSelectedMenu("Deployment"));
            navigate(
              `/app/deployment/${
                window.location.pathname.includes("fav")
                  ? props.app.projectId
                  : props.app.id
              }`
            );
          }}
        >
          <div className={classes.optionItem}>
            <span className={classes.optionTitle}>Open</span>
          </div>
        </div>
        <div
          className={classes.options}
          onClick={(e) => {
            dispatch(ApplicationAction.setSelectedApp(props.app));
            dispatch(menuAction.setSelectedMenu("Deployment"));
            navigate(
              `/app/deployment/${
                window.location.pathname.includes("fav")
                  ? props.app.projectId
                  : props.app.id
              }`
            );
          }}
        >
          <div className={classes.optionItem}>
            <span className={classes.optionTitle}>Deployments</span>
          </div>
          <div className={classes.optionNumber}>
            <span className={classes.optionTitle}>
              {props.app.deployments?.length}
            </span>
          </div>
        </div>
        <div
          className={classes.options}
          onClick={(e) => {
            dispatch(ApplicationAction.setSelectedApp(props.app));
            dispatch(menuAction.setSelectedMenu("Deployment"));
            navigate(
              `/app/version-history/${
                window.location.pathname.includes("fav")
                  ? props.app.projectId
                  : props.app.id
              }`
            );
          }}
        >
          <div className={classes.optionItem}>
            <span className={classes.optionTitle}>Model history</span>
          </div>
        </div>
      </div>
      <hr className={classes.hr} />
      <div className={classes.footer}>
        <div className={classes.member}>
          {props.app.contributers
            ? props.app.contributers.map((member: { name: string }, index) => {
                return (
                  <div className={classes.avatar} key={index}>
                    <span className={classes.textIcon}>
                      {member ? member.name[0] : "N"}
                    </span>
                  </div>
                );
              })
            : ""}
        </div>
        <div className={classes.date}>
          {props.app.modifyDate
            ? `Modified ${props.app.modifyDate.split("T")[0]}`
            : `Created ${props.app.created.split("T")[0]}`}
        </div>
      </div>
      {AppModalFlag && modalHandler()}
      {optionFlag && (
        <div className={classes.tileOptions}>
          <ApplicationOptions
            onMove={(e) => {
              setappModal("move");
              getApp();
            }}
            appDuplicated={(e) => {
              appDuplicated();
            }}
            app={props.app}
            appId={props.app.id}
          />
        </div>
      )}
    </div>
  );
};
export default ApplicationTile;
