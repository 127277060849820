import BaseButton from "../../../components/base/BaseButton";
import classes from "./style/AccountDataFooter.module.scss";
import { Call, Check, TickCircle } from "iconsax-react";
const AccountDataFooter = () => {
  const contactIcon = () => {
    return <Call className={classes.contactIcon} />;
  };
  return (
    <div className={classes.footerLayout}>
      <div className={classes.advertise}>
        <span className={classes.adsTitle}>Pro Plan</span>
        <span className={classes.adsCaption}>
          Infinite features in one package!
        </span>
      </div>
      <BaseButton
        title="Contact us"
        icon={contactIcon()}
        hasLeftIcon={true}
        color="primary"
      />
      <div className={classes.details}>
        <div className={classes.checkbox}>
          <TickCircle className={classes.checkIcon} />
          <span className={classes.checkText}>Support</span>
        </div>
        <div className={classes.line} />
        <div className={classes.checkbox}>
          <TickCircle className={classes.checkIcon} />
          <span className={classes.checkText}>200 GB internal space</span>
        </div>
        <div className={classes.line} />
        <div className={classes.checkbox}>
          <TickCircle className={classes.checkIcon} />
          <span className={classes.checkText}>500,000 users</span>
        </div>
      </div>
    </div>
  );
};
export default AccountDataFooter;
