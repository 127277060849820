import classes from "./style/Setting.module.scss";
import ImageUploader from "../../../../components/core/ImageUploader";
import Input from "../../../../components/base/Input";
import TextArea from "../../../../components/base/Textarea";
import { useEffect, useRef, useState } from "react";
import { loadingActions } from "../../../../store/loading-slice";
import { errorActions } from "../../../../store/error-slice";
import { RepoFactory } from "../../../../baseRepository/Factory";
import { RepoType } from "../../../../types/sharedTypes";
import { RootState } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { AxiosError, AxiosResponse } from "axios";
import HeaderLayout from "../../components/setting/HeaderLayout";
import BaseButton from "../../../../components/base/BaseButton";
import MoreIcon from "@atlaskit/icon/glyph/more";
import { useParams } from "react-router-dom";
import { ApplicationAction } from "../../store/application-slice";
import SettingOptions from "./SettingOptions";
const ApplicationRepository = RepoFactory.get(RepoType.App);
const SettingComponent = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const settingOptionRef = useRef<HTMLDivElement>(null);
  const [optionFlag, setOptionFlag] = useState<boolean>(false);
  const selectedAppDetail = useSelector(
    (state: RootState) => state.application.selectedAppDetail
  );

  const [appName, setAppName] = useState(selectedAppDetail.project.name);
  const [description, setDescription] = useState(
    selectedAppDetail.project.description
  );
  const [image, setImage] = useState(selectedAppDetail.project.coverImage);
  const setSelectedImage = (image: string) => {
    setImage(image);
  };
  const AppNameHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setAppName(e.currentTarget.value);
  };
  const descriptionHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setDescription(e.currentTarget.value);
  };

  const more = () => {
    return <MoreIcon label="" />;
  };
  const appNameValidation = () => {
    const pattern = /^(?![.\d])[^#.&^()$%@!~]*$/;
    return pattern.test(appName);
  };
  const updateProject = () => {
    if (!appNameValidation()) {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          message: "Application Name Format Is Not Correct",
          statusCode: 500,
        })
      );
      return;
    }
    if (appName.length && image.length) {
      dispatch(loadingActions.setHasLoading(true));
      dispatch(errorActions.setHasError(false));
      dispatch(errorActions.setError(""));
      let body = {
        projectId: projectId,
        name: appName,
        description: description,
        coverImage: image,
      };
      ApplicationRepository.updateProject(body)
        .then((res: AxiosResponse<any>) => {
          dispatch(ApplicationAction.setSelectedApp(res.data.value.project));
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: "Application Updated Successfuly",
              statusCode: 200,
            })
          );
        })
        .catch((error: AxiosError<any>) => {
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: error.response?.data
                ? error.response.data.Reasons[0].Message
                : error.message,
              statusCode: error.response?.status,
            })
          );
        })
        .finally(() => {
          dispatch(loadingActions.setHasLoading(false));
        });
    } else {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          message: "Please enter a name and image for App !",
          statusCode: 50,
        })
      );
    }
  };
  const modalExitHandler = (status: boolean) => {
    setOptionFlag(status);
  };
  const handleClickOutside = (event: MouseEvent) => {
    const { target } = event;
    if (
      settingOptionRef.current &&
      !settingOptionRef.current.contains(target as HTMLElement)
    ) {
      setOptionFlag(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [settingOptionRef]);

  useEffect(() => {
    setAppName(selectedAppDetail.project.name);
    setDescription(selectedAppDetail.project.description);
    setImage(selectedAppDetail.project.coverImage);
  }, [selectedAppDetail]);
  return (
    <>
      <HeaderLayout
        type="Setting"
        children={
          <div className={classes.headerBtn}>
            <BaseButton
              size="sm"
              title="Save"
              color="primary"
              onClickHandler={(e) => {
                updateProject();
              }}
            />
            <BaseButton
              size="sm"
              title=""
              hasLeftIcon={true}
              icon={more()}
              color="secondary"
              onClickHandler={() => {
                setOptionFlag(!optionFlag);
              }}
            />
          </div>
        }
      />
      <div className={classes.option}>
        {optionFlag && (
          <div className={classes.optionLayout} ref={settingOptionRef}>
            <SettingOptions
              onClose={modalExitHandler}
              selectedApp={selectedAppDetail}
            />
          </div>
        )}
      </div>

      <div className={classes.setting}>
        <span className={classes.label}>Cover</span>
        <ImageUploader
          setSelectedImage={(e) => setSelectedImage(e)}
          selectedImage={image ? image : selectedAppDetail.project.coverImage}
        />
        <Input
          name="Application name"
          title="Title"
          placeholder="Application name"
          value={appName}
          handleChange={(e) => AppNameHandler(e)}
        />
        <TextArea
          title="Description"
          placeholder="Type something ..."
          value={description}
          handleChange={(e) => descriptionHandler(e)}
        />
      </div>
    </>
  );
};
export default SettingComponent;
