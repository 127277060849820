import classes from "./style/ApplicationPanel.module.scss";
import ApplicationTile from "./ApplicationTile";
import EmptyFolder from "../../../core/helpers/EmptyFolder";
interface MyComponent {
  appList: any;
}
const ApplicationPanel = (props: MyComponent) => {
  return (
    <div className={classes.panelBox}>
      {props.appList?.length ? (
        props?.appList.map((app: any, index: number) => {
          return <ApplicationTile app={app} key={index} />;
        })
      ) : (
        <div className={classes.empty}>
          <EmptyFolder />
        </div>
      )}
    </div>
  );
};
export default ApplicationPanel;
