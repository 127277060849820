import classes from "./style/Header.module.scss";
import Logo from "../../assets/icons/CodeinoneLogo.svg";
import BaseButton from "../base/BaseButton";
import PremiumIcon from "@atlaskit/icon/glyph/premium";
const SimpleHeader = () => {
  const premiumIcon = () => {
    return <PremiumIcon label="" primaryColor="#F86F03" />;
  };
  return (
    <div className={classes.headerContainer}>
      <div className={classes.leftPanel}>
        <img className={classes.logo} alt="logo" src={Logo} />
      </div>
      <div className={classes.rightPanel}>
        <BaseButton
          title="Premium"
          color="outline"
          hasRightIcon={false}
          hasLeftIcon={true}
          hasBlock={false}
          icon={premiumIcon()}
        ></BaseButton>
      </div>
    </div>
  );
};
export default SimpleHeader;
