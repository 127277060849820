import classes from "./style/Input.module.scss";
import Form from "react-bootstrap/Form";
interface MyComponentProps {
  title?: string;
  size?: "sm" | "lg";
  type?: string;
  placeholder?: string;
  id?: string | undefined;
  name: string;
  value?: string | number;
  maxLength?: number;
  icon?: JSX.Element | JSX.Element[];
  disable?: boolean;
  pattern?: string;
  error?: boolean;
  handleChange?:
    | ((e: React.ChangeEvent<HTMLInputElement>, id?: number) => void)
    | undefined;
  required?: boolean;
  readOnly?: boolean;
  onClick?: (() => void) | undefined;
  handleEnter?:
    | ((e: React.KeyboardEvent<HTMLInputElement>) => void)
    | undefined;
  defaultValue?: string | number;
  min?: number;
  max?: number;
  centerText?: boolean;
  isInvalid?: boolean;
  inValidmsg?: string;
}
const Input = (props: MyComponentProps) => {
  return (
    <div className={classes.inputbox}>
      {props.title && (
        <Form.Label>
          {props.title}
          {props.required === false && (
            <span className={classes.labelRequired}>optional</span>
          )}
        </Form.Label>
      )}

      <Form.Control
        defaultValue={props.defaultValue}
        className={`${classes.input} ${props?.centerText ? "text-center" : ""}`}
        size={props.size}
        type={props.type}
        readOnly={props.readOnly}
        placeholder={props.placeholder}
        id={props.id ? props.id : Math.random() + "input"}
        value={props.value}
        disabled={props.disable}
        isInvalid={props.isInvalid}
        onChange={props.handleChange}
        onClick={props.onClick}
        onKeyDown={props.handleEnter}
        required={props.required}
        min={props.min ? props.min : 0}
        max={props.max ? props.max : 100}
        pattern={props.pattern}
      />

      {/* <Form.Control.Feedback type="invalid">
        {props.inValidmsg}
      </Form.Control.Feedback> */}
    </div>
  );
};
export default Input;
