import classes from "./style/ImageUploader.module.scss";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { useEffect, useRef, useState } from "react";
import BaseButton from "../base/BaseButton";
import Mountain from "../../assets/icons/appsBackground/mountian.png";
import {} from "../../types/enums";
import Cup from "../../assets/icons/appsBackground/Cup.png";
import Desktop from "../../assets/icons/appsBackground/Desktop.png";
import Disk from "../../assets/icons/appsBackground/Disk.png";
import Nucleus from "../../assets/icons/appsBackground/Nucleus.png";
import Parrot from "../../assets/icons/appsBackground/Parrot.png";
import Tool from "../../assets/icons/appsBackground/Tool.png";
import Tube from "../../assets/icons/appsBackground/Tube.png";
import Window from "../../assets/icons/appsBackground/Window.png";
import Default from "../../assets/icons/appsBackground/Default.png";
import Mobile from "../../assets/icons/appsBackground/Mobile.png";
import { BorderColor } from "../../types/sharedTypes";

interface MyComponent {
  selectedImage?: string;
  setSelectedImage?: (image: string) => void;
}
const ImageUploader = (props: MyComponent) => {
  const defaultImageRef = useRef<HTMLDivElement>(null);
  const [defaultListFlag, setDefaultListFlag] = useState<boolean>(false);
  const [selectedDefaultImage, setSelectedDefaultImage] = useState<string>(
    props.selectedImage ? props.selectedImage : ""
  );
  const [defaultImages, setDefaultImages] = useState([
    {
      id: "Mountain",
      src: Mountain,
    },
    {
      id: "Cup",
      src: Cup,
    },
    {
      id: "Desktop",
      src: Desktop,
    },
    {
      id: "Disk",
      src: Disk,
    },
    {
      id: "Mobile",
      src: Mobile,
    },
    {
      id: "Nucleus",
      src: Nucleus,
    },
    {
      id: "Parrot",
      src: Parrot,
    },
    {
      id: "Tool",
      src: Tool,
    },
    {
      id: "Tube",
      src: Tube,
    },
    {
      id: "Window",
      src: Window,
    },
    {
      id: "Default",
      src: Default,
    },
  ]);

  const imageMapper = () => {
    let src = "";
    defaultImages.forEach((image) => {
      if (props.selectedImage && image.id === props.selectedImage) {
        src = image.src;
      }
    });
    return src;
  };
  const handleClickOutside = (event: MouseEvent) => {
    const { target } = event;
    if (
      defaultImageRef.current &&
      !defaultImageRef.current.contains(target as HTMLElement)
    ) {
      setDefaultListFlag(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [defaultImageRef]);

  useEffect(() => {
    if (props.selectedImage) setSelectedDefaultImage(props.selectedImage);
  }, [props.selectedImage]);

  return (
    <div className={classes.uploaderBox}>
      <img
        className={classes.appIcon}
        src={imageMapper()}
        alt=""
        onClick={() => setDefaultListFlag(!defaultListFlag)}
      />

      {defaultListFlag && (
        <div ref={defaultImageRef} className={classes.listBox}>
          <div className={classes.listLayout}>
            <span className={classes.listTitle}>Select from images</span>
            <div className={classes.items}>
              {defaultImages.map((image, index) => {
                return (
                  <img
                    key={index}
                    alt="avatar"
                    src={image.src}
                    className={
                      props?.selectedImage === image.id
                        ? classes.defaultSelectedIcon
                        : classes.defaultIcon
                    }
                    onClick={(e) => {
                      setDefaultListFlag(false);
                      if (props.setSelectedImage) {
                        props.setSelectedImage(image.id);
                      }
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ImageUploader;
