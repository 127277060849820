import classes from "./style/DataBaseForm.module.scss";
import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import DatabaseCard from "../../../components/base/DataBaseCard";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
interface myComponentProps {
  databaseList: any[];
}
const DataBaseForm = (props: myComponentProps) => {
  const [databaseKind, setDatabaseKind] = useState("database");
  const selectedCard = useSelector(
    (state: RootState) => state.diagram.selectedDatabase
  );

  const databaseHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDatabaseKind(e.target.value);
  };
  const dataMap = (title: string) => {
    let card = props.databaseList.find((X) => X.name === title);
    return card;
  };

  useEffect(() => {
    if (selectedCard) {
      if (
        selectedCard.name === "CodeInOne" ||
        selectedCard.name === "Python" ||
        selectedCard.name === "Nodejs"
      ) {
        setDatabaseKind("tecnology");
      }
    } else {
    }
  }, [selectedCard]);
  return (
    <div className={classes.database}>
      <span className={classes.databaseHeader}>Select database</span>

      <div className={classes.typeList}>
        <Form.Group controlId="kindOfDatabase">
          <Form.Check
            inline
            value="database"
            type="radio"
            aria-label=""
            label="Database type"
            onChange={databaseHandler}
            checked={databaseKind === "database"}
          />
          <Form.Check
            inline
            value="tecnology"
            type="radio"
            aria-label="tecnology"
            label="Tecnology type"
            onChange={databaseHandler}
            checked={databaseKind === "tecnology"}
          />
        </Form.Group>
      </div>
      {databaseKind === "database" ? (
        <div className={classes.cardBox}>
          <DatabaseCard title="SQLServer" type={dataMap("SQLServer")} />
          <DatabaseCard title="Mongodb" type={dataMap("Mongodb")} />
          <DatabaseCard title="Postgresql" type={dataMap("Postgresql")} />
        </div>
      ) : (
        <div className={classes.cardBox}>
          <DatabaseCard title="CodeInOne" type={dataMap("CodeInOne")} />
          <DatabaseCard title="Python" disable={true} />
          <DatabaseCard title="Nodejs" disable={true} />
        </div>
      )}
    </div>
  );
};
export default DataBaseForm;
