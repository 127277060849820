import classes from "./style/CharmDbDesign.module.scss";
import { IconColor } from "../../../../types/sharedTypes";
import BaseButton from "../../../../components/base/BaseButton";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
interface myComponentProps {
  changeStep: (number: number, status: string) => void;
  changeStepItems: (items: any) => void;
}
const CharmDbDesign = (props: myComponentProps) => {
  const selectedDiagram = useSelector(
    (state: RootState) => state.diagram.selectedDiagram
  );
  const changeStep = (number: number, status: string) => {
    if (props.changeStep) {
      props.changeStep(number, status);
    }
  };
  const changeStepItems = (status: string) => {
    props.changeStepItems([
      {
        id: "SelectModel",
        label: "Select model",
        percentageComplete: 100,
        status: "disabled",
        href: "#",
      },
      {
        id: "appInfo",
        label: "App info",
        percentageComplete: 100,
        status: "disabled",
        href: "#",
      },
      {
        id: "Configuration",
        label: "Configuration",
        percentageComplete: status === "back" ? 0 : 100,
        status: status === "back" ? "current" : "disable",
        href: "#",
      },
      {
        id: "Design",
        label: "Design",
        percentageComplete: status === "next" ? 100 : 0,
        status: status === "next" ? "disable" : "undisable",
        href: "#",
      },
      {
        id: "Review",
        label: "Review",
        percentageComplete: status === "next" ? 0 : 0,
        status: status === "next" ? "current" : "unvisited",
        href: "#",
      },
    ]);
  };
  const nextStep = () => {
    changeStep(5, "next");
    changeStepItems("next");
  };
  const backStep = () => {
    changeStep(3, "back");
    changeStepItems("back");
  };
  return (
    <div className={classes.designBox}>
      <iframe
        title="charm"
        className={classes.charmFrame}
        src={`${process.env.REACT_APP_CHARMDB_BASE_DOMAIN}/diagrams/createNew/${selectedDiagram.id}/${selectedDiagram.diagramCurrentVersion}`}
      />
      <div className={classes.footerLayout}>
        <div className={classes.buttonBox}>
          <BaseButton
            size="sm"
            title="Back"
            color="warning"
            onClickHandler={() => backStep()}
          />
          <BaseButton
            size="sm"
            title="Next"
            color="primary"
            onClickHandler={() => nextStep()}
          />
        </div>
      </div>
    </div>
  );
};
export default CharmDbDesign;
