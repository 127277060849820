import axios from "axios";

export const authHeader: { Authorization: string } | {} =
  axios.interceptors.request.use((req) => {
    req.headers.Authorization = getCookie("access_token")
      ? "Bearer " + getCookie("access_token")
      : null;
    return req;
  });
function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts: any = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export const baseURL = process.env.REACT_APP_API_BASE_DOMAIN;
axios.defaults.baseURL = baseURL;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.get["Content-Type"] = "application/json";
axios.defaults.headers.delete["Content-Type"] = "application/json";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.patch["Content-Type"] = "application/json";
axios.defaults.headers.Accept = "application/json";
if (authHeader) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${getCookie(
    "access_token"
  )}`;
}

export default axios;
