import classes from "./style/AppInfoForm.module.scss";
import Input from "../../../../components/base/Input";
import TexrArea from "../../../../components/base/Textarea";
import SelectInput from "../../../../components/base/SelectInput";
import BaseButton from "../../../../components/base/BaseButton";
import ArrowRightIcon from "@atlaskit/icon/glyph/arrow-right";
import ImageUploader from "../../../../components/core/ImageUploader";
import { loadingActions } from "../../../../store/loading-slice";
import { errorActions } from "../../../../store/error-slice";
import { RepoFactory } from "../../../../baseRepository/Factory";
import { RepoType } from "../../../../types/sharedTypes";
import { RootState } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { AxiosError, AxiosResponse } from "axios";
import { ApplicationAction } from "../../store/application-slice";
import { useLocation } from "react-router-dom";
import { useMemo, useState } from "react";
const ApplicationRepository = RepoFactory.get(RepoType.App);
const FolderRepository = RepoFactory.get(RepoType.Folder);
interface MyComponent {
  changeStep: (number: number, status: string) => void;
  changeStepItems: (items: any) => void;
  page: string | null;
}
const AppInfoForm = (props: MyComponent) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const selectedWorkSpaceId = useSelector(
    (state: RootState) => state.auth.selectedWorkSpaceId
  );
  const selectedApp = useSelector(
    (state: RootState) => state.application.selectedApp
  );
  const selectedFolder = useSelector(
    (state: RootState) => state.folder.selectedFolder
  );
  const selectedDiagram = useSelector(
    (state: RootState) => state.diagram.selectedDiagram
  );
  const [appName, setAppName] = useState(
    searchParams.get("status") === "back" ? selectedApp.name : ""
  );
  const [description, setDescription] = useState(
    searchParams.get("status") === "back" ? selectedApp.description : ""
  );
  const [image, setImage] = useState(
    searchParams.get("status") === "back" ? selectedApp.coverImage : "Default"
  );
  const [name, setName] = useState("");
  const [selectedPermision, setSelectedPermision] = useState({
    value: "1",
    title: "Admin",
  });
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);

  const ArrowRight = () => {
    return <ArrowRightIcon label="" />;
  };
  const AppNameHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setAppName(e.currentTarget.value);
  };
  const descriptionHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setDescription(e.currentTarget.value);
  };
  const setSelectedImage = (imageUrl: string) => {
    setImage(imageUrl);
  };
  const appNameValidation = () => {
    const pattern = /^(?![.\d])[^#.&^()$%@!~]*$/;
    return pattern.test(appName);
  };
  const createProject = async () => {
    if (!appNameValidation()) {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          message: "Application Name Format Is Not Correct",
          statusCode: 500,
        })
      );
      return;
    }
    if (appName.length && image.length) {
      dispatch(loadingActions.setHasLoading(true));
      dispatch(errorActions.setHasError(false));
      dispatch(errorActions.setError(""));
      let body = {
        tenantId: selectedWorkSpaceId,
        diagramId: selectedDiagram.id,
        diagramVersion: selectedDiagram.diagramCurrentVersion
          ? selectedDiagram.diagramCurrentVersion
          : 1,
        name: appName,
        description: description,
        coverImage: image,
        contributers: name.length
          ? [{ name: name, email: name, permission: +selectedPermision.value }]
          : [],
        projectOwner: {
          name: userInfo.nickname,
          email: userInfo.email,
          permission: 1,
        },
      };
      await ApplicationRepository.createProject(body)
        .then((res: AxiosResponse<any>) => {
          dispatch(ApplicationAction.setSelectedApp(res.data.value.project));
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: "Application Created Successfuly",
              statusCode: 200,
            })
          );
          if (props.page === "fav") {
            addToFavorite(res.data.value.project.id);
          } else if (props.page === "folder") {
            moveToFolderHandler(res.data.value.project.id);
          } else {
            changeStep(3, "next");
            changeStepItemsToNext();
          }
        })
        .catch((error: AxiosError<any>) => {
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: error.response?.data
                ? error.response.data.Reasons[0].Message
                : error.message,
              statusCode: error.response?.status,
            })
          );
        })
        .finally(() => {
          dispatch(loadingActions.setHasLoading(false));
        });
    } else {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          message: "Please enter a name and image for App !",
          statusCode: 500,
        })
      );
    }
  };
  const addToFavorite = async (id: string) => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    let body = {
      projectId: id,
    };
    await ApplicationRepository.addToFavorite(body)
      .then((res: AxiosResponse<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: "App Added To Favorites Successfuly",
            statusCode: 200,
          })
        );
        changeStep(3, "next");
        changeStepItemsToNext();
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const moveToFolderHandler = async (id: string) => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));

    let body = {
      tenantId: selectedWorkSpaceId,
      folderId: selectedFolder.id,
      projectId: id,
    };
    await FolderRepository.assignApp(body)
      .then((res: AxiosResponse<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: "Application Moved To Folder Successfuly",
            statusCode: 200,
          })
        );
        changeStep(3, "next");
        changeStepItemsToNext();
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data));
      });
  };
  const updateProject = () => {
    if (!appNameValidation()) {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          message: "Application Name Format Is Not Correct",
          statusCode: 500,
        })
      );
      return;
    }
    if (appName.length && image.length) {
      dispatch(loadingActions.setHasLoading(true));
      dispatch(errorActions.setHasError(false));
      dispatch(errorActions.setError(""));
      let body = {
        projectId: selectedApp.id,
        name: appName,
        description: description,
        coverImage: image,
      };
      ApplicationRepository.updateProject(body)
        .then((res: AxiosResponse<any>) => {
          dispatch(ApplicationAction.setSelectedApp(res.data.value.project));
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: "Application Updated Successfuly",
              statusCode: 200,
            })
          );
          changeStep(3, "next");
          changeStepItemsToNext();
        })
        .catch((error: AxiosError<any>) => {
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: error.response?.data
                ? error.response.data.Reasons[0].Message
                : error.message,
              statusCode: error.response?.status,
            })
          );
        })
        .finally(() => {
          dispatch(loadingActions.setHasLoading(false));
        });
    } else {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          message: "Please enter a name and image for App !",
          statusCode: 50,
        })
      );
    }
  };
  const changeStep = (number: number, status: string) => {
    if (props.changeStep) {
      props.changeStep(number, status);
    }
  };
  const changeStepItemsToNext = () => {
    props.changeStepItems([
      {
        id: "SelectModel",
        label: "Select model",
        percentageComplete: 100,
        status: "disabled",
        href: "#",
      },
      {
        id: "appInfo",
        label: "App info",
        percentageComplete: 100,
        status: "disabled",
        href: "#",
      },
      {
        id: "Configuration",
        label: "Configuration",
        percentageComplete: 0,
        status: "current",
        href: "#",
      },
      {
        id: "Design",
        label: "Design",
        percentageComplete: 0,
        status: "unvisited",
        href: "#",
      },
      {
        id: "Review",
        label: "Review",
        percentageComplete: 0,
        status: "unvisited",
        href: "#",
      },
    ]);
  };
  const changeStepItemsToLast = () => {
    props.changeStepItems([
      {
        id: "SelectModel",
        label: "Select model",
        percentageComplete: 0,
        status: "current",
        href: "#",
      },
      {
        id: "appInfo",
        label: "App info",
        percentageComplete: 0,
        status: "unvisited",
        href: "#",
      },
      {
        id: "Configuration",
        label: "Configuration",
        percentageComplete: 0,
        status: "unvisited",
        href: "#",
      },
      {
        id: "Design",
        label: "Design",
        percentageComplete: 0,
        status: "unvisited",
        href: "#",
      },
      {
        id: "Review",
        label: "Review",
        percentageComplete: 0,
        status: "unvisited",
        href: "#",
      },
    ]);
  };
  return (
    <div className={classes.AppInfo}>
      <h1 className={classes.header}>Input application information</h1>
      <div className={classes.form}>
        <span className={classes.label}>Application Information</span>
        <span className={classes.label}>Cover</span>
        <ImageUploader
          setSelectedImage={(e) => setSelectedImage(e)}
          selectedImage={image}
        />
        <Input
          name="App name"
          placeholder="Enter Your Application Name"
          value={appName}
          handleChange={(e) => AppNameHandler(e)}
        />
        <TexrArea
          placeholder="Description (optional)"
          value={description}
          handleChange={(e) => descriptionHandler(e)}
        />
        {searchParams.get("status") !== "back" && (
          <>
            <span className={classes.label}>
              Do you want to share with others?
            </span>
            <div className={classes.formRow}>
              <div className={classes.emailSection}>
                <Input
                  value={name}
                  handleChange={(e) => setName(e.target.value)}
                  name="Input name or email"
                  placeholder="Input name or email"
                />
              </div>
              <div className={classes.roleSection}>
                <SelectInput
                  placeHolder="As ..."
                  options={[
                    { value: "1", title: "Admin" },
                    { value: "2", title: "Contributor" },
                  ]}
                  defaultValue={selectedPermision}
                  handleselectedOption={(e) => {
                    setSelectedPermision(e);
                  }}
                  hasBlock={true}
                />
              </div>
            </div>
          </>
        )}
        <div className={classes.btnRow}>
          <BaseButton
            size="sm"
            title="Back"
            color="secondary"
            onClickHandler={(e) => {
              changeStepItemsToLast();
              changeStep(1, "back");
            }}
          />
          <BaseButton
            size="sm"
            title="Add"
            color="primary"
            hasRightIcon={true}
            icon={ArrowRight()}
            onClickHandler={(e) =>
              searchParams.get("status") === "back"
                ? updateProject()
                : createProject()
            }
          />
        </div>
      </div>
    </div>
  );
};
export default AppInfoForm;
