import { ReactNode, useEffect, useState } from "react";
import classes from "./style/MenuItem.module.scss";
import { useNavigate } from "react-router-dom";
import { menuAction } from "../../store/menu-slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";

interface MyComponent {
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  title: string;
  link: string;
}
const MenuItem = (props: MyComponent) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedMenu = useSelector(
    (state: RootState) => state.menu.selectedMenu
  );

  const menuItemHandler = () => {
    console.log(props.title);

    dispatch(menuAction.setSelectedMenu(props.title));
    navigate(props.link);
  };

  return (
    <>
      {props.title !== "My Repository" ? (
        <div
          className={
            selectedMenu === props.title
              ? classes.selectedMenuItem
              : classes.menuItem
          }
          onClick={(e) => menuItemHandler()}
        >
          {props.title === selectedMenu && (
            <div className={classes.indicator}></div>
          )}
          <div className={classes.itemBox}>
            <div className={classes.itemContainer}>
              {props.leftIcon}
              <span
                style={{
                  color: selectedMenu === props.title ? "#0C66E4" : "#172B4D",
                }}
                className={classes.menuItemTitle}
              >
                {props.title}
              </span>
              {props.rightIcon}
            </div>
          </div>
        </div>
      ) : (
        <a
          style={{ textDecoration: "none" }}
          className={
            selectedMenu === props.title
              ? classes.selectedMenuItem
              : classes.menuItem
          }
          href={props.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.title === selectedMenu && (
            <div className={classes.indicator}></div>
          )}
          <div className={classes.itemBox}>
            <div className={classes.itemContainer}>
              {props.leftIcon}
              <span
                style={{
                  color: selectedMenu === props.title ? "#0C66E4" : "#172B4D",
                }}
                className={classes.menuItemTitle}
              >
                {props.title}
              </span>
              {props.rightIcon}
            </div>
          </div>
        </a>
      )}
    </>
  );
};
export default MenuItem;
