import classes from "./style/Members.module.scss";
import avatar from "../../../../assets/images/avatar image.svg";
import ChevronDownIcon from "@atlaskit/icon/glyph/chevron-down";
import EditorRemoveIcon from "@atlaskit/icon/glyph/editor/remove";
import { computePageNumber } from "../../../../core/helpers/pagination";
import { useParams } from "react-router-dom";
import Pagination from "../../../../components/base/TablePagination";
import { RepoFactory } from "../../../../baseRepository/Factory";
import { AxiosError, AxiosResponse } from "axios";
import { RepoType } from "../../../../types/sharedTypes";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadingActions } from "../../../../store/loading-slice";
import { errorActions } from "../../../../store/error-slice";
import { ApplicationAction } from "../../store/application-slice";
import { RootState } from "../../../../store";
import PasswordText from "../../../../components/base/PasswordText";
const ApplicationRepository = RepoFactory.get(RepoType.App);
const Members = () => {
  const { pageNumber } = useParams();
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState<number>(pageNumber);
  const membersList = useSelector(
    (state: RootState) => state.application.membersList
  );
  const handleChangePage = (selectedPage: { selected: number }) => {
    // if (changePage) {
    //   changePage(selectedPage);
    // }
  };
  const getAllContributers = () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    ApplicationRepository.getAllContributers(projectId)
      .then((res: AxiosResponse<any>) => {
        dispatch(ApplicationAction.setMembersList(res.data.value.contributers));
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const deleteContributer = (id: string) => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    ApplicationRepository.deleteContributer(id)
      .then((res: AxiosResponse<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: "deleted member Successfuly",
            statusCode: 200,
          })
        );
        getAllContributers();
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  useEffect(() => {
    getAllContributers();
  }, []);
  console.log(membersList);

  return (
    <div className={classes.members}>
      <div className={classes.header}>
        <div className={classes.userSection}>User</div>
        <div className={classes.passwordSection}>Password</div>
        <div className={classes.action}>Accessibility</div>
      </div>
      {membersList.length
        ? membersList.map((member, index) => {
            return (
              <div key={index} className={classes.row}>
                <div className={classes.userName}>
                  <img src={avatar} alt="avatar image" />
                  <div className={classes.name}>{member.name}</div>
                </div>
                <div className={classes.password}>
                  <div className={classes.passwordSection}>
                    <PasswordText password={member.password} />
                  </div>
                </div>
                <div className={classes.actions}>
                  <div className={classes.dropdownSelected}>
                    {/* <ChevronDownIcon label="" /> */}
                    {member.permissionType === 1 ? (
                      <span>Admin</span>
                    ) : member.permissionType === 2 ? (
                      <span>Contributor</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className={classes.trash}
                    onClick={(e) => deleteContributer(member.id)}
                  >
                    <EditorRemoveIcon label="" />
                  </div>
                </div>
              </div>
            );
          })
        : ""}

      {/* <Pagination
        totalPage={10}
        currentPage={3}
        handlePageChange={handleChangePage}
      /> */}
    </div>
  );
};

export default Members;
