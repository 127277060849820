import { useState } from "react";
import classes from "./style/SettingOptions.module.scss";
import Modal from "../../../../components/base/Modal";
import MoveToFolder from "../MoveToFolder";
import { RepoType } from "../../../../types/sharedTypes";
import { loadingActions } from "../../../../store/loading-slice";
import { menuAction } from "../../../../store/menu-slice";
import { errorActions } from "../../../../store/error-slice";
import { useDispatch } from "react-redux";
import { RepoFactory } from "../../../../baseRepository/Factory";
import { AxiosError, AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import WarningModal from "../../../../core/helpers/WarningModal";
const ApplicationRepository = RepoFactory.get(RepoType.App);
interface MyComponent {
  onClose: (status: boolean) => void;
  selectedApp: any;
}
const SettingOptions = (props: MyComponent) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalFlag, setModalFlag] = useState<boolean>(false);
  const [warningFlag, setWarningFlag] = useState<boolean>(false);
  const closeHandler = () => {
    if (props.onClose) return props.onClose(true);
  };
  const exitModal = (status: boolean) => {
    return setModalFlag(status);
  };
  const exitWarningModal = (status: boolean) => {
    return setWarningFlag(status);
  };
  const deleteProject = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));

    await ApplicationRepository.deleteProject(props.selectedApp.project.id)
      .then((res: AxiosResponse) => {
        setWarningFlag(false);
        dispatch(menuAction.setSelectedMenu("My Applications"));
        navigate("/app");
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const appDuplicated = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    let body = {
      projectId: props.selectedApp.project.id,
      name: "Copy of " + props.selectedApp.project.name,
    };
    await ApplicationRepository.cloneProject(body)
      .then((res: AxiosResponse) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: "App Duplicated Successfuly",
            statusCode: 200,
          })
        );
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  return (
    <div className={classes.optionBox}>
      <div className={classes.optionItems}>
        <div className={classes.item} onClick={(e) => appDuplicated()}>
          <span className={classes.itemTitle}>Duplicate</span>
        </div>
        <div
          className={classes.item}
          onClick={() => {
            setModalFlag(true);
            closeHandler();
          }}
        >
          <span className={classes.itemTitle}>Move to folder</span>
        </div>
        <div className={classes.item} onClick={() => setWarningFlag(true)}>
          <span className={classes.deleteTitle}>Delete</span>
        </div>
      </div>
      {modalFlag && (
        <Modal
          body={
            <div className={classes.moveToFolder}>
              <MoveToFolder
                exitAction={exitModal}
                app={props.selectedApp.project}
              />
            </div>
          }
        />
      )}
      {warningFlag && (
        <WarningModal
          type="delete"
          header="Warning"
          alert="Are you sure you want to delete this Application ? "
          onClose={exitWarningModal}
          onDelete={() => deleteProject()}
        />
      )}
    </div>
  );
};
export default SettingOptions;
