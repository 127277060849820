import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
// import "./assets/font/font.scss";
import "./assets/style/scss/elements.scss";
import "./assets/style/css/main.css";
import App from "./App";
import store from "./store/index";
import "bootstrap/dist/css/bootstrap.min.css";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      {/* <div>test</div> */}
    </Provider>
  </React.StrictMode>
);
