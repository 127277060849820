import CreateAppLayout from "../../../../components/layouts/CreateAppLayout";
import classes from "./style/CreateApp.module.scss";
import CharmDbProjects from "../../components/CreateApp/CharmDbProjects";
import ArrowLeftIcon from "@atlaskit/icon/glyph/arrow-left";
import { useLocation, useNavigate } from "react-router-dom";
import { ProgressTracker, Stages } from "@atlaskit/progress-tracker";
import SelectModel from "../../components/CreateApp/SelectModel";
import { useMemo, useState } from "react";
import AppInfoForm from "../../components/CreateApp/AppInfoForm";
import AppInfoImage from "../../components/CreateApp/AppInfoImage";
import ConfigurationForm from "../../components/CreateApp/ConfigurationForm";
import ConfigurationImage from "../../components/CreateApp/ConfigurationImage";
import Review from "../../components/CreateApp/Review";
import ReviewImage from "../../components/CreateApp/ReviewImage";
import NewBlankModel from "../../components/CreateApp/NewBlankModel";
import CharmDbDesign from "../../components/CreateApp/CharmDbDesign";
import { RootState } from "../../../../store";
import { useSelector } from "react-redux";
const CreateApp = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const [stepNumber, setStepNumber] = useState(
    searchParams.get("stepNumber") ? Number(searchParams.get("stepNumber")) : 1
  );

  const [selectWay, setSelectWay] = useState<string | null>(
    searchParams.get("selectedWay") ? searchParams.get("selectedWay") : ""
  );
  const [page, setPage] = useState<string | null>(
    searchParams.get("page") ? searchParams.get("page") : "app"
  );
  const selectedFolder = useSelector(
    (state: RootState) => state.folder.selectedFolder
  );

  const [items, setItems] = useState<Stages>([
    {
      id: "SelectModel",
      label: "Select model",
      percentageComplete: stepNumber === 1 ? 0 : 100,
      status: stepNumber === 1 ? "current" : "disabled",
      href: "#",
    },
    {
      id: "appInfo",
      label: "App info",
      percentageComplete: 0,
      status: stepNumber === 1 ? "unvisited" : "current",
      href: "#",
    },
    {
      id: "Configuration",
      label: "Configuration",
      percentageComplete: 0,
      status: "unvisited",
      href: "#",
    },
    {
      id: "Design",
      label: "Design",
      percentageComplete: 0,
      status: "unvisited",
      href: "#",
    },
    {
      id: "Review",
      label: "Review",
      percentageComplete: 0,
      status: "unvisited",
      href: "#",
    },
  ]);
  const changeStepItems = (currentItems: Stages) => {
    setItems(currentItems);
  };
  const changeStepNumber = (number: number, status?: string) => {
    searchParams.set("stepNumber", String(number));
    navigate(
      `/app/new?selectedWay=${selectWay}&stepNumber=${number}&page=${page}&status=${
        status ? status : "next"
      }`
    );
    setStepNumber(number);
  };
  const changeSelectWay = (way: string, status: string) => {
    searchParams.set("selectedWay", way);
    navigate(
      `/app/new?selectedWay=${way}&stepNumber=${stepNumber}&page=${page}&status=${status}`
    );
    setSelectWay(way);
  };
  const backToDashboard = () => {
    if (page === "fav") {
      navigate("/app/fav");
    } else if (page === "folder") {
      navigate(`/folders/${selectedFolder.id}`);
    } else {
      navigate("/app");
    }
  };
  return (
    <>
      {stepNumber !== 4 ? (
        <CreateAppLayout>
          <div className={classes.createAppSection}>
            <div className={classes.leftSide}>
              <div
                className={classes.backBtn}
                onClick={(e) => {
                  backToDashboard();
                }}
              >
                <ArrowLeftIcon label="" />
                <span>Back to dashboard</span>
              </div>
              <div className={classes.steps}>
                <ProgressTracker items={items} />
              </div>

              {stepNumber === 1 ? (
                <>
                  <div className={classes.question}>
                    Select the way that you want to create your app?
                  </div>
                  <SelectModel
                    selectedWay={selectWay?.length ? selectWay : ""}
                    changeSelectWay={(e) => changeSelectWay(e, "next")}
                  />
                </>
              ) : stepNumber === 2 ? (
                <AppInfoForm
                  changeStep={(number, status) =>
                    changeStepNumber(number, status)
                  }
                  changeStepItems={(e) => changeStepItems(e)}
                  page={page}
                />
              ) : stepNumber === 3 ? (
                <ConfigurationForm
                  selectedWay={selectWay?.length ? selectWay : ""}
                  changeStep={(number, status) =>
                    changeStepNumber(number, status)
                  }
                  changeStepItems={(e) => changeStepItems(e)}
                />
              ) : stepNumber === 5 ? (
                <Review
                  backToDashboard={() => backToDashboard()}
                  changeStep={(number) => changeStepNumber(number)}
                  changeStepItems={(e) => changeStepItems(e)}
                />
              ) : (
                <></>
              )}
            </div>
            <div className={classes.rightSide}>
              {stepNumber === 1 ? (
                <>
                  {selectWay === "blankModel" ? (
                    <NewBlankModel
                      changeStep={(number) => changeStepNumber(number, "next")}
                      changeStepItems={(e) => changeStepItems(e)}
                    />
                  ) : (
                    <CharmDbProjects
                      selectedWay={selectWay?.length ? selectWay : ""}
                      changeStep={(number) => changeStepNumber(number, "next")}
                      changeStepItems={(e) => changeStepItems(e)}
                    />
                  )}
                </>
              ) : stepNumber === 2 ? (
                <AppInfoImage
                  selectedWay={selectWay?.length ? selectWay : ""}
                  changeStep={(number) => changeStepNumber(number, "back")}
                  changeStepItems={(e) => changeStepItems(e)}
                />
              ) : stepNumber === 3 ? (
                <ConfigurationImage
                  selectedWay={selectWay?.length ? selectWay : ""}
                  changeStep={(number) => changeStepNumber(number, "back")}
                  changeStepItems={(e) => changeStepItems(e)}
                />
              ) : stepNumber === 5 ? (
                <ReviewImage />
              ) : (
                <></>
              )}
            </div>
          </div>
        </CreateAppLayout>
      ) : (
        <CharmDbDesign
          changeStep={(number, status) => changeStepNumber(number, status)}
          changeStepItems={(e) => changeStepItems(e)}
        ></CharmDbDesign>
      )}
    </>
  );
};
export default CreateApp;
