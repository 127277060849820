import { Copy } from "iconsax-react";
import classes from "./style/RepositoryForm.module.scss";
import CopyIcon from "../../../assets/icons/CopyIcon.svg";
import { loadingActions } from "../../../store/loading-slice";
import { errorActions } from "../../../store/error-slice";
import { useDispatch } from "react-redux";
import { RepoFactory } from "../../../baseRepository/Factory";
import { RepoType } from "../../../types/sharedTypes";
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
const TenantRepository = RepoFactory.get(RepoType.Tenant);

const RepositoryForm = () => {
  const dispatch = useDispatch();
  const [repositoryLink, setRepositoryLink] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const copyLinkHandler = (elementId: string) => {
    let copyText = document.getElementById(elementId);
    if (copyText) {
      navigator.clipboard.writeText(copyText.innerText);
    }
  };
  const getRepositoryLink = async () => {
    let repolink = "";
    if (localStorage.getItem("selectedWorkspaceId")) {
      dispatch(loadingActions.setHasLoading(true));
      dispatch(errorActions.setHasError(false));
      dispatch(errorActions.setError(""));

      await TenantRepository.getRepositoryLink(
        localStorage.getItem("selectedWorkspaceId")
      )
        .then((res: AxiosResponse<any>) => {
          setRepositoryLink(res.data.value.tenantDto.repositoryPath);
          setUserName(res.data.value.tenantDto?.contributer?.userName);
          setPassword(res.data.value.tenantDto?.contributer?.password);
        })
        .catch((error: AxiosError<any>) => {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        })
        .finally(() => {
          dispatch(loadingActions.setHasLoading(false));
        });
    }
  };
  useEffect(() => {
    getRepositoryLink();
  }, []);
  return (
    <div className={classes.repositoryLayout}>
      <span className={classes.repositoryTitle}>Repository data</span>
      <div className={classes.repositoryCard}>
        <div className={classes.content}>
          <div className={classes.item}>
            <span className={classes.itemTitle}>Repository Link</span>
            <div className={classes.itemDetial}>
              <a
                className={classes.itemLink}
                href={repositoryLink && repositoryLink}
                target="_blank"
                rel="noopener noreferrer"
                id="link"
              >
                {repositoryLink}
              </a>
              <img
                alt=""
                src={CopyIcon}
                className={classes.copyIcon}
                onClick={() => copyLinkHandler("link")}
              />
            </div>
          </div>
          <div className={classes.item}>
            <span className={classes.itemTitle}>Repository User name</span>
            <div className={classes.itemDetial}>
              <span className={classes.detialText} id="userName">
                {userName}
              </span>
              <img
                alt=""
                src={CopyIcon}
                className={classes.copyIcon}
                onClick={() => copyLinkHandler("userName")}
              />
            </div>
          </div>
          <div className={classes.item}>
            <span className={classes.itemTitle}>Repository Password</span>
            <div className={classes.itemDetial}>
              <span className={classes.detialText} id="password">
                {password}
              </span>
              <img
                alt=""
                src={CopyIcon}
                className={classes.copyIcon}
                onClick={() => copyLinkHandler("password")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RepositoryForm;
