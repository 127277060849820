import classes from "./style/VersionOptions.module.scss";
import { AxiosError, AxiosResponse } from "axios";
import { RepoFactory } from "../../../../baseRepository/Factory";
import { useDispatch } from "react-redux";
import { RepoType } from "../../../../types/sharedTypes";
import { errorActions } from "../../../../store/error-slice";
import { loadingActions } from "../../../../store/loading-slice";
import { diagramActions } from "../../../Diagram/store/diagram-slice";
const DiagramRepository = RepoFactory.get(RepoType.Diagram);
interface MyComponent {
  onDelete: (status: boolean) => void;
  version: number;
  diagramId: number;
  current: boolean;
}
const VersionOptions = (props: MyComponent) => {
  const dispatch = useDispatch();
  const deleteHandler = () => {
    if (props.onDelete) return props.onDelete(true);
  };
  const setCurrentVersion = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    let body = {
      version: props.version,
      diagramId: props.diagramId,
    };
    await DiagramRepository.setCurrentVersion(body)
      .then((res: AxiosResponse) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: "Set Current version Successfuly",
            statusCode: 200,
          })
        );
        getDigramsVersionHistory();
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const getDigramsVersionHistory = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await DiagramRepository.getDigramsVersionHistory(props.diagramId)
      .then((res: AxiosResponse) => {
        dispatch(diagramActions.setSelectedDiagram(res.data.value.diagram));
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };

  return (
    <div className={classes.optionBox}>
      <div className={classes.optionItems}>
        <div className={classes.item}>
          <span className={classes.itemTitle}>View</span>
        </div>
        {!props.current && (
          <div className={classes.item} onClick={() => setCurrentVersion()}>
            <span className={classes.itemTitle}>Restore this version</span>
          </div>
        )}
        <div className={classes.item} onClick={() => deleteHandler()}>
          <span className={classes.deleteTitle}>Delete</span>
        </div>
      </div>
    </div>
  );
};
export default VersionOptions;
