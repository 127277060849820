import classes from "./style/TemplateOptions.module.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { diagramActions } from "../../Diagram/store/diagram-slice";
import { projectTemplate } from "../../../types/sharedTypes";
interface myProps {
  template: projectTemplate;
  onDesignView: (status: boolean) => void;
}
const TemplateOptions = (props: myProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const createNewApplication = () => {
    dispatch(diagramActions.setSelectedDiagram(props.template));
    navigate("/app/new?selectedWay=selectFromTemplate&stepNumber=2&page=app");
  };

  return (
    <div className={classes.optionBox}>
      <div className={classes.options} onClick={() => props.onDesignView(true)}>
        <div className={classes.optionItem}>
          <span className={classes.optionTitle}>Design View</span>
        </div>
      </div>
      <div
        className={classes.options}
        onClick={(e) => {
          createNewApplication();
        }}
      >
        <div className={classes.optionItem}>
          <span className={classes.optionTitle}>Create from this template</span>
        </div>
      </div>
    </div>
  );
};
export default TemplateOptions;
