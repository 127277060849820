import classes from "./style/Review.module.scss";
import CheckIcon from "@atlaskit/icon/glyph/check";
import BaseButton from "../../../../components/base/BaseButton";
import BitbuckerDeploymentsIcon from "@atlaskit/icon/glyph/upload";
import { RootState } from "../../../../store";
import { useNavigate } from "react-router-dom";
import { RepoType } from "../../../../types/sharedTypes";
import { useDispatch, useSelector } from "react-redux";
import { loadingActions } from "../../../../store/loading-slice";
import { errorActions } from "../../../../store/error-slice";
import { RepoFactory } from "../../../../baseRepository/Factory";
import { AxiosError, AxiosResponse } from "axios";
import PageIcon from "@atlaskit/icon/glyph/page";
import ChildIssuesIcon from "@atlaskit/icon/glyph/child-issues";
import RefreshIcon from "@atlaskit/icon/glyph/refresh";
import EditorSuccessIcon from "@atlaskit/icon/glyph/editor/success";
import avatar from "../../../../assets/images/avatar image.svg";
import { useState } from "react";
import Modal from "../../../../components/base/Modal";
interface MyComponent {
  changeStep: (number: number) => void;
  changeStepItems: (items: any) => void;
  backToDashboard: () => void;
}
const ApplicationRepository = RepoFactory.get(RepoType.App);
const Review = (props: MyComponent) => {
  const dispatch = useDispatch();
  const [modalFlag, setModalFlag] = useState<boolean>(false);
  const [repositoryLink, setRepositoryLink] = useState<string>("");
  const selectedApp = useSelector(
    (state: RootState) => state.application.selectedApp
  );
  const selectedDiagram = useSelector(
    (state: RootState) => state.diagram.selectedDiagram
  );
  const appDeployment = useSelector(
    (state: RootState) => state.application.appDeployment
  );
  const uploadIcon = () => {
    return <BitbuckerDeploymentsIcon label="" />;
  };
  const backToDashboard = () => {
    props.backToDashboard();
  };
  const changeCharmDb = () => {
    changeStep(1);
    changeStepItemsToLast();
  };
  const changeStep = (number: number) => {
    if (props.changeStep) {
      props.changeStep(number);
    }
  };
  const changeStepItemsToLast = () => {
    props.changeStepItems([
      {
        id: "SelectModel",
        label: "Select model",
        percentageComplete: 0,
        status: "current",
        href: "#",
      },
      {
        id: "appInfo",
        label: "App info",
        percentageComplete: 0,
        status: "unvisited",
        href: "#",
      },
      {
        id: "Configuration",
        label: "Configuration",
        percentageComplete: 0,
        status: "unvisited",
        href: "#",
      },
      {
        id: "Design",
        label: "Design",
        percentageComplete: 0,
        status: "unvisited",
        href: "#",
      },
      {
        id: "Review",
        label: "Review",
        percentageComplete: 0,
        status: "unvisited",
        href: "#",
      },
    ]);
  };
  const appDeployed = () => {
    dispatch(loadingActions.setCreateAppHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    let body = {
      deploymentId: appDeployment.id,
    };

    ApplicationRepository.appDeployed(body)
      .then((res: AxiosResponse<any>) => {
        setRepositoryLink(res.data.value.deployment.repositoryURL);
        setModalFlag(true);
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data.Reasons[0].Message,
            statusCode: 500,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setCreateAppHasLoading(false));
      });
  };
  return (
    <div className={classes.review}>
      <h1 className={classes.header}>Review & Confirmation</h1>
      <div className={classes.selectedDiagramCard}>
        <div className={classes.header}>
          <PageIcon label="" />
          <span>Your Application</span>
        </div>
        <div className={classes.detail}>
          <div className={classes.icon}>
            <ChildIssuesIcon label="" />
          </div>
          <div className={classes.DataName}>{selectedDiagram.name}</div>
          <div className={classes.changes} onClick={(e) => changeCharmDb()}>
            <RefreshIcon label="" />
            <span>Reset</span>
          </div>
        </div>
        <div className={classes.appDetail}>
          <label className={classes.label}>Name</label>
          <span className={classes.name}>{selectedApp.name}</span>
        </div>
        <div className={classes.appDetail}>
          <label className={classes.label}>Description</label>
          <span className={classes.description}>{selectedApp.description}</span>
        </div>
        {selectedApp.contributers && (
          <div className={classes.appDetail}>
            <label className={classes.label}>Members</label>
            <span className={classes.membersList}>
              {selectedApp.contributers.map((user, index) => {
                return (
                  <div key={index} className={classes.member}>
                    <div className={classes.userImage}>
                      <img
                        src={avatar}
                        alt="avatar"
                        className={classes.image}
                      />
                    </div>
                    <div className={classes.memberName}>{user.name}</div>
                  </div>
                );
              })}
            </span>
          </div>
        )}
        <div className={classes.appDetail}>
          <label className={classes.label}>Configuration</label>
        </div>
        <div className={classes.row}>
          {appDeployment.techStack.architecture === 1 ? (
            <span className={classes.tag}>
              <CheckIcon label="" />
              <span>Monolithic</span>
            </span>
          ) : appDeployment.techStack.architecture === 2 ? (
            <span className={classes.tag}>
              <CheckIcon label="" />
              <span>Microservices</span>
            </span>
          ) : (
            ""
          )}
          {appDeployment.techStack.caching === 2 ? (
            <span className={classes.tag}>
              <CheckIcon label="" />
              <span>InMemory</span>
            </span>
          ) : appDeployment.techStack.caching === 3 ? (
            <span className={classes.tag}>
              <CheckIcon label="" />
              <span>Redis</span>
            </span>
          ) : (
            ""
          )}
          {appDeployment.techStack.dataBase === 1 ? (
            <span className={classes.tag}>
              <CheckIcon label="" />
              <span>Mongo</span>
            </span>
          ) : appDeployment.techStack.dataBase === 2 ? (
            <span className={classes.tag}>
              <CheckIcon label="" />
              <span>Postgres</span>
            </span>
          ) : appDeployment.techStack.dataBase === 3 ? (
            <span className={classes.tag}>
              <CheckIcon label="" />
              <span>SqlServer</span>
            </span>
          ) : (
            ""
          )}
          {appDeployment.techStack.messaging === 2 ? (
            <span className={classes.tag}>
              <CheckIcon label="" />
              <span>Rabbitmq</span>
            </span>
          ) : appDeployment.techStack.messaging === 3 ? (
            <span className={classes.tag}>
              <CheckIcon label="" />
              <span>Kafka</span>
            </span>
          ) : (
            ""
          )}
          {appDeployment.techStack.scheduler === 2 ? (
            <span className={classes.tag}>
              <CheckIcon label="" />
              <span>Hangfire</span>
            </span>
          ) : appDeployment.techStack.scheduler === 3 ? (
            <span className={classes.tag}>
              <CheckIcon label="" />
              <span>Internal</span>
            </span>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.btnSection}>
          <BaseButton
            size="sm"
            title="Save & Publish later"
            color="secondary"
            onClickHandler={(e) => backToDashboard()}
          />
          <BaseButton
            size="sm"
            title="Publish now"
            color="primary"
            hasLeftIcon={true}
            icon={uploadIcon()}
            onClickHandler={(e) => appDeployed()}
          />
          <span className={classes.massege}>You have (1) publishes left.</span>
        </div>
      </div>
      {modalFlag && (
        <Modal
          body={
            <div className={classes.showMassage}>
              <div className={classes.header}>Create application </div>
              <div className={classes.massage}>
                <div className={classes.icon}>
                  <EditorSuccessIcon label="" />
                </div>
                <span className={classes.yourMassage}>
                  Your app created successfully.
                </span>
              </div>
              <div className={classes.footer}>
                <BaseButton
                  size="sm"
                  title="Back to dashboard"
                  color="secondary"
                  onClickHandler={(e) => backToDashboard()}
                />
                <a
                  href={repositoryLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.linkButton}
                >
                  <BaseButton
                    size="sm"
                    title="Go to Repository"
                    color="primary"
                  />
                </a>
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};
export default Review;
