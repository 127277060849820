import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import DashboardRouter from "../modules/dashboard/routes/DashboardRouter";
import FolderRouter from "../modules/Folder/routes/FolderRouter";
import ApplicationRouter from "../modules/application/routes/ApplicationRouter";
import { useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../store/auth-slice";
import { useCookies } from "react-cookie";
import { RootState } from "../store";
import TemplateRouter from "../modules/template/routes/TemplateRouter";
import AccountRouter from "../modules/account/routes/AccountRouter";
const Router = () => {
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const isAuthanticated = cookies.access_token ? true : false;
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const urlParams = new URLSearchParams(window.location.search);
  const selectedWorkspaceId = urlParams.get("selectedWorkspaceId");
  const idToken = urlParams.get("idToken");
  const selectedLicensedWorkspace = urlParams.get("selectedLicensedWorkspace");

  useEffect(() => {
    if (selectedWorkspaceId) {
      localStorage.setItem("selectedWorkspaceId", selectedWorkspaceId);
      localStorage.setItem("idToken", idToken ? idToken : "");
      localStorage.setItem(
        "selectedLicensedWorkspace",
        selectedLicensedWorkspace ? selectedLicensedWorkspace : ""
      );
      window.location.href =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
    }
  }, [selectedWorkspaceId]);
  useEffect(() => {
    if (localStorage.getItem("idToken")) {
      dispatch(authActions.setUserInfo(localStorage.getItem("idToken")));
      dispatch(authActions.setIsAuthenticated(true));
    }
  }, [dispatch]);
  useEffect(() => {
    if (!cookies.access_token) {
      window.location.href =
        process.env.REACT_APP_ADMIN_BASE_DOMAIN + "/auth/login";
    }
  }, [cookies.access_token]);

  useLayoutEffect(() => {
    dispatch(authActions.setIsAuthenticated(isAuthanticated));
    dispatch(authActions.setSelectedWorkSpaceId());
  }, [isAuthanticated, dispatch, userInfo]);
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/*" element={<Navigate replace to="/app" />} />
        {/* <Route path="/" element={<DashboardRouter />} /> */}
        <Route path="/app/*" element={<ApplicationRouter />} />
        <Route path="/folders/*" element={<FolderRouter />} />
        <Route path="/templates/*" element={<TemplateRouter />} />
        <Route path="/account/*" element={<AccountRouter />} />
        <Route path="*" element={<Navigate replace to="/app" />} />
      </Routes>
    </BrowserRouter>
  );
};
export default Router;
