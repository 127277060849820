import DeploymentTile from "./DeploymentTile";
import classes from "./style/DeploymentPanel.module.scss";
import { AxiosError, AxiosResponse } from "axios";
import { useParams } from "react-router-dom";
import { RepoType } from "../../../../types/sharedTypes";
import { useDispatch } from "react-redux";
import { loadingActions } from "../../../../store/loading-slice";
import { errorActions } from "../../../../store/error-slice";
import { RepoFactory } from "../../../../baseRepository/Factory";
import { ApplicationAction } from "../../store/application-slice";
import { useEffect } from "react";
const ApplicationRepository = RepoFactory.get(RepoType.App);
const DeploymentPanel = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const getProjectById = () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));

    ApplicationRepository.getProjectById(projectId)
      .then((res: AxiosResponse<any>) => {
        dispatch(ApplicationAction.setSelectedAppDetail(res.data.value));
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  useEffect(() => {
    getProjectById();
  }, []);
  return (
    <div className={classes.panelLayout}>
      <DeploymentTile getProjectById={() => getProjectById()} />
    </div>
  );
};
export default DeploymentPanel;
