import axios from "axios";
const resource =
  process.env.REACT_APP_CHARMDB_API_BASE_DOMAIN +
  "/api/v1/charmGraphService/Diagrams";
const templateResource =
  process.env.REACT_APP_CHARMDB_API_BASE_DOMAIN +
  "/api/v1/charmGraphService/Templates";
const dataVariationsResource =
  process.env.REACT_APP_CHARMDB_API_BASE_DOMAIN +
  "/api/v1/charmGraphService/DataVariations";

const config = {
  headers: { Authorization: `Bearer ${getCookie("access_token")}` },
};
function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts: any = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}
const DiagramRepository = {
  createDiagram(body: {
    name: string;
    description: string;
    isPrivate: boolean;
    dataVariationId: number;
    tenantId: number;
    businessURl: string;
    tags: any;
    logo: string;
  }) {
    return axios.post(`${resource}`, body, config);
  },
  getAllDigrams(id: number) {
    return axios.get(`${resource}/GetAllDiagrams?tenantId=${id}`);
  },
  getDiagram(id: number) {
    return axios.get(`${resource}?id=${id}`);
  },
  getDigramsVersionHistory(diagramId: number) {
    return axios.get(`${resource}/GetVersionHistory?diagramId=${diagramId}`);
  },
  createNewDigramsVersion(body: { diagramId: number; fromVersion: number }) {
    return axios.post(`${resource}/CreateNewVersion`, body);
  },
  setCurrentVersion(body: { diagramId: number; version: number }) {
    return axios.post(`${resource}/SetCurrentVersion`, body);
  },
  RemoveVersion(body: { diagramId: number; version: number }) {
    return axios.post(`${resource}/RemoveVersion`, body);
  },
  getVersion(body: { diagramId: number; version: number }) {
    return axios.post(`${resource}/GetVersion`, body);
  },
  getAllTemplates() {
    return axios.get(`${templateResource}/GetAllTemplates`);
  },
  getAllDataVariations() {
    return axios.get(`${dataVariationsResource}/GetAll`);
  },
};
export default DiagramRepository;
