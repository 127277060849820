import classes from "./style/DashboardLayout.module.scss";
import { ReactNode } from "react";
import Header from "../core/Header";
import AppSettingMenu from "../core/AppSettingMenu";
import ShortcutIcon from "@atlaskit/icon/glyph/shortcut";
interface LayoutProps {
  children: ReactNode;
}
const AppSettingLayout = ({ children }: LayoutProps) => {
  return (
    <div className={classes.layout}>
      <div className={classes.header}>
        <Header />
      </div>

      <div className={classes.dashboardBody}>
        <div className={classes.sideBar}>
          <AppSettingMenu />
          <div className={classes.sideBarFooter}>
            <div className={classes.docButton}>
              <ShortcutIcon label="" primaryColor={"#626F86"} />
              <span className={classes.buttonTitle}>Documents</span>
            </div>
          </div>
        </div>
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
};
export default AppSettingLayout;
