import { Route, Routes } from "react-router-dom";
import Application from "../pages/Application";
import Deployment from "../pages/appSetting/Deployment";
import DataModels from "../pages/appSetting/DataModels";
import VersionHistory from "../pages/appSetting/VersionHistory";
import Members from "../pages/appSetting/Members";
import CreateApp from "../pages/createApp/CreateApp";
import Setting from "../pages/appSetting/Setting";
import FavoriteApplications from "../pages/FavoriteApplications";
const ApplicationRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Application />} />
      <Route path="/fav" element={<FavoriteApplications />} />
      <Route path="/new" element={<CreateApp />} />
      <Route path="/deployment/:projectId" element={<Deployment />} />
      {/* <Route path="/data-model/:projectId" element={<DataModels />} /> */}
      <Route path="/version-history/:projectId" element={<VersionHistory />} />
      <Route path="/members/:projectId" element={<Members />} />
      <Route path="/repository/:projectId" element={<VersionHistory />} />
      <Route path="/setting/:projectId" element={<Setting />} />
    </Routes>
  );
};
export default ApplicationRouter;
