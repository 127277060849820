import { useEffect } from "react";
import DashboardLayout from "../../../components/layouts/DashboardLayout";
import AccountDataFooter from "../components/AccountDataFooter";
import PlanForm from "../components/PlanForm";
import RepositoryForm from "../components/RepositoryForm";
import classes from "./style/AccountData.module.scss";
import { useDispatch } from "react-redux";
import { menuAction } from "../../../store/menu-slice";
import { folderActions } from "../../Folder/store/folder-slice";
const AccountData = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(menuAction.setSelectedMenu("Account Data"));
    dispatch(folderActions.setSelectedFolderMenu(""));
  }, []);
  return (
    <DashboardLayout>
      <div className={classes.accountLayout}>
        <div className={classes.header}>
          <span className={classes.headerTitle}>Account Data</span>
        </div>
        <div className={classes.formBody}>
          <div className={classes.bodySection}>
            <PlanForm />
          </div>
          <div className={classes.bodySection}>
            <RepositoryForm />
          </div>
        </div>
        <div className={classes.formFooter}>
          <AccountDataFooter />
        </div>
      </div>
    </DashboardLayout>
  );
};
export default AccountData;
