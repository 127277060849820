import classes from "./style/SelectModel.module.scss";
import FolderIcon from "@atlaskit/icon/glyph/folder";
import DocumentIcon from "@atlaskit/icon/glyph/document";
import EditorLayoutTwoEqualIcon from "@atlaskit/icon/glyph/editor/layout-two-equal";
import { IconColor } from "../../../../types/sharedTypes";
interface myComponentProps {
  changeSelectWay: (way: string) => void;
  selectedWay: string;
}
const SelectModel = (props: myComponentProps) => {
  const changeSelectWay = (way: string) => {
    if (props.changeSelectWay) {
      props.changeSelectWay(way);
    }
  };
  return (
    <div className={classes.way}>
      <div
        className={
          props.selectedWay === "selectFromCharmdb"
            ? classes.selectedCard
            : classes.card
        }
        onClick={(e) => {
          changeSelectWay("selectFromCharmdb");
        }}
      >
        <div className={classes.header}>
          <FolderIcon label="" primaryColor={IconColor.blue} />
          <span> Select from CharmDB</span>
        </div>
        <div className={classes.detail}>
          Design new model view Design new model viewDesign new model viewDesign
          new model viewDesign new model view
        </div>
      </div>
      <div
        className={
          props.selectedWay === "blankModel"
            ? classes.selectedCard
            : classes.card
        }
        onClick={(e) => {
          changeSelectWay("blankModel");
        }}
      >
        <div className={classes.header}>
          <DocumentIcon label="" primaryColor={IconColor.blue} />
          <span> Design new model</span>
        </div>
        <div className={classes.detail}>
          Design new model view Design new model viewDesign new model viewDesign
          new model viewDesign new model view
        </div>
      </div>
      <div
        className={
          props.selectedWay === "selectFromTemplate"
            ? classes.selectedCard
            : classes.card
        }
        onClick={(e) => {
          changeSelectWay("selectFromTemplate");
        }}
      >
        <div className={classes.header}>
          <EditorLayoutTwoEqualIcon label="" primaryColor={IconColor.blue} />
          <span> Select from Templates</span>
        </div>
        <div className={classes.detail}>
          Design new model view Design new model viewDesign new model viewDesign
          new model viewDesign new model view
        </div>
      </div>
    </div>
  );
};
export default SelectModel;
