import classes from "./style/Folders.module.scss";
import DashboardLayout from "../../../components/layouts/DashboardLayout";
import FoldersHeader from "../components/FoldersHeader";
import FolderPanel from "../components/FolderPanel";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { menuAction } from "../../../store/menu-slice";
import { folderActions } from "../store/folder-slice";
import { RootState } from "../../../store";
import { FolderSortOptions, Option } from "../../../types/sharedTypes";
import { SortFolders } from "../../../types/sharedTypes";
const Folders = () => {
  const dispatch = useDispatch();
  const sortType = useSelector((state: RootState) => state.folder.sortType);
  const folderList = useSelector((state: RootState) => state.folder.folderList);
  const sortHandler = (status: Option) => {
    dispatch(folderActions.setSortType(status));
  };
  useEffect(() => {
    dispatch(folderActions.setFolderList(SortFolders(sortType, folderList)));
  }, [sortType]);
  useEffect(() => {
    dispatch(folderActions.setSelectedFolderMenu("All Folders"));
    dispatch(menuAction.setSelectedMenu(""));
  }, []);

  return (
    <DashboardLayout>
      <div className={classes.foldersLayout}>
        <FoldersHeader
          defaultSort={sortType}
          sortOptions={FolderSortOptions}
          onSort={sortHandler}
        />
        <FolderPanel />
      </div>
    </DashboardLayout>
  );
};
export default Folders;
