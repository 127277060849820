import BaseRepository from "../../../baseRepository/BaseRepository";
import axios from "axios";
export const baseURL = process.env.REACT_APP_API_BASE_DOMAIN;
axios.defaults.baseURL = baseURL;
const resource = "/api/v1/codeInOne/Folders";
const FolderRepository = {
  getAllFolders(id: number) {
    return BaseRepository.get(`${resource}/GetAll?tenantId=${id}`);
  },
  getFolderById(id: number) {
    return BaseRepository.get(`${resource}/${id}`);
  },
  createFolder(body: { tenantId: number; projectId: number; name: string }) {
    return BaseRepository.post(`${resource}`, body);
  },
  deleteFolder(id: number) {
    return BaseRepository.delete(`${resource}?id=${id}`);
  },
  updateFolder(body: { folderId: number; name: string }) {
    return BaseRepository.put(`${resource}`, body);
  },
  assignApp(body: { projectId: string; folderId: string; tenantId: string }) {
    return BaseRepository.post(`${resource}/Assign`, body);
  },
  unassignApp(body: { projectId: string; folderId: string }) {
    return BaseRepository.post(`${resource}/unAssign`, body);
  },
};
export default FolderRepository;
