import classes from "./style/Folders.module.scss";
import DashboardLayout from "../../../components/layouts/DashboardLayout";
import FolderDetails from "../components/FolderDetials";
import ApplicationHeader from "../../application/components/ApplicationHeader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  ApplicationSortOptions,
  Option,
  SortSelectedFolderApp,
} from "../../../types/sharedTypes";
import { ApplicationAction } from "../../application/store/application-slice";
import { useEffect } from "react";
const Folder = () => {
  const dispatch = useDispatch();
  const selectedFolder = useSelector(
    (state: RootState) => state.folder.selectedFolder
  );
  const sortType = useSelector(
    (state: RootState) => state.application.sortType
  );
  const selectedFolderApplist = useSelector(
    (state: RootState) => state.application.selectedFolderApplist
  );
  const sortHandler = (status: Option) => {
    dispatch(ApplicationAction.setSortType(status));
  };
  useEffect(() => {
    dispatch(
      ApplicationAction.setSelectedFolderApplist(
        SortSelectedFolderApp(sortType, [...selectedFolderApplist])
      )
    );
  }, [sortType, dispatch]);
  return (
    <DashboardLayout>
      <div className={classes.foldersLayout}>
        <ApplicationHeader
          title={`My Applications in ${selectedFolder.name}`}
          sortOptions={ApplicationSortOptions}
          defaultSort={sortType}
          onSort={sortHandler}
          page="folder"
        />
        <FolderDetails />
      </div>
    </DashboardLayout>
  );
};
export default Folder;
