import classes from "./style/FolderPanel.module.scss";
import FolderTile from "./FolderTile";
import { useSelector } from "react-redux";
import { folderActions } from "../store/folder-slice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { FolderType, SortFolders } from "../../../types/sharedTypes";
import { RootState } from "../../../store";
const FolderPanel = () => {
  const dispatch = useDispatch();
  const folderList = useSelector((state: RootState) => state.folder.folderList);

  return (
    <div className={classes.panelBox}>
      {folderList?.map((folder: FolderType, index) => {
        return <FolderTile folder={folder} key={index} />;
      })}
    </div>
  );
};
export default FolderPanel;
