import classes from "./style/CharmDbTile.module.scss";
import ChildIssuesIcon from "@atlaskit/icon/glyph/child-issues";
import BaseButton from "../../../../components/base/BaseButton";
import { useDispatch } from "react-redux";
import { diagramActions } from "../../../Diagram/store/diagram-slice";
import { useEffect, useState } from "react";
interface MyComponent {
  diagram: any;
  changeStep: (number: number) => void;
  changeStepItems: (items: any) => void;
}
const CharmDbTile = (props: MyComponent) => {
  const dispatch = useDispatch();
  const [diagramImage, setDiagramImage] = useState<string>("");

  const selectCharmProject = () => {
    dispatch(diagramActions.setSelectedDiagram(props.diagram));
    changeStep(2);
    changeStepItems();
  };
  const changeStep = (number: number) => {
    if (props.changeStep) {
      props.changeStep(number);
    }
  };
  const changeStepItems = () => {
    props.changeStepItems([
      {
        id: "SelectModel",
        label: "Select model",
        percentageComplete: 100,
        status: "disabled",
        href: "#",
      },
      {
        id: "appInfo",
        label: "App info",
        percentageComplete: 0,
        status: "current",
        href: "#",
      },
      {
        id: "Configuration",
        label: "Configuration",
        percentageComplete: 0,
        status: "unvisited",
        href: "#",
      },
      {
        id: "Design",
        label: "Design",
        percentageComplete: 0,
        status: "unvisited",
        href: "#",
      },
      {
        id: "Review",
        label: "Review",
        percentageComplete: 0,
        status: "unvisited",
        href: "#",
      },
    ]);
  };
  useEffect(() => {
    if (props.diagram?.models?.length > 0) {
      let currenVersion = props.diagram?.models.find(
        (model: any) => model?.version === props.diagram?.diagramCurrentVersion
      );
      setDiagramImage(currenVersion?.snapshot);
    }
  }, [props.diagram]);
  return (
    <div className={classes.tileContainer}>
      {diagramImage ? (
        <img
          alt="diagramImage"
          className={classes.backgroundImage}
          src={diagramImage}
        />
      ) : (
        <div className={classes.tileIcon}>
          <ChildIssuesIcon label="" size="large" />
        </div>
      )}
      <div className={classes.tileContent}>
        <div className={classes.contentHeader}>
          <span className={classes.projectName}>{props.diagram.name}</span>
        </div>
      </div>
      <div className={classes.hoverSection}>
        <BaseButton
          title="Select"
          color="primary"
          onClickHandler={(e) => selectCharmProject()}
        />
      </div>
    </div>
  );
};
export default CharmDbTile;
