import classes from "./style/WarningModal.module.scss";
import ErrorIcon from "@atlaskit/icon/glyph/error";
import { IconColor } from "../../types/sharedTypes";
import BaseButton from "../../components/base/BaseButton";
interface MyComponent {
  onClose: (status: boolean) => void;
  onDelete: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  type: string;
  header: string;
  alert: string;
}
const WarningModal = (props: MyComponent) => {
  const closeHandler = () => {
    if (props.onClose) return props.onClose(false);
  };
  //   const saveAndLeave = () => {
  //     props.saveAction();
  //     props.onLeave();
  //   };
  return (
    <div className={classes.backgroundModal}>
      <div className={classes.modalLayout}>
        <div className={classes.header}>
          <ErrorIcon label="" primaryColor={IconColor.danger} />
          <span className={classes.headerTitle}>{props.header}</span>
        </div>
        <div className={classes.content}>
          <span className={classes.contentText}>{props.alert}</span>
        </div>
        <div className={classes.modalFooter}>
          <BaseButton
            title="Cancel"
            size="sm"
            color="secondary"
            onClickHandler={(e) => closeHandler()}
          />
          {props.type === "exit" && (
            <BaseButton
              title="Save and Leave"
              size="sm"
              hasRightIcon={false}
              hasLeftIcon={false}
              color="primary"
              //   onClickHandler={(e) => saveAndLeave()}
            />
          )}

          <BaseButton
            title={props.type === "exit" ? "Leave" : "Yes,delete it"}
            hasRightIcon={false}
            hasLeftIcon={false}
            color="danger"
            size="sm"
            onClickHandler={props.onDelete}
          />
        </div>
      </div>
    </div>
  );
};
export default WarningModal;
