import { useLocation } from "react-router-dom";
import { useMemo, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { RepoType } from "../../../../types/sharedTypes";
import { useDispatch, useSelector } from "react-redux";
import { loadingActions } from "../../../../store/loading-slice";
import { errorActions } from "../../../../store/error-slice";
import { RepoFactory } from "../../../../baseRepository/Factory";
import { ApplicationAction } from "../../store/application-slice";
import { RootState } from "../../../../store";
import classes from "./style/ConfigurationForm.module.scss";
import Tag from "../../../../components/base/Tag";
import BaseButton from "../../../../components/base/BaseButton";
import ArrowRightIcon from "@atlaskit/icon/glyph/arrow-right";
import Input from "../../../../components/base/Input";
const ApplicationRepository = RepoFactory.get(RepoType.App);
interface MyComponent {
  changeStep: (number: number, status: string) => void;
  changeStepItems: (items: any) => void;
  selectedWay: string;
}

const ConfigurationForm = (props: MyComponent) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const selectedWorkSpaceId = useSelector(
    (state: RootState) => state.auth.selectedWorkSpaceId
  );
  const selectedApp = useSelector(
    (state: RootState) => state.application.selectedApp
  );
  const selectedDiagram = useSelector(
    (state: RootState) => state.diagram.selectedDiagram
  );
  const appDeployment = useSelector(
    (state: RootState) => state.application.appDeployment
  );
  const [repositoryName, setRepositoryName] = useState(
    searchParams.get("status") === "back" ? appDeployment.name : ""
  );

  const [stackTemplate, setStackTemplate] = useState(
    searchParams.get("status") === "back"
      ? appDeployment.techStack
      : {
          architecture: 0,
          caching: 0,
          dataBase: 0,
          messaging: 0,
          scheduler: 0,
        }
  );
  const databaseList = [
    {
      id: 1,
      title: "Mongo",
    },
    {
      id: 2,
      title: "Postgres",
    },
    {
      id: 3,
      title: "SqlServer",
    },
  ];
  const msgPlatfrom = [
    {
      id: 2,
      title: "Rabbitmq",
    },
    {
      id: 3,
      title: "Kafka",
    },
  ];
  const arcApproach = [
    {
      id: 1,
      title: "Monolithic",
    },
    {
      id: 2,
      title: "Microservices",
    },
  ];
  const caching = [
    {
      id: 2,
      title: "InMemory",
    },
    {
      id: 3,
      title: "Redis",
    },
  ];
  const scheduler = [
    {
      id: 2,
      title: "Hangfire",
    },
    {
      id: 3,
      title: "Internal",
    },
  ];
  const nextStep = () => {
    addDeployment();
  };
  const repositoryNameHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setRepositoryName(e.currentTarget.value);
  };
  const addDeployment = () => {
    if (!repositoryName.length) {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          message: "Please enter project name",
          statusCode: 500,
        })
      );
    } else {
      dispatch(loadingActions.setHasLoading(true));
      dispatch(errorActions.setHasError(false));
      dispatch(errorActions.setError(""));

      let body = {
        name: repositoryName,
        projectId: selectedApp.id,
        tenantId: selectedWorkSpaceId,
        stackTemplate: stackTemplate,
        diagramId: selectedDiagram ? selectedDiagram.id : 1,
        diagramVersion:
          selectedDiagram && selectedDiagram.diagramCurrentVersion
            ? selectedDiagram.diagramCurrentVersion
            : 1,
      };

      ApplicationRepository.addDeployment(body)
        .then((res: AxiosResponse<any>) => {
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: "Set up configuration Successfuly",
              statusCode: 200,
            })
          );
          dispatch(
            ApplicationAction.setAppDeployment(res.data.value.deployment)
          );
          changeStepItemsToNext();
          changeStep(props.selectedWay === "blankModel" ? 4 : 5, "next");
        })
        .catch((error: AxiosError<any>) => {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        })
        .finally(() => {
          dispatch(loadingActions.setHasLoading(false));
        });
    }
  };
  const updateDeployment = () => {
    if (!repositoryName.length) {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          message: "Please enter project name",
          statusCode: 500,
        })
      );
    } else {
      dispatch(loadingActions.setHasLoading(true));
      dispatch(errorActions.setHasError(false));
      dispatch(errorActions.setError(""));

      let body = {
        deploymentId: appDeployment.id,
        name: repositoryName,
        projectId: selectedApp.id,
        tenantId: selectedWorkSpaceId,
        stackTemplate: stackTemplate,
        diagramId: selectedDiagram ? selectedDiagram.id : 1,
        diagramVersion:
          selectedDiagram && selectedDiagram.diagramCurrentVersion
            ? selectedDiagram.diagramCurrentVersion
            : 1,
      };

      ApplicationRepository.editDeployment(body)
        .then((res: AxiosResponse<any>) => {
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: "Set up configuration Successfuly",
              statusCode: 200,
            })
          );
          dispatch(
            ApplicationAction.setAppDeployment(res.data.value.deployment)
          );
          changeStepItemsToNext();
          changeStep(props.selectedWay === "blankModel" ? 4 : 5, "next");
        })
        .catch((error: AxiosError<any>) => {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        })
        .finally(() => {
          dispatch(loadingActions.setHasLoading(false));
        });
    }
  };
  const changeStep = (number: number, status: string) => {
    if (props.changeStep) {
      props.changeStep(number, status);
    }
  };
  const changeStepItemsToNext = () => {
    props.changeStepItems([
      {
        id: "SelectModel",
        label: "Select model",
        percentageComplete: 100,
        status: "disabled",
        href: "#",
      },
      {
        id: "appInfo",
        label: "App info",
        percentageComplete: 100,
        status: "disabled",
        href: "#",
      },
      {
        id: "Configuration",
        label: "Configuration",
        percentageComplete: 100,
        status: "disabled",
        href: "#",
      },
      {
        id: "Design",
        label: "Design",
        percentageComplete: props.selectedWay === "blankModel" ? 0 : 100,
        status: props.selectedWay === "blankModel" ? "current" : "disabled",
        href: "#",
      },
      {
        id: "Review",
        label: "Review",
        percentageComplete: 0,
        status: props.selectedWay === "blankModel" ? "unvisited" : "current",
        href: "#",
      },
    ]);
  };
  const changeStepItemsToLast = () => {
    props.changeStepItems([
      {
        id: "SelectModel",
        label: "Select model",
        percentageComplete: 100,
        status: "disabled",
        href: "#",
      },
      {
        id: "appInfo",
        label: "App info",
        percentageComplete: 0,
        status: "current",
        href: "#",
      },
      {
        id: "Configuration",
        label: "Configuration",
        percentageComplete: 0,
        status: "unvisited",
        href: "#",
      },
      {
        id: "Design",
        label: "Design",
        percentageComplete: 0,
        status: "unvisited",
        href: "#",
      },
      {
        id: "Review",
        label: "Review",
        percentageComplete: 0,
        status: "unvisited",
        href: "#",
      },
    ]);
  };

  return (
    <div className={classes.formLayout}>
      <span className={classes.header}>Set up configuration</span>
      <Input
        name="Project name"
        placeholder="Project name"
        value={repositoryName}
        handleChange={(e) => repositoryNameHandler(e)}
      />
      <div className={classes.formBox}>
        {/**databas */}
        <div className={classes.formItem}>
          <span className={classes.itemTitle}>What is your Database?</span>
          <div className={classes.tagBox}>
            {databaseList.map((item, index) => {
              return (
                <Tag
                  onClick={(e) => {
                    setStackTemplate({ ...stackTemplate, dataBase: item.id });
                  }}
                  selected={stackTemplate.dataBase === item.id}
                  clickable={stackTemplate.dataBase !== item.id}
                  title={item.title}
                  id={item.id}
                  key={index}
                  rounded={true}
                />
              );
            })}
          </div>
        </div>

        {/** messaging platform */}

        <div className={classes.formItem}>
          <span className={classes.itemTitle}>
            What is your messaging platform?
          </span>
          <div className={classes.tagBox}>
            {msgPlatfrom.map((item, index) => {
              return (
                <Tag
                  onClick={(e) => {
                    setStackTemplate({ ...stackTemplate, messaging: item.id });
                  }}
                  selected={stackTemplate.messaging === item.id}
                  clickable={stackTemplate.messaging !== item.id}
                  title={item.title}
                  id={item.id}
                  key={index}
                  rounded={true}
                />
              );
            })}
          </div>
        </div>

        {/** architecture approach */}
        <div className={classes.formItem}>
          <span className={classes.itemTitle}>
            What is your architecture approach?
          </span>
          <div className={classes.tagBox}>
            {arcApproach.map((item, index) => {
              return (
                <Tag
                  onClick={(e) => {
                    setStackTemplate({
                      ...stackTemplate,
                      architecture: item.id,
                    });
                  }}
                  selected={stackTemplate.architecture === item.id}
                  clickable={stackTemplate.architecture !== item.id}
                  title={item.title}
                  id={item.id}
                  key={index}
                  rounded={true}
                />
              );
            })}
          </div>
        </div>
        {/** caching */}
        <div className={classes.formItem}>
          <span className={classes.itemTitle}>What is your Caching?</span>
          <div className={classes.tagBox}>
            {caching.map((item, index) => {
              return (
                <Tag
                  onClick={(e) => {
                    setStackTemplate({ ...stackTemplate, caching: item.id });
                  }}
                  selected={stackTemplate.caching === item.id}
                  clickable={stackTemplate.caching !== item.id}
                  title={item.title}
                  id={item.id}
                  key={index}
                  rounded={true}
                />
              );
            })}
          </div>
        </div>
        {/** scheduler */}
        <div className={classes.formItem}>
          <span className={classes.itemTitle}>What is your Scheduler?</span>
          <div className={classes.tagBox}>
            {scheduler.map((item, index) => {
              return (
                <Tag
                  onClick={(e) => {
                    setStackTemplate({ ...stackTemplate, scheduler: item.id });
                  }}
                  selected={stackTemplate.scheduler === item.id}
                  clickable={stackTemplate.scheduler !== item.id}
                  title={item.title}
                  id={item.id}
                  key={index}
                  rounded={true}
                />
              );
            })}
          </div>
        </div>

        <div className={classes.buttonBox}>
          <BaseButton
            size="sm"
            title="Back"
            color="secondary"
            onClickHandler={(e) => {
              changeStepItemsToLast();
              changeStep(2, "back");
            }}
          />
          <BaseButton
            size="sm"
            title="Continue"
            hasRightIcon={true}
            icon={<ArrowRightIcon label="" />}
            color="primary"
            onClickHandler={(e) =>
              searchParams.get("status") === "back"
                ? updateDeployment()
                : nextStep()
            }
          />
        </div>
      </div>
    </div>
  );
};
export default ConfigurationForm;
