import classes from "./style/ApplicationPanel.module.scss";
import ApplicationTile from "./ApplicationTile";
import { errorActions } from "../../../store/error-slice";
import { loadingActions } from "../../../store/loading-slice";
import { ApplicationAction } from "../store/application-slice";
import { useDispatch, useSelector } from "react-redux";
import { RepoFactory } from "../../../baseRepository/Factory";
import { useEffect } from "react";
import { RepoType, SortFavoriteApp } from "../../../types/sharedTypes";
import { RootState } from "../../../store";
import { AxiosError, AxiosResponse } from "axios";
import EmptyFolder from "../../../core/helpers/EmptyFolder";
const ApplicationRepository = RepoFactory.get(RepoType.App);

const FavoriteApplicationPanel = () => {
  const dispatch = useDispatch();

  const favoriteAppList = useSelector(
    (state: RootState) => state.application.favoriteAppList
  );
  const favoriteSortType = useSelector(
    (state: RootState) => state.application.favoriteSortType
  );
  const selectedWorkSpaceId = useSelector(
    (state: RootState) => state.auth.selectedWorkSpaceId
  );
  const getFavoriteApp = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await ApplicationRepository.getFavoriteApplication(selectedWorkSpaceId)
      .then((res: AxiosResponse<any>) => {
        dispatch(
          ApplicationAction.setFavoriteAppList(
            SortFavoriteApp(favoriteSortType, res.data.value.favorites)
          )
        );
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  useEffect(() => {
    if (selectedWorkSpaceId) {
      getFavoriteApp();
    }
  }, [selectedWorkSpaceId, favoriteAppList.length]);

  useEffect(() => {
    dispatch(ApplicationAction.setFavoriteAppList(favoriteAppList));
  }, [favoriteSortType]);

  return (
    <div className={classes.panelBox}>
      {favoriteAppList?.length ? (
        <>
          {favoriteAppList?.map((item: any, index: number) => {
            return <ApplicationTile app={item} key={index} />;
          })}
        </>
      ) : (
        <div className={classes.empty}>
          <EmptyFolder pageType="fav" />
        </div>
      )}
    </div>
  );
};
export default FavoriteApplicationPanel;
