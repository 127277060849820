export type Optional<T> = T | null;
export type OptionalOrMissing<T> = T | null | undefined;

export interface Option {
  value: string;
  title: string;
  disable?: boolean;
  selected?: boolean;
}
export enum IconColor {
  blue = "#0C66E4",
  grey = "#758195",
  light_grey = "#0000003B",
  dark_grey = "#626F86",
  danger = "#C9372C",
  white = "#FFFFF",
  Natural_800 = "#44546f",
}
export enum BorderColor {
  blue = "#0C66E4",
}
export enum TagColor {
  blue = "#CCE0FF",
  grey = "#DCDFE4",
  green = "#BAF3DB",
  light_blue = "#C6EDFB",
  pink = "#FFD5D2",
  clicked = "#0055cc",
  white = "#FFFF",
}
export const TagBorderColor: Record<TagColor, string> = {
  [TagColor.blue]: "",
  [TagColor.grey]: "",
  [TagColor.green]: "",
  [TagColor.light_blue]: "",
  [TagColor.pink]: "",
  [TagColor.clicked]: "",
  [TagColor.white]: "#172b4d",
};
export const TagTitleColor: Record<TagColor, string> = {
  [TagColor.blue]: "#0055CC",
  [TagColor.grey]: "#091E42",
  [TagColor.green]: "#216E4E",
  [TagColor.light_blue]: "#206A83",
  [TagColor.pink]: "#AE2E24",
  [TagColor.clicked]: "#FFFF",
  [TagColor.white]: "#172b4d",
};
export enum Colors {
  natural_01 = "#ffffff",
  natural_02 = "#f4f5f5",
  natural_03 = "#d4d5d6",
  natural_04 = "#a9abad",
  natural_05 = "#54575b",
  natural_06 = "#292d32",

  Natural_1100 = "#091e4224",
  Natural_1000 = " #172b4d",
  Natural_800 = "#44546f",
  Natural_700 = " #626f86",
  Natural_0 = "#ffffff",
  Natural_400A = "#091e424f",

  Blue_700 = "#0c66e4",
  Blue_800 = "#0055cc",
  // Blue_700= #09326c,
  Blue_600 = "#CCE0FF",

  Red_800 = "#ae2e24",
  Red_900 = "#5d1f1a",

  Orange_600 = "#a54800",
  Orange_800 = "#a54800",
  Orange_900 = "#702e00",

  Yellow_800 = "#7f5f01",
  Yellow_900 = "#533f04",

  Lime_800 = "#4c6b1f",
  Lime_900 = "#37471f",

  Green_800 = "#216e4e",
  Green_900 = "#164b35",

  Purple_800 = "#5e4db2",
  Purple_900 = "#352c63",

  Teal_800 = "#206a83",
  Teal_900 = "#164555",

  Magenta_800 = "#943d73",
  Magenta_900 = "#50253f",

  Danger = "#c9372c",
}
export enum RepoType {
  Auth = "auth",
  Folder = "folder",
  App = "app",
  Diagram = "diagram",
  Admin = "admin",
  Template = "template",
  Tenant = "tenant",
}

export interface FolderType {
  id: string;
  name: string;
  createDate: string;
  projects?: projectType[];
}

export interface projectType {
  id: string;
  name: string;
  description?: string;
  coverImage?: string;
  tenantId: number;
  created: string;
  modifyDate: string;
  contributers?: contributerType[];
  deployments?: deploymentType[];
}
export interface contributerType {
  id: string;
  name: string;
  email: string;
  active: boolean | false;
  permission: number | 1;
  projectId: number;
}
export interface deploymentType {
  id: string;
  tenantId: number;
  name: string;
  createDate: string;
  modifyDate?: string;
  active: boolean | true;
  userId: number;
  repositoryURL: string;
  diagramId: number;
  diagramVersion: number | 0;
  projectId: number;
  techStack?: {
    architecture: number;
    caching: number;
    dataBase: number;
    messaging: number;
    scheduler: number;
  };
  executeDate?: string;
  successDeployed?: boolean | true;
}
export interface projectTemplate {
  id: string;
  name: string;
  description: string;
  dataVariationId: number;
  tenantId: number;
  addedDate: string;
  lastUpdatedDate: string;
  totalViews: number;
  bussinesUrl: string;
  tags: string;
  logo: string;
  clones: number;
  stars: number;
  tables: number;
  folderName: string;
  folderId: number;
  isFavoriate: boolean;
}
export const FolderSortOptions = [
  { value: "1", title: "Alphabetically" },
  { value: "2", title: "Recent" },
];
export const TemplateSortOptions = [
  { value: "1", title: "Recently updated" },
  { value: "2", title: "Alphabetically" },
  { value: "3", title: "Last created" },
];

export const SortFolders = (sort: Option, list: FolderType[]) => {
  const tempList = [...list];
  switch (sort.title) {
    case "Recent":
      tempList.sort(function (a: FolderType, b: FolderType) {
        return b.createDate
          .toLowerCase()
          .localeCompare(a.createDate.toLowerCase());
      });
      break;
    case "Alphabetically":
      tempList.sort(function (a: FolderType, b: FolderType) {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
      break;
    default:
      break;
  }
  return tempList;
};
export const SortApp = (sort: Option, list: projectType[]) => {
  if (list) {
    const tempList = [] as projectType[];
    list.forEach((element) => {
      tempList.push({ ...element });
    });

    switch (sort.title) {
      case "Recent":
        tempList.sort(function (a: projectType, b: projectType) {
          if (!a.modifyDate) {
            a.modifyDate = a.created;
          }
          if (!b.modifyDate) {
            b.modifyDate = b.created;
          }
          return b.modifyDate
            .toLowerCase()
            .localeCompare(a.modifyDate.toLowerCase());
        });
        break;
      case "Alphabetically":
        tempList.sort(function (a: projectType, b: projectType) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
        break;
      case "Last created":
        tempList.sort(function (a: projectType, b: projectType) {
          return b.created.toLowerCase().localeCompare(a.created.toLowerCase());
        });
        break;
      default:
        break;
    }
    return tempList;
  }
};
export const SortSelectedFolderApp = (sort: Option, list: projectType[]) => {
  if (list) {
    const tempList = [] as projectType[];
    list.forEach((element) => {
      tempList.push({ ...element });
    });

    switch (sort.title) {
      case "Recent":
        tempList.sort(function (a: projectType, b: projectType) {
          if (!a.modifyDate) {
            a.modifyDate = a.created;
          }
          if (!b.modifyDate) {
            b.modifyDate = b.created;
          }
          return b.modifyDate
            .toLowerCase()
            .localeCompare(a.modifyDate.toLowerCase());
        });
        break;
      case "Alphabetically":
        tempList.sort(function (a: projectType, b: projectType) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
        break;
      case "Last created":
        tempList.sort(function (a: projectType, b: projectType) {
          return b.created.toLowerCase().localeCompare(a.created.toLowerCase());
        });
        break;
      default:
        break;
    }
    return tempList;
  }
};
export const SortFavoriteApp = (sort: Option, list: any) => {
  if (list) {
    const tempList = [...list];
    switch (sort.title) {
      case "Recent":
        tempList.sort(function (a, b) {
          if (!a.modifyDate) {
            a.modifyDate = a.createDate;
          }
          if (!b.modifyDate) {
            b.modifyDate = b.createDate;
          }
          return b.modifyDate
            .toLowerCase()
            .localeCompare(a.modifyDate.toLowerCase());
        });
        break;
      case "Alphabetically":
        tempList.sort(function (a, b) {
          return a.projectName
            .toLowerCase()
            .localeCompare(b.projectName.toLowerCase());
        });
        break;
      case "Last created":
        tempList.sort(function (a, b) {
          return b.createDate
            .toLowerCase()
            .localeCompare(a.createDate.toLowerCase());
        });
        break;
      default:
        break;
    }
    return tempList;
  }
};
export const SortTemplate = (sort: Option, list: any) => {
  if (list) {
    const tempList = [...list];
    switch (sort.title) {
      case "Recently updated":
        tempList.sort(function (a: projectTemplate, b: projectTemplate) {
          return b.lastUpdatedDate
            .toLowerCase()
            .localeCompare(a.lastUpdatedDate.toLowerCase());
        });
        break;
      case "Alphabetically":
        tempList.sort(function (a: projectTemplate, b: projectTemplate) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
        break;
      case "Last created":
        tempList.sort(function (a: projectTemplate, b: projectTemplate) {
          return b.addedDate
            .toLowerCase()
            .localeCompare(a.addedDate.toLowerCase());
        });
        break;
      default:
        break;
    }
    return tempList;
  }
};

export const ApplicationSortOptions = [
  { value: "1", title: "Recent" },
  { value: "2", title: "Alphabetically" },
  { value: "3", title: "Last created" },
];
export const FavoriteApplicationSortOptions = [
  { value: "1", title: "Recent" },
  { value: "2", title: "Alphabetically" },
  { value: "3", title: "Last created" },
];
