import classes from "./style/DeploymentTile.module.scss";
import Tag from "../../../../components/base/Tag";
import BaseButton from "../../../../components/base/BaseButton";
import ShortcutIcon from "@atlaskit/icon/glyph/shortcut";
import BitbucketBuildsIcon from "@atlaskit/icon/glyph/bitbucket/builds";
import EditorSuccessIcon from "@atlaskit/icon/glyph/editor/success";
import { useState } from "react";
import Modal from "../../../../components/base/Modal";
import DesignWindow from "../../../../core/helpers/DesignWindow";
import { IconColor, TagColor } from "../../../../types/sharedTypes";
import { RootState } from "../../../../store";
import UploadIcon from "@atlaskit/icon/glyph/upload";
import { useDispatch, useSelector } from "react-redux";
import { loadingActions } from "../../../../store/loading-slice";
import { errorActions } from "../../../../store/error-slice";
import { RepoFactory } from "../../../../baseRepository/Factory";
import { AxiosError, AxiosResponse } from "axios";
import { RepoType } from "../../../../types/sharedTypes";
const ApplicationRepository = RepoFactory.get(RepoType.App);
interface myProps {
  getProjectById: () => void;
}
interface Deploy {
  id: string;
  diagramVersion: number;
  diagramId: number;
  createDate: string;
  name: string;
  repositoryURL: string;
  techStack: {
    architecture: number;
    caching: number;
    dataBase: number;
    messaging: number;
    scheduler: number;
  };
}
const DeploymentTile = (props: myProps) => {
  const dispatch = useDispatch();
  const selectedAppDetail = useSelector(
    (state: RootState) => state.application.selectedAppDetail
  );
  const [designModalFlag, setDesignModalFlag] = useState(false);
  const [publishModalFlag, setPublishModalFlag] = useState(false);
  const [selectedDeploy, setSelectedDeploy] = useState<Deploy>();
  const [repositoryLink, setRepositoryLink] = useState<string>("");

  const exitModal = (status: boolean) => {
    return setDesignModalFlag(status);
  };
  const publishExitModal = (status: boolean) => {
    return setPublishModalFlag(status);
  };
  const upload = () => {
    return <UploadIcon label="" />;
  };
  const appDeployed = (id: string) => {
    dispatch(loadingActions.setCreateAppHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    let body = {
      deploymentId: id,
    };

    ApplicationRepository.appDeployed(body)
      .then((res: AxiosResponse<any>) => {
        setPublishModalFlag(true);
        setRepositoryLink(res.data.value.deployment.repositoryURL);
        props.getProjectById();
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data.Reasons[0].Message,
            statusCode: 500,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setCreateAppHasLoading(false));
      });
  };

  return (
    <>
      {selectedAppDetail.project.deployments.map((deploy: Deploy, index) => {
        return (
          <div className={classes.tileContainer} key={index}>
            <div className={classes.tileBox}>
              <div className={classes.header}>
                <span className={classes.headerTitle}>{deploy.name}</span>
                {!deploy.repositoryURL?.length && (
                  <span className={classes.headerDraftTitle}>(Drafted)</span>
                )}
              </div>
              <div className={classes.typeBox}>
                {deploy.techStack.architecture === 1 ? (
                  <Tag title="Monolithic" color={TagColor.green} />
                ) : deploy.techStack.architecture === 2 ? (
                  <Tag title="Microservices" color={TagColor.light_blue} />
                ) : (
                  ""
                )}
                {deploy.techStack.caching === 2 ? (
                  <Tag title="InMemory" color={TagColor.green} />
                ) : deploy.techStack.caching === 3 ? (
                  <Tag title="Redis" color={TagColor.light_blue} />
                ) : (
                  ""
                )}
                {deploy.techStack.dataBase === 1 ? (
                  <Tag title="Mongo" color={TagColor.blue} />
                ) : deploy.techStack.dataBase === 2 ? (
                  <Tag title="Postgres" color={TagColor.light_blue} />
                ) : deploy.techStack.dataBase === 3 ? (
                  <Tag title="SqlServer" color={TagColor.grey} />
                ) : (
                  ""
                )}
                {deploy.techStack.messaging === 2 ? (
                  <Tag title="Rabbitmq" color={TagColor.pink} />
                ) : deploy.techStack.messaging === 3 ? (
                  <Tag title="Kafka" color={TagColor.pink} />
                ) : (
                  ""
                )}
                {deploy.techStack.scheduler === 2 ? (
                  <Tag title="Hangfire" color={TagColor.light_blue} />
                ) : deploy.techStack.scheduler === 3 ? (
                  <Tag title="Internal" color={TagColor.grey} />
                ) : (
                  ""
                )}
              </div>

              {deploy.repositoryURL?.length ? (
                <a
                  href={deploy.repositoryURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.linkButton}
                >
                  <ShortcutIcon label="" primaryColor="#44546F" />
                  Open repository
                </a>
              ) : (
                <BaseButton
                  hasBlock={true}
                  color="primary"
                  hasLeftIcon={true}
                  icon={upload()}
                  title="Publish"
                  size="sm"
                  onClickHandler={(e) => appDeployed(deploy.id)}
                />
              )}
              <BaseButton
                size="sm"
                title="Design View"
                color="secondary"
                hasLeftIcon={true}
                icon={
                  <BitbucketBuildsIcon
                    label=""
                    primaryColor={IconColor.Natural_800}
                  />
                }
                disable={
                  selectedAppDetail.project.deployments[0].diagramId
                    ? false
                    : true
                }
                hasBlock={true}
                onClickHandler={() => {
                  setSelectedDeploy(deploy);
                  setDesignModalFlag(true);
                }}
              />
              <div className={classes.dateBox}>
                <span className={classes.dateText}>
                  Modified : {deploy.createDate.split("T")[0]}
                </span>
              </div>
            </div>
          </div>
        );
      })}
      {designModalFlag && (
        <Modal
          body={
            <DesignWindow
              designId={String(selectedDeploy?.diagramId)}
              onClose={exitModal}
              diagramVersion={String(selectedDeploy?.diagramVersion)}
            />
          }
          exitAction={exitModal}
        />
      )}
      {publishModalFlag && (
        <Modal
          body={
            <div className={classes.showMassage}>
              <div className={classes.header}>Create application </div>
              <div className={classes.massage}>
                <div className={classes.icon}>
                  <EditorSuccessIcon label="" />
                </div>
                <span className={classes.yourMassage}>
                  Your app created successfully.
                </span>
              </div>
              <div className={classes.footer}>
                <BaseButton
                  size="sm"
                  title="Cancel"
                  color="secondary"
                  onClickHandler={(e) => {
                    setPublishModalFlag(false);
                  }}
                />
                <a
                  href={repositoryLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.modallinkButton}
                >
                  <BaseButton
                    size="sm"
                    title="Go to Repository"
                    color="primary"
                  />
                </a>
              </div>
            </div>
          }
        />
      )}
    </>
  );
};
export default DeploymentTile;
