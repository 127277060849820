import classes from "./style/CreateAppLoading.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
const CreateAppLoading = () => {
  const creatAppHasLoading = useSelector(
    (state: RootState) => state.loading.creatAppHasLoading
  );
  return (
    <>
      {creatAppHasLoading && (
        <div className={classes.loaderBox}>
          <div className={classes.loaderFrame}>
            <div className={classes.header}>
              <span className={classes.headerTitle}>
                Your app is creating...
              </span>
            </div>
            <div className={classes.loaderBody}>
              <div className={classes.loader}></div>
              <span className={classes.loadingText}>please wait...</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default CreateAppLoading;
