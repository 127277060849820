import classes from "./style/TemplateTile.module.scss";
import { useEffect, useRef, useState } from "react";
import MoreIcon from "@atlaskit/icon/glyph/more";
import { projectTemplate, TagColor } from "../../../types/sharedTypes";
import TemplateOptions from "./TemplateOptions";
import TemplateDuplicateForm from "./TemplateDuplicateForm";
import ChildIssuesIcon from "@atlaskit/icon/glyph/child-issues";
import Modal from "../../../components/base/Modal";
import DesignWindow from "../../../core/helpers/DesignWindow";

interface MyComponentProps {
  template: projectTemplate;
}
const TemplateTile = (props: MyComponentProps) => {
  const tileRef = useRef<HTMLDivElement>(null);
  const [optionFlag, setOptionFlag] = useState<boolean>(false);
  const [designModalFlag, setDesignModalFlag] = useState(false);
  const exitModal = (status: boolean) => {
    return setDesignModalFlag(status);
  };
  const optionFlagHandler = () => {
    setOptionFlag(!optionFlag);
  };

  const designViewHandler = (status: boolean) => {
    setOptionFlag(false);
    setDesignModalFlag(status);
  };
  const handleClickOutside = (event: MouseEvent) => {
    const { target } = event;
    if (tileRef.current && !tileRef.current.contains(target as HTMLElement)) {
      setOptionFlag(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [tileRef]);
  return (
    <div className={classes.tileContainer} ref={tileRef}>
      <div className={classes.tileImage}>
        <div
          className={classes.tileOptionIcon}
          onClick={(e) => optionFlagHandler()}
        >
          <MoreIcon label="" primaryColor={TagColor.blue} />
        </div>
        <ChildIssuesIcon size="large" label="" />
      </div>
      <div className={classes.tileContent}>
        <div className={classes.contentHeader}>
          <span className={classes.appName} onClick={(e) => {}}>
            {props.template.name}
          </span>
        </div>
      </div>
      {optionFlag && (
        <div className={classes.tileOptions}>
          <TemplateOptions
            template={props.template}
            onDesignView={designViewHandler}
          />
        </div>
      )}
      {designModalFlag && (
        <Modal
          body={
            <DesignWindow designId={props.template.id} onClose={exitModal} />
          }
          exitAction={exitModal}
        />
      )}
    </div>
  );
};
export default TemplateTile;
