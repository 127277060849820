import classes from "./style/DashboardLayout.module.scss";
import { ReactNode } from "react";
import Header from "../core/Header";
import Menu from "../core/Menu";
import ShortcutIcon from "@atlaskit/icon/glyph/shortcut";

interface LayoutProps {
  children: ReactNode;
}
const DashboardLayout = ({ children }: LayoutProps) => {
  return (
    <div className={classes.layout}>
      <div className={classes.header}>
        <Header />
      </div>

      <div className={classes.dashboardBody}>
        <div className={classes.sideBar}>
          <Menu />
          <div className={classes.sideBarFooter}>
            <div className={classes.docButton}>
              <ShortcutIcon label="" primaryColor={"#626F86"} />
              <span className={classes.buttonTitle}>Documents</span>
            </div>
            <div className={classes.footerNavigation}>
              <div className={classes.linkBox}>
                <span className={classes.footerTitle}>Terms of use</span>
                <span>.</span>
                <span className={classes.footerTitle}>About us</span>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
};
export default DashboardLayout;
