import classes from "./style/ConfigurationImage.module.scss";
import ConfigImage from "../../../../assets/images/configurationImage.svg";
import { RootState } from "../../../../store";
import { useSelector } from "react-redux";
import PageIcon from "@atlaskit/icon/glyph/page";
import ChildIssuesIcon from "@atlaskit/icon/glyph/child-issues";
import RefreshIcon from "@atlaskit/icon/glyph/refresh";
import avatar from "../../../../assets/images/avatar image.svg";
interface MyComponent {
  changeStep: (number: number) => void;
  changeStepItems: (items: any) => void;
  selectedWay: string;
}
const ConfigurationImage = (props: MyComponent) => {
  const selectedDiagram = useSelector(
    (state: RootState) => state.diagram.selectedDiagram
  );
  const selectedApp = useSelector(
    (state: RootState) => state.application.selectedApp
  );
  const changeCharmDb = () => {
    changeStep(1);
    changeStepItemsToLast();
  };
  const changeStep = (number: number) => {
    if (props.changeStep) {
      props.changeStep(number);
    }
  };
  const changeStepItemsToLast = () => {
    props.changeStepItems([
      {
        id: "SelectModel",
        label: "Select model",
        percentageComplete: 0,
        status: "current",
        href: "#",
      },
      {
        id: "appInfo",
        label: "App info",
        percentageComplete: 0,
        status: "unvisited",
        href: "#",
      },
      {
        id: "Configuration",
        label: "Configuration",
        percentageComplete: 0,
        status: "unvisited",
        href: "#",
      },
      {
        id: "Design",
        label: "Design",
        percentageComplete: 0,
        status: "unvisited",
        href: "#",
      },
      {
        id: "Review",
        label: "Review",
        percentageComplete: 0,
        status: "unvisited",
        href: "#",
      },
    ]);
  };
  return (
    <div className={classes.imageBox}>
      <div className={classes.selectedDiagramCard}>
        <div className={classes.header}>
          <PageIcon label="" />
          <span>Your Application</span>
        </div>
        <div className={classes.detail}>
          <div className={classes.icon}>
            <ChildIssuesIcon label="" />
          </div>
          <div className={classes.DataName}>{selectedDiagram.name}</div>
          <div className={classes.changes} onClick={(e) => changeCharmDb()}>
            <RefreshIcon label="" />
            <span>Reset</span>
          </div>
        </div>
        <div className={classes.appDetail}>
          <label className={classes.label}>Name</label>
          <span className={classes.name}>{selectedApp.name}</span>
        </div>
        <div className={classes.appDetail}>
          <label className={classes.label}>Description</label>
          <span className={classes.description}>{selectedApp.description}</span>
        </div>
        {selectedApp.contributers && (
          <div className={classes.appDetail}>
            <label className={classes.label}>Members</label>
            <span className={classes.membersList}>
              {selectedApp.contributers?.map((user, index) => {
                return (
                  <div key={index} className={classes.member}>
                    <div className={classes.userImage}>
                      <img
                        src={avatar}
                        alt="avatar"
                        className={classes.image}
                      />
                    </div>
                    <div className={classes.memberName}>{user.name}</div>
                  </div>
                );
              })}
            </span>
          </div>
        )}
      </div>
      {/* <img
        alt="configImage"
        className={classes.imageContainer}
        src={ConfigImage}
      /> */}
    </div>
  );
};
export default ConfigurationImage;
