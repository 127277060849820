import classes from "./style/DiagramCreationForm.module.scss";
import Input from "../../../components/base/Input";
import Textarea from "../../../components/base/Textarea";
import ToggleSwitch from "../../../components/base/ToggleSwitch";
import BaseButton from "../../../components/base/BaseButton";
import Nucleus from "../../../assets/image/Nucleus.png";
import { useEffect, useState } from "react";
import { diagramActions } from "../store/diagram-slice";
import { errorActions } from "../../../store/error-slice";
import { loadingActions } from "../../../store/loading-slice";
import { useDispatch, useSelector } from "react-redux";
import DataBaseForm from "./DataBaseForm";
import { RootState } from "../../../store";
import { RepoFactory } from "../../../baseRepository/Factory";
import { AxiosError, AxiosResponse } from "axios";
const DiagramSRepository = () => RepoFactory.get("diagram");
const DataVariationsRepository = () => RepoFactory.get("dataVariations");
interface MyComponentProps {
  onSuccess: (status: boolean) => void;
}
const DiagramCreationForm = (props: MyComponentProps) => {
  const [databaseList, setDatabaseList] = useState([]);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const databasetype = useSelector(
    (state: RootState) => state.diagram.selectedDatabase
  );
  const [diagramtype, setDiagramType] = useState<boolean>(false);
  const dispatch = useDispatch();
  const selectedWorkSpaceId = useSelector(
    (state: RootState) => state.auth.selectedWorkSpaceId
  );

  const setNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const setDescriptionHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };

  const setDiagramTypeHandler = (selectedType: boolean) => {
    setDiagramType(selectedType);
  };

  const addDiagram = async () => {
    if (name && databasetype && description) {
      dispatch(loadingActions.setHasLoading(true));
      dispatch(errorActions.setHasError(false));
      dispatch(errorActions.setError(""));
      let body = {
        name: name,
        description: description,
        isPrivate: diagramtype,
        dataVariationId: databasetype && databasetype.id,
        tenantId: selectedWorkSpaceId,
        businessURl: null,
        tags: null,
        logo: null,
      };
      await DiagramSRepository()
        .createDiagram(body)
        .then((res: AxiosResponse) => {
          dispatch(diagramActions.setSelectedDiagram(res.data.value.diagram));
          dispatch(diagramActions.setSelectedDatabse(null));
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: "Diagram Created Successfuly",
              statusCode: 200,
            })
          );
          setTimeout(() => {
            return props.onSuccess(true);
          }, 500);
        })
        .catch((error: AxiosError<any>) => {
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: error.response?.data
                ? error.response.data.Reasons
                : error.message,
              statusCode: error.response?.status,
            })
          );
        })
        .finally(() => {
          dispatch(loadingActions.setHasLoading(false));
        });
    } else if (name === "") {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          message: "Please Enter Diagram Name !",
          statusCode: 50,
        })
      );
    } else if (description === "") {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          message: "Please Enter Diagram Description !",
          statusCode: 50,
        })
      );
    } else {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          message: "Please Define Database Type !",
          statusCode: 50,
        })
      );
    }
  };

  const getDataVariations = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await DiagramSRepository()
      .getAllDataVariations()
      .then((res: AxiosResponse) => {
        setDatabaseList(res.data.value.dataVariations);
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  useEffect(() => {
    getDataVariations();
  }, []);
  return (
    <div className={classes.formContainer}>
      <div className={classes.formHeader}>
        <span className={classes.headerTitle}>Create a blank model</span>
        <span className={classes.headerCaption}>
          Free up your time and resources for what matters most - creating a
          better quality data model with CharmDB
        </span>
      </div>
      <div className={classes.formContent}>
        <Input
          name="diagramName"
          placeholder="Name"
          value={name}
          handleChange={(e) => {
            setNameHandler(e);
          }}
        />
        <Textarea
          placeholder="Description"
          name="description"
          value={description}
          maxLength={1000}
          handleChange={(e) => {
            setDescriptionHandler(e);
          }}
        />
        <DataBaseForm databaseList={databaseList} />
        <div className={classes.privateBox}>
          <span>{diagramtype ? "Private" : "Public"}</span>
          <ToggleSwitch
            checked={diagramtype}
            checkAction={(e) => {
              setDiagramTypeHandler(e.target.checked);
            }}
            disabled={false}
          />
        </div>
      </div>
      <div className={classes.formAction}>
        <BaseButton
          size="sm"
          title={"Create"}
          color="primary"
          hasRightIcon={false}
          hasLeftIcon={false}
          onClickHandler={(e) => {
            addDiagram();
          }}
        />
      </div>
    </div>
  );
};
export default DiagramCreationForm;
