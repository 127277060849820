import { Version } from "../../types/type";
import classes from "./style/VersionHistoryCard.module.scss";
import VersionTile from "./VersionTile";
interface MyComponent {
  title: string;
  versions: number;
  description?: string;
  diagramId: number;
  current: boolean | false;
}
const VersionHistoryCard = (props: MyComponent) => {
  return (
    <div className={classes.cardLayout}>
      <VersionTile
        title={props.title}
        versionNumber={props.versions}
        description={props.description}
        diagramId={props.diagramId}
        current={props.current}
      />
    </div>
  );
};
export default VersionHistoryCard;
