import Router from "./router";
import { useSelector } from "react-redux";
import LoadHandling from "./core/helpers/LoadHandling";
import ErrorHandling from "./core/helpers/ErrorHandling";
import { RootState } from "./store";
import CreateAppLoading from "./core/helpers/CreateAppLoading";

function App() {
  const hasLoading = useSelector(
    (state: RootState) => state.loading.hasLoading
  );
  const hasError = useSelector((state: RootState) => state.error.hasError);
  const creatAppHasLoading = useSelector(
    (state: RootState) => state.loading.creatAppHasLoading
  );
  return (
    <>
      {hasError && <ErrorHandling />}
      {hasLoading && <LoadHandling />}
      {creatAppHasLoading && <CreateAppLoading />}
      <Router />
    </>
  );
}
export default App;
