import classes from "./style/FoldersHeader.module.scss";
import BaseButton from "../../../components/base/BaseButton";
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import SelectInput from "../../../components/base/SelectInput";
import Modal from "../../../components/base/Modal";
import FolderCreationForm from "./FolderCreationForm";
import { useState } from "react";
import { Option } from "../../../types/sharedTypes";
interface MyComponentProps {
  defaultSort: Option;
  sortOptions: Option[];
  onSort?: (sort: Option) => void;
}
const FoldersHeader = (props: MyComponentProps) => {
  const [folderModalFlag, setFolderModalFlag] = useState(false);
  const addIcon = () => {
    return <EditorAddIcon label="" />;
  };
  const exitModal = (status: boolean) => {
    setFolderModalFlag(status);
  };

  return (
    <div className={classes.headerLayout}>
      <div className={classes.headerBox}>
        <span className={classes.headerTitle}>All folders</span>
        <div className={classes.buttonBox}>
          <div className={classes.sortSection}>
            <SelectInput
              placeHolder="Sort By ..."
              defaultValue={props.defaultSort}
              options={props.sortOptions}
              handleselectedOption={(e) => {
                if (props.onSort) {
                  return props.onSort(e);
                }
              }}
              hasBlock={true}
            />
          </div>
          <div className={classes.buttonSection}>
            <BaseButton
              size="sm"
              title="Add folder"
              color="primary"
              hasRightIcon={false}
              hasLeftIcon={true}
              icon={addIcon()}
              onClickHandler={() => setFolderModalFlag(true)}
            ></BaseButton>
          </div>
        </div>
      </div>
      {folderModalFlag && (
        <Modal
          body={<FolderCreationForm exitAction={exitModal} />}
          exitAction={exitModal}
        />
      )}
    </div>
  );
};
export default FoldersHeader;
