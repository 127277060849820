import classes from "./style/AppsMenu.module.scss";
import AdminAvatar from "../../assets/icons/Monkey.png";
import Tool from "../../assets/icons/tool.png";
import { useNavigate } from "react-router-dom";
const AppsMenu = () => {
  const navigate = useNavigate();
  const openCreateAppPage = () => {
    navigate("/app/new");
  };
  return (
    <div className={classes.menuBox}>
      <div className={classes.menuBody}>
        <div className={classes.header}>
          <span className={classes.headerTitle}>Your Services</span>
        </div>

        <a
          href={process.env.REACT_APP_ADMIN_BASE_DOMAIN}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.menuItem}
        >
          <img
            src={AdminAvatar}
            alt="adminPanel Avatar"
            className={classes.avatar}
          />
          <span className={classes.menuItemTitle}>Admin Panel</span>
        </a>
        <a
          href={process.env.REACT_APP_CHARMDB_BASE_DOMAIN}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.menuItem}
        >
          <img src={Tool} alt="adminPanel Avatar" className={classes.avatar} />
          <span className={classes.menuItemTitle}>CharmDB</span>
        </a>
      </div>
      <div className={classes.menuFooter}>
        <div
          className={classes.footerContainer}
          onClick={(e) => openCreateAppPage()}
        >
          <span className={classes.footerTitle}>Create application</span>
        </div>
      </div>
    </div>
  );
};
export default AppsMenu;
