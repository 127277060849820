import classes from "./style/FolderList.module.scss";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import FolderIcon from "@atlaskit/icon/glyph/folder";
import Modal from "../../../components/base/Modal";
import FolderCreationForm from "./FolderCreationForm";
import { loadingActions } from "../../../store/loading-slice";
import { errorActions } from "../../../store/error-slice";
import { RepoFactory } from "../../../baseRepository/Factory";
import { Colors, IconColor, RepoType } from "../../../types/sharedTypes";
import { AxiosError, AxiosResponse } from "axios";
import { FolderType } from "../../../types/sharedTypes";
import { folderActions } from "../store/folder-slice";
const FolderRepository = RepoFactory.get(RepoType.Folder);

const FolderList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalFlag, setModalFlag] = useState(false);
  const selectedWorkSpaceId = useSelector(
    (state: RootState) => state.auth.selectedWorkSpaceId
  );
  const selectedFolderMenu = useSelector(
    (state: RootState) => state.folder.selectedFolderMenu
  );

  const folderList = useSelector((state: RootState) => state.folder.folderList);
  const exitModal = (status: boolean) => {
    setModalFlag(status);
  };

  const getAllFolders = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await FolderRepository.getAllFolders(selectedWorkSpaceId)
      .then((res: AxiosResponse<any>) => {
        dispatch(folderActions.setFolderList(res.data.value.folders));
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  useEffect(() => {
    if (selectedWorkSpaceId) {
      getAllFolders();
    }
  }, [selectedWorkSpaceId]);

  return (
    <div className={classes.folderListContainer}>
      <div className={classes.listHeader}>
        <span className={classes.headerTitle}>folders</span>
      </div>
      {folderList.length > 0 &&
        folderList?.map((folder: FolderType, index) => {
          return (
            index < 3 && (
              <div
                className={
                  selectedFolderMenu === folder.name
                    ? classes.selectedListItem
                    : classes.listItem
                }
                key={index}
                onClick={(e) => {
                  navigate(`/folders/${folder.id}`);
                }}
              >
                {selectedFolderMenu === folder.name && (
                  <div className={classes.indicator}></div>
                )}
                <FolderIcon
                  label=""
                  primaryColor={
                    selectedFolderMenu === folder.name
                      ? IconColor.blue
                      : IconColor.dark_grey
                  }
                />
                <span
                  style={{
                    color:
                      selectedFolderMenu === folder.name ? Colors.Blue_800 : "",
                  }}
                  className={classes.itemTitle}
                >
                  {folder.name}
                </span>
              </div>
            )
          );
        })}

      <div
        className={
          selectedFolderMenu === "All Folders"
            ? classes.selectedListItem
            : classes.listItem
        }
        onClick={(e) => {
          navigate("/folders");
        }}
      >
        {selectedFolderMenu === "All Folders" && (
          <div className={classes.indicator}></div>
        )}
        <span
          style={{
            color: selectedFolderMenu === "All Folders" ? Colors.Blue_800 : "",
          }}
          className={classes.buttonTitle}
        >
          All Folders
        </span>
      </div>
      <div className={classes.listItem} onClick={() => setModalFlag(true)}>
        <EditorAddIcon label="" primaryColor="#626F86" />
        <span className={classes.buttonTitle}>Add folder</span>
      </div>
      {modalFlag && (
        <Modal
          body={<FolderCreationForm exitAction={exitModal} />}
          exitAction={exitModal}
        />
      )}
    </div>
  );
};
export default FolderList;
