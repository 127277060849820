import classes from "./style/ApplicationOptions.module.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ApplicationAction } from "../store/application-slice";
import { menuAction } from "../../../store/menu-slice";
interface myProps {
  onMove: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  appId: string;
  app: any;
  appDuplicated: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}
const ApplicationOptions = (props: myProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const openVersionHistoryPage = () => {
    navigate(`/app/version-history/${props.appId}`);
  };
  return (
    <div className={classes.optionBox}>
      <div
        className={classes.options}
        onClick={(e) => {
          dispatch(ApplicationAction.setSelectedApp(props.app));
          dispatch(menuAction.setSelectedMenu("Deployment"));
          navigate(`/app/deployment/${props.app.id}`);
        }}
      >
        <div className={classes.optionItem}>
          <span className={classes.optionTitle}>Open</span>
        </div>
      </div>
      <div className={classes.options} onClick={(e) => props.appDuplicated(e)}>
        <div className={classes.optionItem}>
          <span className={classes.optionTitle}>Duplicate</span>
        </div>
      </div>
      <div
        className={classes.options}
        onClick={(e) => openVersionHistoryPage()}
      >
        <div className={classes.optionItem}>
          <span className={classes.optionTitle}>View version history</span>
        </div>
      </div>
      <div className={classes.options} onClick={props.onMove}>
        <div className={classes.optionItem}>
          <span className={classes.optionTitle}>Move to folder</span>
        </div>
      </div>
    </div>
  );
};
export default ApplicationOptions;
