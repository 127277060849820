import classes from "./style/Deployment.module.scss";
import AppSettingLayout from "../../../../components/layouts/AppSettingLayout";
import Members from "../../components/setting/Members";
import HeaderLayout from "../../components/setting/HeaderLayout";
import BaseButton from "../../../../components/base/BaseButton";
import Modal from "../../../../components/base/Modal";
import InviteMembersForm from "../../components/setting/InviteMembersForm";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { menuAction } from "../../../../store/menu-slice";
import { loadingActions } from "../../../../store/loading-slice";
import { errorActions } from "../../../../store/error-slice";
import { AxiosError, AxiosResponse } from "axios";
import { ApplicationAction } from "../../store/application-slice";
import { RepoFactory } from "../../../../baseRepository/Factory";
import { RepoType } from "../../../../types/sharedTypes";
import { useParams } from "react-router-dom";
const ApplicationRepository = RepoFactory.get(RepoType.App);

const Deployment = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams();

  const [modalFlag, setModalFlag] = useState<boolean>(false);
  const modalExitHandler = (status: boolean) => {
    setModalFlag(status);
  };
  const getProjectById = () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));

    ApplicationRepository.getProjectById(projectId)
      .then((res: AxiosResponse<any>) => {
        dispatch(ApplicationAction.setSelectedAppDetail(res.data.value));
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  useEffect(() => {
    dispatch(menuAction.setSelectedMenu("Members"));
    getProjectById();
  }, []);
  return (
    <AppSettingLayout>
      <div className={classes.deployLayout}>
        <HeaderLayout
          type="Members"
          children={
            <BaseButton
              size="sm"
              title="Invite members"
              color="primary"
              onClickHandler={() => {
                setModalFlag(true);
              }}
            />
          }
        />
        <Members />
        {modalFlag && (
          <Modal
            body={<InviteMembersForm onClose={modalExitHandler} />}
            exitAction={modalExitHandler}
          />
        )}
      </div>
    </AppSettingLayout>
  );
};
export default Deployment;
