import classes from "./style/ReviewImage.module.scss";
import Review from "../../../../assets/images/ReviewImage.svg";
const ReviewImage = () => {
  return (
    <div className={classes.image}>
      <img src={Review} alt="review" />
    </div>
  );
};
export default ReviewImage;
