import classes from "./style/Templates.module.scss";
import DashboardLayout from "../../../components/layouts/DashboardLayout";
import TemplatePanel from "../components/TemplatePanel";
import TemplateHeader from "../components/TemplateHeader";
import {
  Option,
  SortTemplate,
  TemplateSortOptions,
} from "../../../types/sharedTypes";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { templateAction } from "../store/template-slice";
import { RootState } from "../../../store";
const Templates = () => {
  const dispatch = useDispatch();
  const sortType = useSelector((state: RootState) => state.template.sortType);
  const templateList = useSelector(
    (state: RootState) => state.template.templateList
  );
  const sortHandler = (status: Option) => {
    dispatch(templateAction.setSortType(status));
  };
  useEffect(() => {
    dispatch(
      templateAction.setTemplateList(SortTemplate(sortType, templateList))
    );
  }, [sortType]);

  return (
    <DashboardLayout>
      <div className={classes.appLayout}>
        <TemplateHeader
          sortOptions={TemplateSortOptions}
          defaultSort={sortType}
          onSort={sortHandler}
        />
        <TemplatePanel />
      </div>
    </DashboardLayout>
  );
};
export default Templates;
