// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeploymentPanel_panelLayout__ufqCY {
  display: flex;
  width: 100%;
  align-items: flex-start;
  align-content: flex-start;
  gap: 24px;
  flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/modules/application/components/setting/style/DeploymentPanel.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,uBAAA;EACA,yBAAA;EACA,SAAA;EACA,eAAA;AACF","sourcesContent":[".panelLayout {\n  display: flex;\n  width: 100%;\n  align-items: flex-start;\n  align-content: flex-start;\n  gap: 24px;\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panelLayout": `DeploymentPanel_panelLayout__ufqCY`
};
export default ___CSS_LOADER_EXPORT___;
