import classes from "./style/TemplateHeader.module.scss";
import BaseButton from "../../../components/base/BaseButton";
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import SelectInput from "../../../components/base/SelectInput";
import { useNavigate } from "react-router-dom";
import { Option } from "../../../types/sharedTypes";
interface MyComponentProps {
  defaultSort: Option;
  sortOptions: Option[];
  onSort?: (sort: Option) => void;
}
const TemplateHeader = (props: MyComponentProps) => {
  return (
    <div className={classes.headerLayout}>
      <div className={classes.headerBox}>
        <span className={classes.headerTitle}>Templates</span>
        <div className={classes.buttonBox}>
          <div className={classes.sortSection}>
            <SelectInput
              placeHolder="Sort By ..."
              defaultValue={props.defaultSort}
              options={props.sortOptions}
              handleselectedOption={(e) => {
                if (props.onSort) {
                  return props.onSort(e);
                }
              }}
              hasBlock={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default TemplateHeader;
