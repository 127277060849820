import { useEffect, useState } from "react";
import classes from "./style/Menu.module.scss";
import Nucleus from "../../assets/icons/Nucleus.png";
import MediaServicesGridIcon from "@atlaskit/icon/glyph/media-services/grid";
import StarIcon from "@atlaskit/icon/glyph/star";
import PremiumIcon from "@atlaskit/icon/glyph/premium";
import BookIcon from "@atlaskit/icon/glyph/book";
import ShortcutIcon from "@atlaskit/icon/glyph/shortcut";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import MenuItem from "./MenuItem";
import { JSX } from "react/jsx-runtime";
import FolderList from "../../modules/Folder/components/FolderList";
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import PersonCircleIcon from "@atlaskit/icon/glyph/person-circle";
import Modal from "../../components/base/Modal";
import { errorActions } from "../../store/error-slice";
import { loadingActions } from "../../store/loading-slice";
import { RepoFactory } from "../../baseRepository/Factory";
import { RepoType } from "../../types/sharedTypes";
import { AxiosError, AxiosResponse } from "axios";
const TenantRepository = RepoFactory.get(RepoType.Tenant);
interface MenuInt {
  title: string;
  link: string;
  rightIcon?: JSX.Element;
}

const Menu = () => {
  const [selectedLicensedWorkspace, setSelectedLicensedWorkspace] =
    useState<string>("");
  const dispatch = useDispatch();

  const selectedMenu = useSelector(
    (state: RootState) => state.menu.selectedMenu
  );
  const selectedWorkSpaceId = useSelector(
    (state: RootState) => state.auth.selectedWorkSpaceId
  );
  const [repositoryLink, setRepositoryLink] = useState<string>("");
  const getRepositoryLink = async () => {
    let repolink = "";
    if (localStorage.getItem("selectedWorkspaceId")) {
      dispatch(loadingActions.setHasLoading(true));
      dispatch(errorActions.setHasError(false));
      dispatch(errorActions.setError(""));

      await TenantRepository.getRepositoryLink(
        localStorage.getItem("selectedWorkspaceId")
      )
        .then((res: AxiosResponse<any>) => {
          setRepositoryLink(res.data.value.tenantDto.repositoryPath);
        })
        .catch((error: AxiosError<any>) => {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        })
        .finally(() => {
          dispatch(loadingActions.setHasLoading(false));
        });
    }
  };

  const menuIconHandler = (title: string) => {
    switch (title) {
      case "My Applications":
        return (
          <MediaServicesGridIcon
            label=""
            primaryColor={
              selectedMenu === "My Applications" ? "#0C66E4" : "#626F86"
            }
          />
        );
      case "Favorites":
        return (
          <StarIcon
            label=""
            primaryColor={selectedMenu === "Favorites" ? "#0C66E4" : "#626F86"}
          />
        );
      case "Templates":
        return (
          <PremiumIcon
            label=""
            primaryColor={selectedMenu === "Templates" ? "#0C66E4" : "#626F86"}
          />
        );
      case "My Repository":
        return (
          <BookIcon
            label=""
            primaryColor={
              selectedMenu === "My Repository" ? "#0C66E4" : "#626F86"
            }
          />
        );
      case "Account Data":
        return (
          <PersonCircleIcon
            label=""
            primaryColor={
              selectedMenu === "Account Data" ? "#0C66E4" : "#626F86"
            }
          />
        );
    }
  };

  const [menu, setMenu] = useState<MenuInt[]>([
    {
      title: "My Applications",
      link: "/app",
    },
    {
      title: "Favorites",
      link: "/app/fav",
    },
    {
      title: "Templates",
      link: "/templates",
    },
    {
      title: "Account Data",
      link: "/account",
    },
    {
      title: "My Repository",
      rightIcon: <ShortcutIcon label="" primaryColor={"#626F86"} />,
      link: repositoryLink,
    },
  ]);
  useEffect(() => {
    let workspace = localStorage.getItem("selectedLicensedWorkspace");
    if (workspace) setSelectedLicensedWorkspace(JSON.parse(workspace)?.name);
  }, [localStorage]);
  useEffect(() => {
    getRepositoryLink();
  }, []);
  return (
    <>
      <div className={classes.menuHeader}>
        <div className={classes.headerBox}>
          <img
            className={classes.headerIcon}
            src={Nucleus}
            alt="workspace-avatar"
          />
          <span className={classes.headerTitle}>
            {selectedLicensedWorkspace
              ? selectedLicensedWorkspace
              : "My Workspace"}
          </span>
        </div>
      </div>
      {menu?.map((item, index) => {
        return (
          <MenuItem
            key={index}
            title={item.title}
            leftIcon={menuIconHandler(item.title)}
            rightIcon={item?.rightIcon}
            link={item.title === "My Repository" ? repositoryLink : item.link}
          />
        );
      })}
      <FolderList />
    </>
  );
};
export default Menu;
