import { useEffect, useState } from "react";
import classes from "./style/CharmDbProjects.module.scss";
import Illustration from "../../../../assets/icons/Illustration.png";
import CharmDbTile from "./CharmDbTile";
import { AxiosError, AxiosResponse } from "axios";
import { RepoFactory } from "../../../../baseRepository/Factory";
import { useDispatch, useSelector } from "react-redux";
import { RepoType } from "../../../../types/sharedTypes";
import { errorActions } from "../../../../store/error-slice";
import { loadingActions } from "../../../../store/loading-slice";
import { RootState } from "../../../../store";
const DiagramRepository = RepoFactory.get(RepoType.Diagram);
interface myComponentProps {
  changeStep: (number: number) => void;
  changeStepItems: (items: any) => void;
  selectedWay: string;
}

const CharmDbProjects = (props: myComponentProps) => {
  const dispatch = useDispatch();
  const selectedWorkSpaceId = useSelector(
    (state: RootState) => state.auth.selectedWorkSpaceId
  );
  const [diagramList, setDiagramList] = useState([]);

  const getAllDigrams = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await DiagramRepository.getAllDigrams(selectedWorkSpaceId)
      .then((res: AxiosResponse) => {
        setDiagramList(res.data.value.diagrams);
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const getAllTemplates = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await DiagramRepository.getAllTemplates()
      .then((res: AxiosResponse) => {
        setDiagramList(res.data.value.tempaltes);
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  useEffect(() => {
    if (props.selectedWay === "selectFromCharmdb") {
      getAllDigrams();
    } else if (props.selectedWay === "selectFromTemplate") {
      getAllTemplates();
    }
  }, [props.selectedWay]);
  return (
    <div className={classes.projectLayout}>
      <span className={classes.listTitle}>Select your data model to start</span>
      {diagramList.length ? (
        <>
          <span className={classes.description}>
            Please choose the data model from the list below.
          </span>
          <div className={classes.tileBox}>
            {diagramList?.map((project, index) => {
              return (
                <CharmDbTile
                  changeStep={(e) => props.changeStep(e)}
                  changeStepItems={(e) => props.changeStepItems(e)}
                  key={index}
                  diagram={project}
                />
              );
            })}
          </div>
        </>
      ) : (
        <div className={classes.emptyBox}>
          <img
            alt="projectIconIcon"
            src={Illustration}
            className={classes.icon}
          />
          <span className={classes.caption}>
            There is no applications listed here.
          </span>
        </div>
      )}
    </div>
  );
};
export default CharmDbProjects;
