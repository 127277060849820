import classes from "./style/FolderOptions.module.scss";
interface MyComponent {
  onEdit: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onDelete: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}
const FolderOptions = (props: MyComponent) => {
  return (
    <div className={classes.optionBox}>
      <div className={classes.optionItems}>
        <div className={classes.item} onClick={props.onEdit}>
          <span className={classes.itemTitle}>Edit</span>
        </div>
        <div className={classes.item} onClick={props.onDelete}>
          <span className={classes.deleteTitle}>Delete</span>
        </div>
      </div>
    </div>
  );
};
export default FolderOptions;
