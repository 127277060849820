import { createSlice } from "@reduxjs/toolkit";
import { TemplateSortOptions } from "../../../types/sharedTypes";
const templateSlice = createSlice({
  name: "template",
  initialState: {
    templateList: [],
    sortType: TemplateSortOptions[0],
  },
  reducers: {
    setTemplateList(state, action) {
      state.templateList = action.payload;
    },
    setSortType(state, action) {
      state.sortType = action.payload;
    },
  },
});
export const templateAction = templateSlice.actions;
export default templateSlice.reducer;
