import classes from "./style/Application.module.scss";
import DashboardLayout from "../../../components/layouts/DashboardLayout";
import EmptyFolder from "../../../core/helpers/EmptyFolder";
import ApplicationHeader from "../components/ApplicationHeader";
import ApplicationPanel from "../components/ApplicationPanel";
import { errorActions } from "../../../store/error-slice";
import { loadingActions } from "../../../store/loading-slice";
import { ApplicationAction } from "../store/application-slice";
import { useDispatch, useSelector } from "react-redux";
import {
  ApplicationSortOptions,
  Option,
  SortApp,
} from "../../../types/sharedTypes";
import { useEffect } from "react";
import { RepoFactory } from "../../../baseRepository/Factory";
import { RepoType } from "../../../types/sharedTypes";
import { RootState } from "../../../store";
import { AxiosError, AxiosResponse } from "axios";
import { menuAction } from "../../../store/menu-slice";
import { folderActions } from "../../Folder/store/folder-slice";
const ApplicationRepository = RepoFactory.get(RepoType.App);
const Application = () => {
  const dispatch = useDispatch();
  const applist = useSelector((state: RootState) => state.application.applist);
  const sortType = useSelector(
    (state: RootState) => state.application.sortType
  );
  const selectedWorkSpaceId = useSelector(
    (state: RootState) => state.auth.selectedWorkSpaceId
  );
  const getAllApp = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await ApplicationRepository.getAllProjects(selectedWorkSpaceId)
      .then((res: AxiosResponse) => {
        dispatch(
          ApplicationAction.setApplist(
            SortApp(sortType, res.data.value.projects)
          )
        );
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const sortHandler = (status: Option) => {
    dispatch(ApplicationAction.setSortType(status));
  };
  useEffect(() => {
    if (selectedWorkSpaceId) {
      getAllApp();
    }
  }, [selectedWorkSpaceId]);
  useEffect(() => {
    dispatch(menuAction.setSelectedMenu("My Applications"));
    dispatch(folderActions.setSelectedFolderMenu(""));
  }, []);

  useEffect(() => {
    dispatch(ApplicationAction.setApplist(SortApp(sortType, applist)));
  }, [sortType, dispatch]);

  return (
    <DashboardLayout>
      <div className={classes.appLayout}>
        <ApplicationHeader
          onSort={sortHandler}
          sortOptions={ApplicationSortOptions}
          defaultSort={sortType}
          page="app"
        />
        {applist?.length === 0 ? (
          <EmptyFolder />
        ) : (
          <ApplicationPanel appList={applist} />
        )}
      </div>
    </DashboardLayout>
  );
};
export default Application;
