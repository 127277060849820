import classes from "./style/VersionHistory.module.scss";
import HeaderLayout from "../../components/setting/HeaderLayout";
import AppSettingLayout from "../../../../components/layouts/AppSettingLayout";
import VersionHistoryCard from "../../components/setting/VersionHistoryCard";
import { useState } from "react";
import { Version } from "../../types/type";
import { useEffect } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { RepoFactory } from "../../../../baseRepository/Factory";
import { useDispatch, useSelector } from "react-redux";
import { RepoType } from "../../../../types/sharedTypes";
import { errorActions } from "../../../../store/error-slice";
import { loadingActions } from "../../../../store/loading-slice";
import { RootState } from "../../../../store";
import { useParams } from "react-router-dom";
import { ApplicationAction } from "../../store/application-slice";
import { diagramActions } from "../../../Diagram/store/diagram-slice";
import { menuAction } from "../../../../store/menu-slice";
const ApplicationRepository = RepoFactory.get(RepoType.App);
const DiagramRepository = RepoFactory.get(RepoType.Diagram);
const VersionHistory = () => {
  const selectedDiagram = useSelector(
    (state: RootState) => state.diagram.selectedDiagram
  );
  const dispatch = useDispatch();
  const { projectId } = useParams();

  const selectedAppDetail = useSelector(
    (state: RootState) => state.application.selectedAppDetail
  );
  const getDigramsVersionHistory = async () => {
    if (selectedAppDetail.project.deployments.length) {
      dispatch(loadingActions.setHasLoading(true));
      dispatch(errorActions.setHasError(false));
      dispatch(errorActions.setError(""));
      await DiagramRepository.getDigramsVersionHistory(
        selectedAppDetail.project.deployments[0].diagramId
      )
        .then((res: AxiosResponse) => {
          dispatch(diagramActions.setSelectedDiagram(res.data.value.diagram));
        })
        .catch((error: AxiosError<any>) => {
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: error.response?.data
                ? error.response.data.Reasons[0].Message
                : error.message,
              statusCode: error.response?.status,
            })
          );
        })
        .finally(() => {
          dispatch(loadingActions.setHasLoading(false));
        });
    }
  };
  const getProjectById = () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));

    ApplicationRepository.getProjectById(projectId)
      .then((res: AxiosResponse<any>) => {
        dispatch(ApplicationAction.setSelectedAppDetail(res.data.value));
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  useEffect(() => {
    getProjectById();
  }, []);
  useEffect(() => {
    if (
      selectedAppDetail.project.deployments.length &&
      selectedAppDetail.project.deployments[0].diagramId > 1
    ) {
      getDigramsVersionHistory();
    }
  }, [selectedAppDetail]);
  useEffect(() => {
    dispatch(menuAction.setSelectedMenu("Model history"));
  }, []);
  return (
    <AppSettingLayout>
      <div className={classes.historyLayout}>
        <HeaderLayout type="Model history" />
      </div>
      {selectedDiagram.models &&
      selectedDiagram.models.length > 0 &&
      selectedAppDetail.project.deployments.length
        ? selectedDiagram.models.map((history, index) => {
            return (
              <VersionHistoryCard
                title={history.data.name}
                versions={history.version}
                diagramId={
                  selectedAppDetail.project.deployments.length
                    ? selectedAppDetail.project.deployments[0].diagramId
                    : 0
                }
                current={
                  history.version === selectedDiagram.diagramCurrentVersion
                    ? true
                    : false
                }
                description={history.description}
              />
            );
          })
        : "History is empty!"}
    </AppSettingLayout>
  );
};
export default VersionHistory;
