import { Route, Routes } from "react-router-dom";
import Folders from "../pages/Folders";
import Folder from "../pages/Folder";
const FolderRouter = () => {
  return (
    <Routes>
      <Route path="/*" element={<Folders />} />
      <Route path="/:folderId" exact element={<Folder />} />
    </Routes>
  );
};
export default FolderRouter;
