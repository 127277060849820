import { useEffect, useState } from "react";
import BaseButton from "../../components/base/BaseButton";
import { IconColor, RepoType } from "../../types/sharedTypes";
import classes from "./style/DesignWindow.module.scss";
import EditIcon from "@atlaskit/icon/glyph/edit";
import { useDispatch } from "react-redux";
import { loadingActions } from "../../store/loading-slice";
import { errorActions } from "../../store/error-slice";
import { RepoFactory } from "../../baseRepository/Factory";
import { AxiosError, AxiosResponse } from "axios";
const DiagramRepository = RepoFactory.get(RepoType.Diagram);

interface MyComponentProps {
  designId: string;
  onClose: (status: boolean) => void;
  diagramVersion?: string;
}
const DesignWindow = (props: MyComponentProps) => {
  const dispatch = useDispatch();
  const [diagramName, setDiagramName] = useState<string>("");
  const closeHandler = () => {
    if (props.onClose) return props.onClose(false);
  };
  const getDiagram = async () => {
    if (props.designId) {
      dispatch(loadingActions.setHasLoading(true));
      dispatch(errorActions.setHasError(false));
      dispatch(errorActions.setError(""));
      await DiagramRepository.getDiagram(props.designId)
        .then((res: AxiosResponse<any>) => {
          setDiagramName(res.data.value.diagram.name);
        })
        .catch((error: AxiosError<any>) => {
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: error.response?.data.Reasons[0].Message,
              statusCode: 500,
            })
          );
        })
        .finally(() => {
          dispatch(loadingActions.setHasLoading(false));
        });
    }
  };
  useEffect(() => {
    getDiagram();
  }, []);
  console.log(props);

  return (
    <div className={classes.designLayout}>
      <div className={classes.header}>
        <span className={classes.headerTitle}>
          {diagramName} - V {props.diagramVersion}
        </span>
      </div>
      <div className={classes.window}>
        <iframe
          title="charm"
          className={classes.charmFrame}
          src={`${process.env.REACT_APP_CHARMDB_BASE_DOMAIN}/diagrams/share/${props.designId}/${props.diagramVersion}`}
        />
      </div>
      <div className={classes.actionBox}>
        <BaseButton
          title="Cancel"
          size="sm"
          color="secondary"
          onClickHandler={(e) => closeHandler()}
        />
        <a
          onClick={(e) => closeHandler()}
          href={`${process.env.REACT_APP_CHARMDB_BASE_DOMAIN}/diagrams/${props.designId}/${props.diagramVersion}`}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.linkButton}
        >
          <EditIcon label="" primaryColor={IconColor.white} />
          Edit
        </a>
      </div>
    </div>
  );
};
export default DesignWindow;
