import classes from "./style/Notification.module.scss";
import CheckIcon from "@atlaskit/icon/glyph/check";
import notif from "../../assets/images/notif.svg";
const Notification = () => {
  return (
    <div className={classes.notifBox}>
      <div className={classes.header}>
        <h2>Notifications</h2>
        <div className={classes.checked}>
          <CheckIcon label="" />
          <span>Mark as read</span>
        </div>
      </div>
      <div className={classes.content}>
        <img src={notif} alt="notif " />
        <span className={classes.massage}>
          There is no notifications listed here.
        </span>
      </div>
    </div>
  );
};
export default Notification;
