import classes from "./style/InviteMembersForm.module.scss";
import BaseButton from "../../../../components/base/BaseButton";
import { useEffect, useState } from "react";
import SelectInput from "../../../../components/base/SelectInput";
import Input from "../../../../components/base/Input";
import { validate } from "email-validator";
import { AxiosError, AxiosResponse } from "axios";
import { useParams } from "react-router-dom";
import { Option, RepoType } from "../../../../types/sharedTypes";
import { useDispatch, useSelector } from "react-redux";
import { loadingActions } from "../../../../store/loading-slice";
import { errorActions } from "../../../../store/error-slice";
import { RepoFactory } from "../../../../baseRepository/Factory";
import { ApplicationAction } from "../../store/application-slice";
import { RootState } from "../../../../store";
const ApplicationRepository = RepoFactory.get(RepoType.App);
const AdminRepository = RepoFactory.get(RepoType.Admin);
interface MyComponent {
  onClose: (status: boolean) => void;
}
const InviteMembersForm = (props: MyComponent) => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const [members, setMembers] = useState([]);

  const selectedWorkSpaceId = useSelector(
    (state: RootState) => state.auth.selectedWorkSpaceId
  );
  const [selectedMemberOption, setSelectedMemberOption] = useState<Option>();
  const [selectedMember, setSelectedMember] = useState<any>();
  const [selectedPermision, setSelectedPermision] = useState({
    value: "1",
    title: "Admin",
  });
  const closeHandler = () => {
    if (props.onClose) return props.onClose(false);
  };
  const addContributer = () => {
    if (selectedMember) {
      dispatch(loadingActions.setHasLoading(true));
      dispatch(errorActions.setHasError(false));
      dispatch(errorActions.setError(""));

      let body = {
        tenantId: Number(selectedWorkSpaceId),
        projectId: Number(projectId),
        name: selectedMember?.nickName,
        userId: String(selectedMember?.userId),
        email: selectedMember?.userName,
        permission: +selectedPermision.value,
      };

      ApplicationRepository.addContributer(body)
        .then((res: AxiosResponse<any>) => {
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: "added member Successfuly",
              statusCode: 200,
            })
          );
          getAllContributers();
        })
        .catch((error: AxiosError<any>) => {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        })
        .finally(() => {
          dispatch(loadingActions.setHasLoading(false));
          closeHandler();
        });
    } else {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          message: "Please enter name or email",
          statusCode: 400,
        })
      );
    }
  };

  const getAllContributers = () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    ApplicationRepository.getAllContributers(projectId)
      .then((res: AxiosResponse<any>) => {
        dispatch(ApplicationAction.setMembersList(res.data.value.contributers));
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const getSubscription = async () => {
    let subscriptionId = "";
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await AdminRepository.getSubscription(selectedWorkSpaceId)
      .then((res: AxiosResponse<any>) => {
        subscriptionId = res.data.value.subscription.find(
          (subscription: any) => subscription.productName === "Code in One"
        ).id;
        getMembers(subscriptionId);
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data));
      });
  };
  const getMembers = async (subscriptionId: string) => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await AdminRepository.getTeamMembers(subscriptionId)
      .then((res: AxiosResponse<any>) => {
        setMembers(res.data.value.members);
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const memberSelectionHandler = () => {
    setSelectedMember(
      members.find(
        (member: any) => member.userId === selectedMemberOption?.value
      )
    );
  };

  useEffect(() => {
    getSubscription();
  }, []);
  useEffect(() => {
    memberSelectionHandler();
  }, [selectedMemberOption]);
  return (
    <div className={classes.formLayout}>
      <div className={classes.header}>
        <span className={classes.headerTitle}>Invite members</span>
      </div>
      <div className={classes.formBody}>
        <div className={classes.members}>
          <SelectInput
            hasBlock={true}
            placeHolder="Select Member"
            options={
              members &&
              members.map((member: any, index) => {
                return {
                  value: member.userId,
                  title: member.nickName,
                };
              })
            }
            defaultValue={selectedMemberOption}
            handleselectedOption={(e) => {
              setSelectedMemberOption(e);
            }}
          />
        </div>
        <div className={classes.roleBox}>
          <SelectInput
            placeHolder="As ..."
            options={[
              { value: "1", title: "Admin" },
              { value: "2", title: "Contributor" },
            ]}
            defaultValue={selectedPermision}
            handleselectedOption={(e) => {
              setSelectedPermision(e);
            }}
            hasBlock={true}
          />
        </div>
      </div>
      <div className={classes.formFooter}>
        <BaseButton
          size="sm"
          title="Close"
          color="secondary"
          onClickHandler={() => closeHandler()}
        />
        <BaseButton
          size="sm"
          title="Add"
          color="primary"
          onClickHandler={(e) => addContributer()}
        />
      </div>
    </div>
  );
};
export default InviteMembersForm;
