import classes from "./style/FolderDetails.module.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { errorActions } from "../../../store/error-slice";
import { loadingActions } from "../../../store/loading-slice";
import { RepoFactory } from "../../../baseRepository/Factory";
import { folderActions } from "../store/folder-slice";
import { AxiosError, AxiosResponse } from "axios";
import { RootState } from "../../../store";
import ApplicationTile from "../../application/components/ApplicationTile";
import { menuAction } from "../../../store/menu-slice";
import { ApplicationAction } from "../../application/store/application-slice";
import { SortSelectedFolderApp } from "../../../types/sharedTypes";
const FolderRepository = () => RepoFactory.get("folder");

const FolderDetails = () => {
  const { folderId } = useParams();
  const dispatch = useDispatch();
  const selectedFolder = useSelector(
    (state: RootState) => state.folder.selectedFolder
  );
  const selectedFolderApplist = useSelector(
    (state: RootState) => state.application.selectedFolderApplist
  );
  const sortType = useSelector(
    (state: RootState) => state.application.sortType
  );
  const getFolder = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await FolderRepository()
      .getFolderById(folderId)
      .then((res: AxiosResponse) => {
        dispatch(folderActions.setSelectedFolder(res.data.value.folder));

        dispatch(
          ApplicationAction.setSelectedFolderApplist(
            SortSelectedFolderApp(sortType, res.data.value.folder.projects)
          )
        );
        dispatch(
          folderActions.setSelectedFolderMenu(res.data.value.folder.name)
        );
        dispatch(menuAction.setSelectedMenu(""));
      })
      .catch((error: AxiosError) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };

  useEffect(() => {
    if (folderId) {
      getFolder();
    }
  }, [folderId]);

  return (
    <>
      <div className={classes.panelLayout}>
        <div className={classes.tileBox}>
          {selectedFolder?.projects.length > 0 ? (
            <>
              {selectedFolderApplist &&
                selectedFolderApplist.map((item: any, index: number) => {
                  return <ApplicationTile app={item} key={index} />;
                })}
            </>
          ) : (
            <div className={classes.emptyFolder}>
              <span> Folder is empty !</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default FolderDetails;
