import { configureStore } from "@reduxjs/toolkit";
import menuSlice from "./menu-slice";
import folderSlice from "../modules/Folder/store/folder-slice";
import applicationSlice from "../modules/application/store/application-slice";
import authSlice from "./auth-slice";
import loadingSlice from "./loading-slice";
import errorSlice from "./error-slice";
import diagramSlice from "../modules/Diagram/store/diagram-slice";
import templateSlice from "../modules/template/store/template-slice";
const store = configureStore({
  reducer: {
    menu: menuSlice,
    folder: folderSlice,
    application: applicationSlice,
    auth: authSlice,
    loading: loadingSlice,
    error: errorSlice,
    diagram: diagramSlice,
    template: templateSlice,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export default store;
