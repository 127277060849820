import classes from "./style/ErrorHandling.module.scss";
import { useSelector, useDispatch } from "react-redux";
import ErrorIcon from "@atlaskit/icon/glyph/error";
import EditorSuccessIcon from "@atlaskit/icon/glyph/editor/success";
import { errorActions } from "../../store/error-slice";
import { useEffect, useState, useCallback } from "react";
import { authActions } from "../../store/auth-slice";
import { RootState } from "../../store";
import { IconColor } from "../../types/sharedTypes";
const ErrorHandling = () => {
  const dispatch = useDispatch();
  const error = useSelector((state: RootState) => state.error.error);
  const [showNotif, setShowNotif] = useState<boolean>(false);

  useCallback(() => {
    if (!showNotif) {
      closeNotify();
    }
  }, [showNotif]);
  const closeNotify = () => {
    setShowNotif(false);
    dispatch(errorActions.setHasError(false));
    dispatch(
      errorActions.setError({
        message: "",
        statusCode: 0,
      })
    );
  };
  useEffect(() => {
    let timer = setTimeout(() => {
      setShowNotif(false);
      dispatch(errorActions.setHasError(false));
      dispatch(
        errorActions.setError({
          message: "",
          statusCode: 0,
        })
      );
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [showNotif]);
  useEffect(() => {
    if (error?.message) {
      setShowNotif(true);
    }
    if (error?.statusCode === 401) {
      dispatch(authActions.logOut());
    }
  }, [error]);
  return (
    <div className={classes.errorHandling}>
      {error && showNotif && (
        <div
          className={
            error?.statusCode === 200 ? classes.successNotif : classes.errNotif
          }
        >
          <div className={classes.content}>
            {error.statusCode === 200 ? (
              <EditorSuccessIcon label="" primaryColor={IconColor.blue} />
            ) : (
              <ErrorIcon label="" primaryColor={IconColor.danger} />
            )}
            <span
              className={classes.message}
              style={{
                color:
                  error?.statusCode === 200 ? IconColor.blue : IconColor.danger,
              }}
            >
              {error.message}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
export default ErrorHandling;
