/*
 <BaseButton
    size="sm"
    title="Learn More"
    color='secondary'
    hasRightIcon={true}
    hasLeftIcon={false}
    hasBlock={true}
    icon={icon()}
  ></BaseButton>
*/
import React, { useEffect, useState } from "react";
interface MyComponentProps {
  size?: String;
  color?: String;
  hasBlock?: Boolean;
  hasLeftIcon?: Boolean;
  hasRightIcon?: Boolean;
  title: String;
  onClickHandler?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  icon?: JSX.Element | JSX.Element[];
  disable?: Boolean;
}
const BaseButton = (props: MyComponentProps) => {
  const [name, setName] = useState("my-btn ");
  useEffect(() => {
    classNameComputed();
  }, []);
  const classNameComputed = () => {
    let tempName = name;
    if (props.size === "lg") {
      tempName = tempName + " larg-btn ";
    } else if (props.size === "sm") {
      tempName = tempName + " small-btn ";
    } else {
      tempName = tempName + " medium-btn ";
    }

    if (props.color === "secondary") {
      tempName = tempName + " secondary-btn ";
    } else if (props.color === "outline") {
      tempName = tempName + " outline-btn ";
    } else if (props.color === "primary-text") {
      tempName = tempName + " primary-text-btn ";
    } else if (props.color === "white-black") {
      tempName = tempName + " white-black-btn ";
    } else if (props.color === "danger") {
      tempName = tempName + " danger-btn ";
    } else if (props.disable) {
      tempName = tempName + " disable-btn ";
    } else {
      tempName = tempName + " primary-btn ";
    }
    if (props.hasBlock) {
      tempName = tempName + " blockButton ";
    }

    setName(tempName);
  };
  const onClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!props.disable && props.onClickHandler) {
      props.onClickHandler(e);
    }
  };
  return (
    <div className={name} onClick={(e) => onClickHandler(e)}>
      {props.hasLeftIcon && props.icon ? props.icon : ""}
      {props.title}
      {props.hasRightIcon && props.icon ? props.icon : ""}
    </div>
  );
};
export default BaseButton;
