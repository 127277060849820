import { Route, Routes } from "react-router-dom";
import AccountData from "../pages/AccountData";
const AccountRouter = () => {
  return (
    <Routes>
      <Route path="/*" element={<AccountData />} />
    </Routes>
  );
};
export default AccountRouter;
