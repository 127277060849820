import FolderRepository from "../modules/Folder/repository/FolderRepository";
import applicationRepository from "../modules/application/repository/applicationRepository";
import DiagramRepository from "../modules/Diagram/repository/DiagramRepository";
import AdminRepository from "../modules/adminPanel/repository/AdminRepository";
import TemplateRepository from "../modules/template/repository/TemplateRepository";
import TenantRepository from "./TenantRepository";
interface repositoriesTypeMap {
  auth: any;
}
export type repositoriesType = repositoriesTypeMap[keyof repositoriesTypeMap];
const repositories: repositoriesType = {
  folder: FolderRepository,
  app: applicationRepository,
  diagram: DiagramRepository,
  admin: AdminRepository,
  template: TemplateRepository,
  tenant: TenantRepository,
};

export const RepoFactory: repositoriesType = {
  get: (name: string) => repositories[name],
};
