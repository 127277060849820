import { useEffect, useState } from "react";
import classes from "./style/Tag.module.scss";
import { TagTitleColor } from "../../types/sharedTypes";
import { TagColor } from "../../types/sharedTypes";
import { TagBorderColor } from "../../types/sharedTypes";
interface MyComponent {
  size?: string;
  id?: number;
  title: string;
  color?: TagColor | TagColor.white;
  removable?: boolean | false;
  rounded?: boolean | false;
  clickable?: boolean | false;
  onClick?: (tagName: string) => void;
  selected?: boolean | false;
}
const Tag = (props: MyComponent) => {
  const [clicked, setClicked] = useState<boolean>(
    props.selected ? props.selected : false
  );

  const clickHandler = () => {
    if (props.clickable) {
      setClicked(!clicked);
      if (props.onClick) return props.onClick(props.title);
    }
  };
  useEffect(() => {
    if (props.selected !== undefined) {
      setClicked(props.selected);
    }
  }, [props.selected]);
  return (
    <div
      className={props.size === "sm" ? classes.smallLayout : classes.layout}
      style={{
        background: clicked
          ? TagColor.clicked
          : props.color
          ? props.color
          : TagColor.white,
        border:
          !props.color || props.color === TagColor.white
            ? `1px solid ${TagBorderColor[TagColor.white]}`
            : "",
        borderRadius: props.rounded ? "100px" : "",
        cursor: props.clickable ? "pointer" : "",
      }}
      onClick={() => {
        clickHandler();
      }}
    >
      <span
        className={classes.title}
        style={{
          color: clicked
            ? TagTitleColor[TagColor.clicked]
            : props.color
            ? TagTitleColor[props.color]
            : TagTitleColor[TagColor.white],
        }}
      >
        {props.title}
      </span>
    </div>
  );
};
export default Tag;
