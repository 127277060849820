import { useState } from "react";
import WatchIcon from "@atlaskit/icon/glyph/watch";
import classes from "./style/PasswordText.module.scss";
import WatchFilledIcon from "@atlaskit/icon/glyph/watch-filled";
interface MyComponentProps {
  password: string;
}
const PasswordText = (props: MyComponentProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  return (
    <div className={classes.PasswordLayout}>
      {showPassword ? (
        <>
          <div onClick={() => setShowPassword(false)}>
            <WatchFilledIcon label="" />
          </div>
          <span>{props.password}</span>
        </>
      ) : (
        <>
          <div onClick={() => setShowPassword(true)}>
            <WatchIcon label="" />
          </div>

          <span className={classes.passText}>............</span>
        </>
      )}
    </div>
  );
};
export default PasswordText;
