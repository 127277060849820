import classes from "./style/FolderTile.module.scss";
import FolderFilledIcon from "@atlaskit/icon/glyph/folder-filled";
import MoreIcon from "@atlaskit/icon/glyph/more";
import { useState, useEffect, useRef } from "react";
import { folderActions } from "../store/folder-slice";
import { errorActions } from "../../../store/error-slice";
import { loadingActions } from "../../../store/loading-slice";
import { useDispatch, useSelector } from "react-redux";
import FolderOptions from "./FolderOptions";
import FolderCreationForm from "./FolderCreationForm";
import Modal from "../../../components/base/Modal";
import WarningModal from "../../../core/helpers/WarningModal";
import { useNavigate } from "react-router-dom";
import { Colors, FolderType, IconColor } from "../../../types/sharedTypes";
import { RepoFactory } from "../../../baseRepository/Factory";
import { AxiosError, AxiosResponse } from "axios";
import { RootState } from "../../../store";
const FolderRepository = () => RepoFactory.get("folder");
interface MyComponent {
  folder: FolderType;
}
const FolderTile = (props: MyComponent) => {
  const navigate = useNavigate();
  const folderOption = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const [optionFlag, setOptionFlag] = useState(false);
  const [mouseHoverFlag, setMouseHoverFlag] = useState(false);
  const [optionHoverFlag, setOptionHoverFlag] = useState(false);
  const [folderModalFlag, setFolderModalFlag] = useState(false);
  const [deleteModalFlag, setDeleteModalFlag] = useState(false);
  const [folderName, setFolderName] = useState("");
  const selectedWorkSpaceId = useSelector(
    (state: RootState) => state.auth.selectedWorkSpaceId
  );
  const sortType = useSelector((state: RootState) => state.folder.sortType);

  const folderList = useSelector((state: RootState) => state.folder.folderList);

  const exitModal = (status: boolean) => {
    setMouseHoverFlag(false);
    setFolderModalFlag(status);
  };
  const warningModalHandler = (state: boolean) => {
    setDeleteModalFlag(state);
  };

  const deleteFolder = async () => {
    if (props.folder.projects && props.folder?.projects.length > 0) {
      setDeleteModalFlag(false);
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          message: "Selected Folder Contains Diagrams you can't Delete it !",
          statusCode: 0,
        })
      );
      setMouseHoverFlag(false);
      return;
    }
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await FolderRepository()
      .deleteFolder(String(props.folder?.id))
      .then((res: AxiosResponse) => {
        // const result = folderList.filter(
        //   (folder) => folder.id !== props.folder.id
        // );
        // dispatch(folderActions.setFolderList(result));
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: "Folder Deleted Successfuly",
            statusCode: 200,
          })
        );
        getAllFolders();
      })
      .catch((error: AxiosError) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError({}));
      })
      .finally(() => {
        setDeleteModalFlag(false);
        setMouseHoverFlag(false);
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const getAllFolders = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await FolderRepository()
      .getAllFolders(selectedWorkSpaceId)
      .then((res: AxiosResponse) => {
        dispatch(folderActions.setFolderList(res.data.value.folders));
      })
      .catch((error: AxiosError) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError({}));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };

  const handleClickOutside = (event: MouseEvent) => {
    const { target } = event;
    if (
      folderOption.current &&
      !folderOption.current.contains(target as HTMLElement)
    ) {
      setOptionFlag(false);
      setOptionHoverFlag(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [folderOption]);
  useEffect(() => {
    if (!optionHoverFlag) {
      setOptionHoverFlag(false);
    }
  }, [optionHoverFlag]);
  return (
    <div className={classes.tileLayout}>
      <div
        className={classes.iconBox}
        onMouseLeave={() => setMouseHoverFlag(false)}
        onMouseEnter={() => {
          setMouseHoverFlag(true);
        }}
        style={{
          background: mouseHoverFlag ? Colors.Natural_1100 : Colors.Natural_0,
        }}
      >
        {mouseHoverFlag && (
          <div
            className={classes.optionIcon}
            onClick={() => {
              setOptionFlag(!optionFlag);
            }}
          >
            <MoreIcon label="" />
          </div>
        )}
        {optionFlag && (
          <div className={classes.folderOption} ref={folderOption}>
            <FolderOptions
              onDelete={() => {
                setDeleteModalFlag(true);
                setOptionFlag(false);
              }}
              onEdit={() => {
                setFolderModalFlag(true);
                setOptionFlag(false);
              }}
            />
          </div>
        )}
        <div
          className={classes.folderIcon}
          onClick={() => navigate(`/folders/${props.folder.id}`)}
        >
          <FolderFilledIcon
            label=""
            primaryColor={IconColor.grey}
            size="large"
          />
        </div>
      </div>
      <div className={classes.tileFooter}>
        <span className={classes.title}>{props.folder.name}</span>
      </div>
      {folderModalFlag && (
        <Modal
          body={
            <FolderCreationForm exitAction={exitModal} folder={props.folder} />
          }
          exitAction={exitModal}
        />
      )}
      {deleteModalFlag && (
        <WarningModal
          type="Leave"
          header="Warning"
          alert="Are You Sure Delete This Folder?"
          onClose={warningModalHandler}
          onDelete={() => deleteFolder()}
        />
      )}
    </div>
  );
};
export default FolderTile;
