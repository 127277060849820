import { createSlice } from "@reduxjs/toolkit";
const menuSlice = createSlice({
  name: "menu",
  initialState: {
    selectedMenu: "My Applications",
  },
  reducers: {
    setSelectedMenu(state, action) {
      state.selectedMenu = action.payload;
    },
  },
});
export const menuAction = menuSlice.actions;
export default menuSlice.reducer;
