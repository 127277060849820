// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Templates_appLayout__EHShz {
  display: flex;
  width: 100%;
  align-self: stretch;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}`, "",{"version":3,"sources":["webpack://./src/modules/template/pages/style/Templates.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;AACF","sourcesContent":[".appLayout {\n  display: flex;\n  width: 100%;\n  align-self: stretch;\n  padding: 24px;\n  flex-direction: column;\n  align-items: center;\n  gap: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appLayout": `Templates_appLayout__EHShz`
};
export default ___CSS_LOADER_EXPORT___;
