import classes from "./style/MoveToFolder.module.scss";
import SelectInput from "../../../components/base/SelectInput";
import Input from "../../../components/base/Input";
import BaseButton from "../../../components/base/BaseButton";
import AddIcon from "@atlaskit/icon/glyph/add";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationAction } from "../store/application-slice";
import { folderActions } from "../../Folder/store/folder-slice";
import { useEffect, useState } from "react";
import { loadingActions } from "../../../store/loading-slice";
import { errorActions } from "../../../store/error-slice";
import { useParams } from "react-router-dom";
import { SortApp } from "../../../types/sharedTypes";
import { RepoFactory } from "../../../baseRepository/Factory";
import { RootState } from "../../../store";
import { AxiosError, AxiosResponse } from "axios";
import { RepoType } from "../../../types/sharedTypes";
const FolderRepository = RepoFactory.get(RepoType.Folder);
const ApplicationRepository = RepoFactory.get(RepoType.App);

interface myProp {
  exitAction: (status: boolean) => void;
  app: any;
}

const MoveToFolder = (props: myProp) => {
  const { folderId } = useParams();
  const dispatch = useDispatch();
  const folderList = useSelector((state: RootState) => state.folder.folderList);
  const sortType = useSelector(
    (state: RootState) => state.application.sortType
  );
  const selectedWorkSpaceId = useSelector(
    (state: RootState) => state.auth.selectedWorkSpaceId
  );
  const [selectedFolder, setSelectedFolder] = useState({ value: "" });
  const [folderName, setFolderName] = useState("");
  const [creatFolderFlag, setCreateFolderFlag] = useState(false);

  const buttonIcon = () => {
    return <AddIcon label="" />;
  };
  const cancelHandler = () => {
    if (props.exitAction) return props.exitAction(false);
  };
  const getProjectListWithPageParams = () => {
    if (window.location.pathname.includes("folders") && folderId) {
      getProjectByFolderId();
    } else {
      getAllProjects();
    }
  };
  const getProjectByFolderId = () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    FolderRepository.getFolderById(folderId)
      .then((res: AxiosResponse<any>) => {
        dispatch(folderActions.setSelectedFolder(res.data.value.folder));
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const createAndMoveFolder = async () => {
    if (folderName !== "") {
      dispatch(loadingActions.setHasLoading(true));
      dispatch(errorActions.setHasError(false));
      dispatch(errorActions.setError(""));
      let body = {
        tenantId: selectedWorkSpaceId,
        name: folderName,
      };
      await FolderRepository.createFolder(body)
        .then((res: AxiosResponse<any>) => {
          moveToFolderHandler(res.data.value.folder.id);
        })
        .catch((error: AxiosError<any>) => {
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: error.response?.data
                ? error.response.data.Reasons[0].Message
                : error.message,
              statusCode: error.response?.status,
            })
          );
        })
        .finally(() => {
          dispatch(loadingActions.setHasLoading(false));
        });
    }
  };
  const getAllFolders = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await FolderRepository.getAllFolders(selectedWorkSpaceId)
      .then((res: AxiosResponse<any>) => {
        dispatch(folderActions.setFolderList(res.data.value.folders));
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };

  const moveToFolderHandler = async (folderId: string) => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));

    let body = {
      tenantId: selectedWorkSpaceId,
      folderId: folderId,
      projectId: window.location.pathname.includes("fav")
        ? props.app.projectId
        : props.app.id,
    };
    await FolderRepository.assignApp(body)
      .then((res: AxiosResponse<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: "Application Moved To Folder Successfuly",
            statusCode: 200,
          })
        );
        getAllFolders();
        getProjectListWithPageParams();
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data));
      })
      .finally(() => {
        return props.exitAction(false);
      });
  };
  const getAllProjects = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await ApplicationRepository.getAllProjects(selectedWorkSpaceId)
      .then((res: AxiosResponse<any>) => {
        dispatch(
          ApplicationAction.setApplist(
            SortApp(sortType, res.data.value.projects)
          )
        );
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  useEffect(() => {
    getAllFolders();
  }, []);
  return (
    <div className={classes.formContainer}>
      <div className={classes.header}>
        <span className={classes.headerTitle}>Move to Folder</span>
      </div>
      {creatFolderFlag === false && (
        <div className={classes.inputbox}>
          <SelectInput
            placeHolder="Select your Folder"
            options={
              folderList &&
              folderList.map((item: any) => {
                return { value: item.id, title: item.name };
              })
            }
            handleselectedOption={(e) => setSelectedFolder(e)}
          />
          <div className={classes.lineBox}>
            <div className={classes.line}></div>
            <span className={classes.lineText}>Or</span>
            <div className={classes.line}></div>
          </div>
          <BaseButton
            title="Add New Folder"
            hasRightIcon={false}
            hasLeftIcon={true}
            icon={buttonIcon()}
            hasBlock={true}
            onClickHandler={(e) => {
              setCreateFolderFlag(true);
            }}
          />
        </div>
      )}
      {creatFolderFlag === true && (
        <div className={classes.inputbox}>
          <Input
            type="text"
            placeholder="Enter Folders Name"
            name="folderName"
            handleChange={(e) => setFolderName(e.target.value)}
          />
        </div>
      )}

      <div className={classes.formAction}>
        <BaseButton
          size="sm"
          color="outline"
          title="Cancel"
          hasRightIcon={false}
          hasLeftIcon={false}
          onClickHandler={(e) => cancelHandler()}
        />
        <BaseButton
          size="sm"
          title="Submit"
          color="primary"
          hasRightIcon={false}
          hasLeftIcon={false}
          onClickHandler={(e) => {
            creatFolderFlag
              ? createAndMoveFolder()
              : moveToFolderHandler(selectedFolder?.value);
          }}
        />
      </div>
    </div>
  );
};
export default MoveToFolder;
