/**
 * <ToggleSwitch
          checked={true}
          checkAction={(e) => toggleHandler()}
          disabled={false}
        />
 */
import classes from "./style/ToggleSwitch.module.scss";
interface MyComponentProps {
  checked: boolean;
  checkAction: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
}
const ToggleSwitch = (props: MyComponentProps) => {
  return (
    <div className={classes.switchBox}>
      <label className={classes.switch}>
        <input
          type="checkbox"
          defaultChecked={props.checked}
          onChange={props.checkAction}
          disabled={props.disabled}
        />
        <span className={classes.slider}></span>
      </label>
    </div>
  );
};
export default ToggleSwitch;
