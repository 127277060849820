import { useState } from "react";
import classes from "./style/Menu.module.scss";
import BookIcon from "@atlaskit/icon/glyph/book";
import ShortcutIcon from "@atlaskit/icon/glyph/shortcut";
import ChildIssuesIcon from "@atlaskit/icon/glyph/child-issues";
import SettingsIcon from "@atlaskit/icon/glyph/settings";
import PeopleIcon from "@atlaskit/icon/glyph/people";
import BitbucketCommitsIcon from "@atlaskit/icon/glyph/bitbucket/commits";
import BitbucketCloneIcon from "@atlaskit/icon/glyph/bitbucket/clone";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import MenuItem from "./MenuItem";
import { JSX } from "react/jsx-runtime";
import { useParams } from "react-router-dom";
interface MenuInt {
  title: string;
  link: string;
  rightIcon?: JSX.Element;
}
const AppSettingMenu = () => {
  const { projectId } = useParams();
  const selectedMenu = useSelector(
    (state: RootState) => state.menu.selectedMenu
  );

  const menuIconHandler = (title: string) => {
    switch (title) {
      case "Deployment":
        return (
          <BitbucketCloneIcon
            label=""
            primaryColor={selectedMenu === "Deployment" ? "#0C66E4" : "#626F86"}
          />
        );
      case "Information":
        return (
          <SettingsIcon
            label=""
            primaryColor={
              selectedMenu === "Information" ? "#0C66E4" : "#626F86"
            }
          />
        );
      case "Members":
        return (
          <PeopleIcon
            label=""
            primaryColor={selectedMenu === "Members" ? "#0C66E4" : "#626F86"}
          />
        );
      case "Model history":
        return (
          <BitbucketCommitsIcon
            label=""
            primaryColor={
              selectedMenu === "Model history" ? "#0C66E4" : "#626F86"
            }
          />
        );
    }
  };

  const [menu, setMenu] = useState<MenuInt[]>([
    {
      title: "Deployment",
      link: `/app/deployment/${projectId}`,
    },
    {
      title: "Information",
      link: `/app/setting/${projectId}`,
    },
    {
      title: "Members",
      link: `/app/members/${projectId}`,
    },
    {
      title: "Model history",
      link: `/app/version-history/${projectId}`,
    },
  ]);

  return (
    <>
      <div className={classes.menuHeader}>
        <div className={classes.headerBox}>
          <span className={classes.headerTitle}>Application name</span>
        </div>
      </div>
      {menu?.map((item, index) => {
        return (
          <MenuItem
            key={index}
            title={item.title}
            leftIcon={menuIconHandler(item.title)}
            rightIcon={item?.rightIcon}
            link={item.link}
          />
        );
      })}
    </>
  );
};
export default AppSettingMenu;
