import BaseRepository from "../../../baseRepository/BaseRepository";
const resource = "/api/v1/codeInOne/Projects";
const favResource = "/api/v1/codeInOne/Favorites";
const ContributersResource = "/api/v1/codeInOne/Contributers";
const DeploymentsResource = "/api/v1/codeInOne/Deployments";
interface projectBody {
  tenantId: number;
  name: string;
  description: string;
  coverImage: string;
  contributers: Array<{ name: string; email: string; permission: 0 }>;
}
const applicationRepository = {
  getAllProjects(id: number) {
    return BaseRepository.get(`${resource}/GetAll?tenantId=${id}`);
  },
  getProjectById(id: number) {
    return BaseRepository.get(`${resource}/${id}`);
  },
  createProject(body: projectBody) {
    return BaseRepository.post(`${resource}`, body);
  },
  deleteProject(id: number) {
    return BaseRepository.delete(`${resource}?id=${id}`);
  },
  updateProject(body: projectBody) {
    return BaseRepository.put(`${resource}`, body);
  },
  cloneProject(body: { projectId: number; name: string }) {
    return BaseRepository.post(`${resource}/Clone`, body);
  },
  getFavoriteApplication(id: number) {
    return BaseRepository.get(`${favResource}/byTenant?tenantId=${id}`);
  },
  addToFavorite(body: { projectId: number }) {
    return BaseRepository.post(`${favResource}`, body);
  },
  unFavoriteApp(id: number) {
    return BaseRepository.delete(`${favResource}?projectId=${id}`);
  },
  getAllContributers(id: number) {
    return BaseRepository.get(`${ContributersResource}/GetAll?projectId=${id}`);
  },
  getContributerById(id: number) {
    return BaseRepository.get(`${ContributersResource}/${id}`);
  },
  addContributer(body: {
    projectId: number;
    name?: string;
    email?: string;
    active: boolean;
    permission: number;
  }) {
    return BaseRepository.post(`${ContributersResource}`, body);
  },
  deleteContributer(id: number) {
    return BaseRepository.delete(`${ContributersResource}?id=${id}`);
  },
  addDeployment(body: {
    projectId: number;
    tenantId: number;
    stackTemplate: {
      architecture: number;
      caching: number;
      dataBase: number;
      messaging: number;
      scheduler: number;
    };
    name: string;
    userId: number;
    repositoryURL: string;
    diagramId: number;
    diagramVersion: number;
  }) {
    return BaseRepository.post(`${DeploymentsResource}`, body);
  },
  editDeployment(body: {
    projectId: number;
    tenantId: number;
    stackTemplate: {
      architecture: number;
      caching: number;
      dataBase: number;
      messaging: number;
      scheduler: number;
    };
    name: string;
    userId: number;
    repositoryURL: string;
    diagramId: number;
    diagramVersion: number;
    deploymentId: number;
  }) {
    return BaseRepository.put(`${DeploymentsResource}`, body);
  },
  deleteDeployment(id: number) {
    return BaseRepository.delete(`${DeploymentsResource}?id=${id}`);
  },
  getAllDeployments(projectId: number) {
    return BaseRepository.get(
      `${DeploymentsResource}/GetProjectHistory?projectId=${projectId}`
    );
  },
  getDeploymentById(id: number) {
    return BaseRepository.get(`${DeploymentsResource}/${id}`);
  },
  appDeployed(body: {
    projectId: number;
    tenantId: number;
    repository: string;
    status: boolean;
  }) {
    return BaseRepository.put(`${DeploymentsResource}/Deployed`, body);
  },
};
export default applicationRepository;
