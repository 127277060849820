import classes from "./style/ApplicationHeader.module.scss";
import BaseButton from "../../../components/base/BaseButton";
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import SelectInput from "../../../components/base/SelectInput";
import { useNavigate } from "react-router-dom";
import { Option } from "../../../types/sharedTypes";
interface myProps {
  title?: string;
  defaultSort?: Option;
  sortOptions?: Option[];
  onSort?: (sort: Option) => void;
  page: string;
}
const ApplicationHeader = (props: myProps) => {
  const navigate = useNavigate();
  const addIcon = () => {
    return <EditorAddIcon label="" />;
  };
  const createNewApplication = () => {
    navigate(`/app/new?page=${props.page}`);
  };

  return (
    <div className={classes.headerLayout}>
      <div className={classes.headerBox}>
        <span className={classes.headerTitle}>
          {props.title ? props.title : "My Applications"}
        </span>
        <div className={classes.buttonBox}>
          <div className={classes.sortSection}>
            <SelectInput
              placeHolder="Sort By ..."
              defaultValue={props.defaultSort}
              options={props.sortOptions}
              handleselectedOption={(e) => {
                if (props.onSort) {
                  return props.onSort(e);
                }
              }}
              hasBlock={true}
            />
          </div>
          <div className={classes.buttonSection}>
            <BaseButton
              size="sm"
              title="Create Application"
              color="primary"
              hasRightIcon={false}
              hasLeftIcon={true}
              icon={addIcon()}
              onClickHandler={(e) => createNewApplication()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ApplicationHeader;
