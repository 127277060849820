import { createSlice } from "@reduxjs/toolkit";
import { number } from "prop-types";
import {
  ApplicationSortOptions,
  FavoriteApplicationSortOptions,
} from "../../../types/sharedTypes";
const applicationSlice = createSlice({
  name: "application",
  initialState: {
    selectedApp: {
      name: "",
      type: "",
      createAt: "",
      background: "",
      coverImage: "",
      id: "",
      description: "",
      contributers: [
        {
          id: "",
          name: "",
        },
      ],
    },
    applist: [],
    favoriteAppList: [],
    sortType: ApplicationSortOptions[0],
    selectedFolderApplist: [],
    favoriteSortType: FavoriteApplicationSortOptions[0],
    membersList: [
      { name: "", email: "", permissionType: 1, id: "", password: "" },
    ],
    selectedAppDetail: {
      diagramId: 0,
      name: "",
      project: {
        name: "",
        coverImage: "",
        description: "",
        id: 0,
        diagramId: 0,
        deployments: [
          {
            id: "",
            createDate: "",
            diagramVersion: 1,
            diagramId: 1,
            name: "",
            repositoryURL: "",
            techStack: {
              architecture: 0,
              caching: 0,
              dataBase: 0,
              messaging: 0,
              scheduler: 0,
            },
          },
        ],
      },
    },
    appDeployment: {
      id: "",
      name: "",
      techStack: {
        architecture: 0,
        caching: 0,
        dataBase: 0,
        messaging: 0,
        scheduler: 0,
      },
    },
  },
  reducers: {
    setSelectedApp(state, action) {
      state.selectedApp = action.payload;
    },
    setApplist(state, action) {
      state.applist = action.payload;
    },
    setFavoriteAppList(state, action) {
      state.favoriteAppList = action.payload;
    },
    setSelectedFolderApplist(state, action) {
      state.selectedFolderApplist = action.payload;
    },
    setSortType(state, action) {
      state.sortType = action.payload;
    },
    setFavoriteSortType(state, action) {
      state.favoriteSortType = action.payload;
    },
    setMembersList(state, action) {
      state.membersList = action.payload;
    },
    setSelectedAppDetail(state, action) {
      state.selectedAppDetail = action.payload;
    },
    setAppDeployment(state, action) {
      state.appDeployment = action.payload;
    },
  },
});
export const ApplicationAction = applicationSlice.actions;
export default applicationSlice.reducer;
