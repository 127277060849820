// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Setting_settingLayout__J0UWF {
  display: flex;
  align-self: stretch;
  padding: 24px 24px 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.Setting_headerBtn__TUb4h {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/modules/application/pages/appSetting/style/Setting.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,4BAAA;EACA,sBAAA;EACA,uBAAA;EACA,SAAA;AACF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;EACA,SAAA;AAEF","sourcesContent":[".settingLayout {\n  display: flex;\n  align-self: stretch;\n  padding: 24px 24px 16px 24px;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 24px;\n}\n.headerBtn {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  align-items: center;\n  gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingLayout": `Setting_settingLayout__J0UWF`,
	"headerBtn": `Setting_headerBtn__TUb4h`
};
export default ___CSS_LOADER_EXPORT___;
