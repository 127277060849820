import { useEffect, useState } from "react";
import classes from "./style/TemplatePanel.module.scss";
import { projectTemplate } from "../../../types/sharedTypes";
import EmptyFolder from "../../../core/helpers/EmptyFolder";
import { useDispatch, useSelector } from "react-redux";
import { loadingActions } from "../../../store/loading-slice";
import { errorActions } from "../../../store/error-slice";
import { RepoType } from "../../../types/sharedTypes";
import { AxiosError, AxiosResponse } from "axios";
import TemplateTile from "./TemplateTile";
import { RepoFactory } from "../../../baseRepository/Factory";
import { menuAction } from "../../../store/menu-slice";
import { folderActions } from "../../Folder/store/folder-slice";
import { templateAction } from "../store/template-slice";
import { RootState } from "../../../store";
const DiagramRepository = RepoFactory.get(RepoType.Diagram);

const TemplatePanel = () => {
  const dispatch = useDispatch();
  const templateList = useSelector(
    (state: RootState) => state.template.templateList
  );

  const getAllTemplate = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await DiagramRepository.getAllTemplates()
      .then((res: AxiosResponse) => {
        dispatch(templateAction.setTemplateList(res.data.value.tempaltes));
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };

  useEffect(() => {
    dispatch(menuAction.setSelectedMenu("Templates"));
    dispatch(folderActions.setSelectedFolderMenu(""));
    getAllTemplate();
  }, []);
  return (
    <div className={classes.panelBox}>
      {templateList.length > 0 ? (
        <>
          {templateList?.map((item: projectTemplate, index: number) => {
            return <TemplateTile template={item} key={index} />;
          })}
        </>
      ) : (
        <div className={classes.empty}>
          <EmptyFolder pageType="template" />
        </div>
      )}
    </div>
  );
};
export default TemplatePanel;
