import classes from "./style/FolderCreationForm.module.scss";
import Input from "../../../components/base/Input";
import BaseButton from "../../../components/base/BaseButton";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadingActions } from "../../../store/loading-slice";
import { errorActions } from "../../../store/error-slice";
import { RepoFactory } from "../../../baseRepository/Factory";
import { FolderType, RepoType } from "../../../types/sharedTypes";
import { AxiosError, AxiosResponse } from "axios";
import { RootState } from "../../../store";
import { folderActions } from "../store/folder-slice";
const FolderRepository = RepoFactory.get(RepoType.Folder);
interface MyComponent {
  folder?: FolderType;
  exitAction: (status: boolean) => void;
}
const FolderCreationForm = (props: MyComponent) => {
  const dispatch = useDispatch();
  const selectedWorkSpaceId = useSelector(
    (state: RootState) => state.auth.selectedWorkSpaceId
  );
  const sortType = useSelector((state: RootState) => state.folder.sortType);

  const [folderName, setFolderName] = useState(
    props.folder?.name ? props.folder.name : ""
  );

  const folderNameHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setFolderName(e.currentTarget.value);
  };
  const cancelHandler = () => {
    if (props.exitAction) return props.exitAction(false);
  };
  const exitHandler = () => {
    return props.exitAction(false);
  };
  const createFolder = async () => {
    if (folderName !== "") {
      dispatch(loadingActions.setHasLoading(true));
      dispatch(errorActions.setHasError(false));
      dispatch(errorActions.setError(""));
      let body = {
        tenantId: selectedWorkSpaceId,
        name: folderName,
      };
      await FolderRepository.createFolder(body)
        .then((res: AxiosResponse<any>) => {
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: "Folder Created Successfuly",
              statusCode: 200,
            })
          );
          getAllFolders();
        })
        .catch((error: AxiosError<any>) => {
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: error.response?.data
                ? error.response.data.Reasons[0].Message
                : error.message,
              statusCode: error.response?.status,
            })
          );
        })
        .finally(() => {
          dispatch(loadingActions.setHasLoading(false));
          exitHandler();
        });
    } else {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          message: "Please enter a name for Folder !",
          statusCode: 50,
        })
      );
    }
  };
  const updateFolder = async () => {
    if (folderName !== "") {
      dispatch(loadingActions.setHasLoading(true));
      dispatch(errorActions.setHasError(false));
      dispatch(errorActions.setError(""));
      let body = {
        name: folderName,
        folderId: props.folder?.id,
      };
      await FolderRepository.updateFolder(body)
        .then((res: AxiosResponse<any>) => {
          dispatch(folderActions.updateFolderList(res.data.value.folder));
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: "Folder Updated Successfuly",
              statusCode: 200,
            })
          );
          getAllFolders();
        })
        .catch((error: AxiosError<any>) => {
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: error.response?.data
                ? error.response.data.Reasons[0].Message
                : error.message,
              statusCode: error.response?.status,
            })
          );
        })
        .finally(() => {
          dispatch(loadingActions.setHasLoading(false));
          exitHandler();
        });
    } else {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          message: "Please enter a name for Folder !",
          statusCode: 50,
        })
      );
    }
  };
  const getAllFolders = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await FolderRepository.getAllFolders(selectedWorkSpaceId)
      .then((res: AxiosResponse<any>) => {
        dispatch(folderActions.setFolderList(res.data.value.folders));
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };

  return (
    <div className={classes.formContainer}>
      <div className={classes.headerBox}>
        <span className={classes.headerTitle}>
          {props.folder ? "Update Folder" : "Add Folder"}
        </span>
      </div>
      <div className={classes.formBody}>
        <Input
          name="folderName"
          size="lg"
          value={folderName}
          placeholder="Folder name"
          handleChange={(e) => folderNameHandler(e)}
        />
      </div>
      <div className={classes.formAction}>
        <BaseButton
          size="sm"
          color="outline"
          title="Cancel"
          hasRightIcon={false}
          hasLeftIcon={false}
          onClickHandler={(e) => cancelHandler()}
        />
        <BaseButton
          size="sm"
          title={props.folder ? "Update" : "Create"}
          color="primary"
          hasRightIcon={false}
          hasLeftIcon={false}
          onClickHandler={() =>
            props.folder ? updateFolder() : createFolder()
          }
        />
      </div>
    </div>
  );
};
export default FolderCreationForm;
