// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VersionHistory_historyLayout__z44wF {
  display: flex;
  align-self: stretch;
  padding: 24px 24px 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}`, "",{"version":3,"sources":["webpack://./src/modules/application/pages/appSetting/style/VersionHistory.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,4BAAA;EACA,sBAAA;EACA,uBAAA;EACA,SAAA;AACF","sourcesContent":[".historyLayout {\n  display: flex;\n  align-self: stretch;\n  padding: 24px 24px 16px 24px;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"historyLayout": `VersionHistory_historyLayout__z44wF`
};
export default ___CSS_LOADER_EXPORT___;
