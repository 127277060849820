import classes from "./style/EmptyFolder.module.scss";
import Illustration from "../../assets/icons/Illustration.png";
import FavoriteImage from "../../assets/images/FavoriteImage.svg";
import TemplateImage from "../../assets/images/TemplateImage.svg";
interface myProps {
  pageType?: string;
}
const EmptyFolder = (props: myProps) => {
  return (
    <div className={classes.emptyBox}>
      <img
        alt="empty image"
        src={
          props.pageType === "fav"
            ? FavoriteImage
            : props.pageType === "template"
            ? TemplateImage
            : Illustration
        }
        className={classes.icon}
      />
      <span className={classes.caption}>
        {props.pageType === "fav"
          ? "There is no favorities listed here."
          : props.pageType === "template"
          ? "There is no templates listed here."
          : " There is no applications listed here."}
      </span>
    </div>
  );
};
export default EmptyFolder;
