import { Route, Routes } from "react-router-dom";
import Templates from "../pages/Templates";

const TemplateRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Templates />} />
    </Routes>
  );
};
export default TemplateRouter;
