import { Version } from "../../types/type";
import classes from "./style/VersionTile.module.scss";
import BitbucketCommitsIcon from "@atlaskit/icon/glyph/bitbucket/commits";
import MoreIcon from "@atlaskit/icon/glyph/more";
import EditIcon from "@atlaskit/icon/glyph/edit";
import AddIcon from "@atlaskit/icon/glyph/add";
import BaseButton from "../../../../components/base/BaseButton";
import { IconColor } from "../../../../types/sharedTypes";
import Tag from "../../../../components/base/Tag";
import { TagColor } from "../../../../types/sharedTypes";
import { useState } from "react";
import Textarea from "../../../../components/base/Textarea";
import VersionOptions from "./VersionOptions";
import Modal from "../../../../components/base/Modal";
import VersionModal from "./VersionModal";
import { useRef } from "react";
import { useEffect } from "react";
const VersionTile = (props: Version) => {
  const versionOptionRef = useRef<HTMLDivElement>(null);
  const [descriptionFlag, setDescriptionFlag] = useState<boolean>(false);
  const [optionFlag, setOptionFlag] = useState<boolean>(false);
  const [modalFlag, setModalFlag] = useState<boolean>(false);
  const modalExitHandler = (status: boolean) => {
    setModalFlag(status);
  };
  const buttonIcon = () => {
    return <AddIcon label="" primaryColor={IconColor.blue} />;
  };
  const handleClickOutside = (event: MouseEvent) => {
    const { target } = event;
    if (
      versionOptionRef.current &&
      !versionOptionRef.current.contains(target as HTMLElement)
    ) {
      setOptionFlag(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [versionOptionRef]);
  return (
    <div className={classes.versionBox}>
      <div className={classes.versionNumber}>
        <div className={classes.version}>
          <BitbucketCommitsIcon label="" />
          <span className={classes.versionTitle}> V {props.versionNumber}</span>
          {props.current && (
            <Tag title="current version" color={TagColor.grey} size="sm" />
          )}
        </div>
        <div
          className={classes.option}
          onClick={() => {
            setOptionFlag(!optionFlag);
          }}
        >
          <MoreIcon label="" />
          {optionFlag && (
            <div className={classes.optionLayout} ref={versionOptionRef}>
              <VersionOptions
                onDelete={modalExitHandler}
                version={props.versionNumber}
                diagramId={props.diagramId}
                current={props.current ? true : false}
              />
            </div>
          )}
        </div>
      </div>
      <div className={classes.header}>
        <span className={classes.headerTitle}>{props.title}</span>
      </div>
      {props.description && descriptionFlag === false && (
        <div className={classes.description}>
          <span className={classes.descriptionTitle}>{props.description}</span>
          {/* <div
            className={classes.iconBox}
            onClick={() => setDescriptionFlag(true)}
          >
            <EditIcon label="" secondaryColor={IconColor.light_grey} />
          </div> */}
        </div>
      )}
      {/* {!props.description && descriptionFlag === false && (
        <BaseButton
          size="sm"
          title="Add description"
          color="primary-text"
          hasLeftIcon={true}
          icon={buttonIcon()}
          onClickHandler={() => setDescriptionFlag(true)}
        />
      )} */}
      {/* {descriptionFlag && (
        <div className={classes.descriptionForm}>
          <Textarea placeholder="please write youre Description" rows={3} />
          <BaseButton
            size="sm"
            title="save"
            color="secondary"
            onClickHandler={() => setDescriptionFlag(false)}
          />
        </div>
      )} */}
      {modalFlag && (
        <Modal
          body={
            <VersionModal
              version={props.versionNumber}
              diagramId={props.diagramId}
              onClose={modalExitHandler}
            />
          }
          exitAction={modalExitHandler}
        />
      )}
    </div>
  );
};
export default VersionTile;
