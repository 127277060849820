import axios from "axios";
const resource =
  process.env.REACT_APP_ADMINPANEL_API_BASE_DOMAIN +
  "api/v1/wTAdmin/UserPermissions";
const subscriptionResource =
  process.env.REACT_APP_ADMINPANEL_API_BASE_DOMAIN +
  "api/v1/wTAdmin/Subscriptions";
const subscriptionPlanResource =
  process.env.REACT_APP_ADMINPANEL_API_BASE_DOMAIN +
  "/api/v1/wTAdmin/SubscriptionPlans";

const config = {
  headers: { Authorization: `Bearer ${getCookie("access_token")}` },
};
function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts: any = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}
const AdminRepository = {
  getTeamMembers(subscriptionId: number) {
    return axios.get(
      `${resource}/GetTeamMembers?subscriptionId=${subscriptionId}`,
      config
    );
  },
  getSubscription(tenantId: number) {
    return axios.get(
      `${subscriptionResource}/GetSubscriptions?workspaceId=${tenantId}`,
      config
    );
  },
  getSubscriptionPlan(id: number) {
    return axios.get(`${subscriptionPlanResource}?productId=${id}`);
  },
};
export default AdminRepository;
