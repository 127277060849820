import classes from "./style/Deployment.module.scss";
import AppSettingLayout from "../../../../components/layouts/AppSettingLayout";
import DeploymentPanel from "../../components/setting/DeploymentPanel";
import HeaderLayout from "../../components/setting/HeaderLayout";
import BaseButton from "../../../../components/base/BaseButton";
import Modal from "../../../../components/base/Modal";
import PublishForm from "../../components/setting/PublishForm";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { menuAction } from "../../../../store/menu-slice";
const Deployment = () => {
  const [modalFlag, setModalFlag] = useState<boolean>(false);
  const dispatch = useDispatch();
  const modalExitHandler = (status: boolean) => {
    setModalFlag(status);
  };
  useEffect(() => {
    dispatch(menuAction.setSelectedMenu("Deployment"));
  }, []);
  return (
    <AppSettingLayout>
      <div className={classes.deployLayout}>
        <HeaderLayout
          type="Deployment"
          children={
            <BaseButton
              size="sm"
              title="New Publish"
              color="primary"
              onClickHandler={() => {
                setModalFlag(true);
              }}
            />
          }
        />
        <DeploymentPanel />
        {modalFlag && (
          <Modal
            body={<PublishForm onClose={modalExitHandler} />}
            exitAction={modalExitHandler}
          />
        )}
      </div>
    </AppSettingLayout>
  );
};
export default Deployment;
