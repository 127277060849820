import classes from "./style/AppInfoImage.module.scss";
import charmImage from "../../../../assets/images/selectCharmdb.svg";
import { RootState } from "../../../../store";
import { useSelector } from "react-redux";
import PageIcon from "@atlaskit/icon/glyph/page";
import ChildIssuesIcon from "@atlaskit/icon/glyph/child-issues";
import RefreshIcon from "@atlaskit/icon/glyph/refresh";
interface MyComponent {
  changeStep: (number: number) => void;
  changeStepItems: (items: any) => void;
  selectedWay: string;
}
const AppInfoImage = (props: MyComponent) => {
  const selectedDiagram = useSelector(
    (state: RootState) => state.diagram.selectedDiagram
  );
  const changeCharmDb = () => {
    changeStep(1);
    changeStepItemsToLast();
  };
  const changeStep = (number: number) => {
    if (props.changeStep) {
      props.changeStep(number);
    }
  };
  const changeStepItemsToLast = () => {
    props.changeStepItems([
      {
        id: "SelectModel",
        label: "Select model",
        percentageComplete: 0,
        status: "current",
        href: "#",
      },
      {
        id: "appInfo",
        label: "App info",
        percentageComplete: 0,
        status: "unvisited",
        href: "#",
      },
      {
        id: "Configuration",
        label: "Configuration",
        percentageComplete: 0,
        status: "unvisited",
        href: "#",
      },
      {
        id: "Design",
        label: "Design",
        percentageComplete: 0,
        status: "unvisited",
        href: "#",
      },
      {
        id: "Review",
        label: "Review",
        percentageComplete: 0,
        status: "unvisited",
        href: "#",
      },
    ]);
  };
  return (
    <div className={classes.image}>
      <div className={classes.selectedDiagramCard}>
        <div className={classes.header}>
          <PageIcon label="" />
          <span>Your Application</span>
        </div>
        <div className={classes.detail}>
          <div className={classes.icon}>
            <ChildIssuesIcon label="" />
          </div>
          <div className={classes.DataName}>{selectedDiagram.name}</div>
          <div className={classes.changes} onClick={(e) => changeCharmDb()}>
            <RefreshIcon label="" />
            <span>Reset</span>
          </div>
        </div>
      </div>

      <img src={charmImage} alt="charm db" />
    </div>
  );
};
export default AppInfoImage;
