import { createSlice } from "@reduxjs/toolkit";
import { FolderSortOptions } from "../../../types/sharedTypes";
const folderSLice = createSlice({
  name: "folder",
  initialState: {
    folderList: [],
    selectedFolder: {
      createDate: "",
      id: "",
      name: "",
      projects: [],
    },
    diagramsListByFolderId: [],
    selectedFolderMenu: "",
    sortType: FolderSortOptions[0],
  },
  reducers: {
    setSelectedFolder(state, action) {
      state.selectedFolder = action.payload;
    },
    setFolderList(state, action) {
      state.folderList = action.payload;
    },
    updateFolderList(state, action) {
      let tempList: any = [];
      state.folderList?.map((item: { id: number }) => {
        if (item.id === action.payload.id) {
          tempList.push(action.payload);
        } else {
          tempList.push(item);
        }
      });
      state.folderList = tempList;
    },
    setSortType(state, action) {
      state.sortType = action.payload;
    },
    setDiagramsListByFolderId(state, action) {
      state.diagramsListByFolderId = action.payload;
    },
    setSelectedFolderMenu(state, action) {
      state.selectedFolderMenu = action.payload;
    },
  },
});
export const folderActions = folderSLice.actions;
export default folderSLice.reducer;
