import classes from "./style/NewBlankModel.module.scss";
import DiagramCreationForm from "../../../Diagram/components/DiagramCreationForm";
interface myComponentProps {
  changeStep: (number: number) => void;
  changeStepItems: (items: any) => void;
}
const NewBlankModel = (props: myComponentProps) => {
  const openNewBlankProject = () => {
    changeStep(2);
    changeStepItems();
  };
  const changeStep = (number: number) => {
    if (props.changeStep) {
      props.changeStep(number);
    }
  };
  const changeStepItems = () => {
    props.changeStepItems([
      {
        id: "SelectModel",
        label: "Select model",
        percentageComplete: 100,
        status: "disabled",
        href: "#",
      },
      {
        id: "appInfo",
        label: "App info",
        percentageComplete: 0,
        status: "current",
        href: "#",
      },
      {
        id: "Configuration",
        label: "Configuration",
        percentageComplete: 0,
        status: "unvisited",
        href: "#",
      },
      {
        id: "Design",
        label: "Design",
        percentageComplete: 0,
        status: "unvisited",
        href: "#",
      },
      {
        id: "Review",
        label: "Review",
        percentageComplete: 0,
        status: "unvisited",
        href: "#",
      },
    ]);
  };
  const diagramHandler = (status: boolean) => {
    if (status) {
      openNewBlankProject();
    }
  };
  return (
    <div className={classes.NewBlankModel}>
      {/* <img src={image} alt="NewBlankModel"></img>
      <span className={classes.text}>
        To create a new blank data model, please select an options:
      </span>
      <a
        href={process.env.REACT_APP_CHARMDB_BASE_DOMAIN + "/diagrams"}
        target="_blank"
        className={classes.infoCard}
      >
        <div className={classes.header}>
          <LightbulbFilledIcon label="" primaryColor={IconColor.blue} />
          <h1 className={classes.text}>Build faster and easier with CharmDB</h1>
          <div className={classes.tag}>Recommended</div>
        </div>
        <div className={classes.description}>
          Free up your time and resources for what matters most - creating a
          better quality data model with CharmDB
        </div>
      </a>
      <div className={classes.blankCard} onClick={(e) => openNewBlankProject()}>
        <div className={classes.header}>
          <DocumentIcon label="" primaryColor={IconColor.blue} />
          <div className={classes.headerBlank}>Design a blank model</div>
        </div>
        <div className={classes.description}>
          Design new model view Design new model viewDesign new model viewDesign
          new model viewDesign new model view
        </div>
      </div> */}
      <DiagramCreationForm onSuccess={diagramHandler} />
    </div>
  );
};
export default NewBlankModel;
