import classes from "./style/Application.module.scss";
import DashboardLayout from "../../../components/layouts/DashboardLayout";
import ApplicationHeader from "../components/ApplicationHeader";
import FavoriteApplicationsPanel from "../components/FavoriteApplicationsPanel";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { menuAction } from "../../../store/menu-slice";
import { folderActions } from "../../Folder/store/folder-slice";
import { ApplicationAction } from "../store/application-slice";
import {
  FavoriteApplicationSortOptions,
  Option,
  SortFavoriteApp,
} from "../../../types/sharedTypes";
import { RootState } from "../../../store";
const FavoriteApplications = () => {
  const dispatch = useDispatch();
  const favoriteSortType = useSelector(
    (state: RootState) => state.application.favoriteSortType
  );
  const favoriteAppList = useSelector(
    (state: RootState) => state.application.favoriteAppList
  );
  const sortHandler = (status: Option) => {
    dispatch(ApplicationAction.setFavoriteSortType(status));
  };
  useEffect(() => {
    dispatch(menuAction.setSelectedMenu("Favorites"));
    dispatch(folderActions.setSelectedFolderMenu(""));
  }, []);
  useEffect(() => {
    dispatch(
      ApplicationAction.setFavoriteAppList(
        SortFavoriteApp(favoriteSortType, favoriteAppList)
      )
    );
  }, [favoriteSortType, dispatch]);

  return (
    <DashboardLayout>
      <div className={classes.appLayout}>
        <ApplicationHeader
          title="Favorites"
          defaultSort={favoriteSortType}
          sortOptions={FavoriteApplicationSortOptions}
          onSort={sortHandler}
          page="fav"
        />
        <FavoriteApplicationsPanel />
      </div>
    </DashboardLayout>
  );
};
export default FavoriteApplications;
