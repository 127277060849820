import classes from "./style/HeaderLayout.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { useNavigate } from "react-router-dom";
import { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { menuAction } from "../../../../store/menu-slice";
interface MyComponent {
  type: string;
  children?: ReactNode;
}
const HeaderLayout = (props: MyComponent) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedAppDetail = useSelector(
    (state: RootState) => state.application.selectedAppDetail
  );
  return (
    <div className={classes.headerLayout}>
      <div className={classes.addressBox}>
        <span
          style={{ cursor: "pointer" }}
          className={classes.addressLink}
          onClick={() => {
            dispatch(menuAction.setSelectedMenu("My Applications"));
            navigate("/app");
          }}
        >
          Application
        </span>
        <span className={classes.address}>/ </span>
        <span className={classes.address}>
          {selectedAppDetail.project.name}
        </span>
      </div>
      <div className={classes.contents}>
        <div className={classes.titleBox}>
          <span className={classes.pageTitle}>{props.type}</span>
        </div>
        <div className={classes.actionBox}>{props.children}</div>
      </div>
    </div>
  );
};
export default HeaderLayout;
