import { TickCircle } from "iconsax-react";
import classes from "./style/PlanForm.module.scss";
import BaseButton from "../../../components/base/BaseButton";
import PremiumIcon from "../../../assets/icons/PremiumIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { loadingActions } from "../../../store/loading-slice";
import { errorActions } from "../../../store/error-slice";
import { RepoFactory } from "../../../baseRepository/Factory";
import { RepoType } from "../../../types/sharedTypes";
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
interface subscriptionPlan {
  id: number;
  name: string;
  description: string;
  price: number;
  billingFrequency: number;
  billingFrequencyName: string;
  productId: number;
}

const AdminRepository = RepoFactory.get(RepoType.Admin);
const PlanForm = () => {
  const dispatch = useDispatch();
  const [subscriptionPlan, setSubscriptionPlan] = useState<subscriptionPlan>();
  const selectedWorkSpaceId = useSelector(
    (state: RootState) => state.auth.selectedWorkSpaceId
  );
  const upgradeIcon = () => {
    return <img alt="" src={PremiumIcon} />;
  };
  const getSubscription = async () => {
    console.log(selectedWorkSpaceId);

    let subscriptionId = "";
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await AdminRepository.getSubscription(selectedWorkSpaceId)
      .then((res: AxiosResponse<any>) => {
        console.log(res.data.value.subscription);

        subscriptionId = res.data.value.subscription.find(
          (subscription: any) => subscription.productName === "Code in One"
        ).subscriptionPlanId;
        getSubscriptionPlan(subscriptionId);
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data));
      });
  };
  const getSubscriptionPlan = async (subscriptionPlanId: string) => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await AdminRepository.getSubscriptionPlan(1)
      .then((res: AxiosResponse<any>) => {
        console.log(res.data.value.subscriptionPlan);
        console.log(subscriptionPlanId);

        let selectedSubscriptionPlan = res.data.value.subscriptionPlan.find(
          (subscription: any) => subscription.id === subscriptionPlanId
        );
        console.log(selectedSubscriptionPlan);

        setSubscriptionPlan(selectedSubscriptionPlan);
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data));
      });
  };
  useEffect(() => {
    if (selectedWorkSpaceId) {
      getSubscription();
    }
  }, [selectedWorkSpaceId]);
  return (
    <div className={classes.planLayout}>
      <span className={classes.planHeader}>Plan</span>
      <div className={classes.planCard}>
        <div className={classes.content}>
          <div className={classes.planType}>
            <span className={classes.typeTitle}>{subscriptionPlan?.name}</span>
            <div className={classes.priceBox}>
              <span
                className={classes.price}
              >{`${subscriptionPlan?.price} $`}</span>
              <span className={classes.planPeriod}>
                {subscriptionPlan?.billingFrequencyName}
              </span>
            </div>
          </div>
          <div className={classes.details}>
            <div className={classes.checkbox}>
              <TickCircle className={classes.checkIcon} />
              <span className={classes.checkText}>Support</span>
            </div>
            <div className={classes.line} />
            <div className={classes.checkbox}>
              <TickCircle className={classes.checkIcon} />
              <span className={classes.checkText}>200 GB internal space</span>
            </div>
            <div className={classes.line} />
            <div className={classes.checkbox}>
              <TickCircle className={classes.checkIcon} />
              <span className={classes.checkText}>500,000 users</span>
            </div>
          </div>
          <BaseButton
            title="Upgrade"
            color="outline"
            size="sm"
            hasLeftIcon={true}
            icon={upgradeIcon()}
          />
        </div>
      </div>
    </div>
  );
};
export default PlanForm;
