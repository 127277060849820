import classes from "./style/Header.module.scss";
import AppSwitcher from "../../assets/icons/app-switcher.svg";
import Logo from "../../assets/icons/CodeinoneLogo.svg";
import BaseButton from "../base/BaseButton";
import PremiumIcon from "@atlaskit/icon/glyph/premium";
import NotificationIcon from "@atlaskit/icon/glyph/notification";
import UserAvatarCircleIcon from "@atlaskit/icon/glyph/user-avatar-circle";
import AppsMenu from "./AppsMenu";
import ProfileMenu from "./ProfileMenu";
import Notification from "./Notification";
import { useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { RootState } from "../../store";
const Header = () => {
  const appMenuRef = useRef<HTMLDivElement>(null);
  const profileMenuRef = useRef<HTMLDivElement>(null);
  const notificationRef = useRef<HTMLDivElement>(null);
  const [appMenuFlag, setAppMenuFlag] = useState<boolean>(false);
  const [profileMenuFlag, setProfileMenuFlag] = useState<boolean>(false);
  const [notificationFlag, setNotificationFlag] = useState<boolean>(false);
  const [selectedLicensedWorkspace, setSelectedLicensedWorkspace] =
    useState<string>("");
  const selectedWorkSpaceId = useSelector(
    (state: RootState) => state.auth.selectedWorkSpaceId
  );
  const premiumIcon = () => {
    return <PremiumIcon label="" primaryColor="#F86F03" />;
  };
  const profileMenuHandler = () => {
    setProfileMenuFlag(!profileMenuFlag);
  };
  const notificationHandler = () => {
    setNotificationFlag(!notificationFlag);
  };
  const handleClickOutside = (event: MouseEvent) => {
    const { target } = event;
    if (
      appMenuRef.current &&
      !appMenuRef.current.contains(target as HTMLElement)
    ) {
      setAppMenuFlag(false);
    }
    if (
      profileMenuRef.current &&
      !profileMenuRef.current.contains(target as HTMLElement)
    ) {
      setProfileMenuFlag(false);
    }
    if (
      notificationRef.current &&
      !notificationRef.current.contains(target as HTMLElement)
    ) {
      setNotificationFlag(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [appMenuRef, profileMenuRef, notificationRef]);
  const appMenuModalHandler = () => {
    if (appMenuFlag === false) {
      setAppMenuFlag(true);
    } else {
      setAppMenuFlag(false);
    }
  };
  useEffect(() => {
    let workspace = localStorage.getItem("selectedLicensedWorkspace");
    if (workspace) setSelectedLicensedWorkspace(JSON.parse(workspace)?.name);
  }, [localStorage]);
  return (
    <div className={classes.headerContainer}>
      <div className={classes.leftPanel}>
        <div
          onClick={(e) => {
            appMenuModalHandler();
          }}
        >
          <img
            className={classes.AppIcon}
            alt="app-switcher"
            src={AppSwitcher}
          />
        </div>

        <img className={classes.logo} alt="logo" src={Logo} />
      </div>
      <div className={classes.rightPanel}>
        <a
          href={`${process.env.REACT_APP_ADMIN_BASE_DOMAIN}/your-license/${selectedLicensedWorkspace}/${selectedWorkSpaceId}`}
          target="_blank"
          className={classes.perBtn}
        >
          <BaseButton
            title="Premium"
            color="outline"
            hasRightIcon={false}
            hasLeftIcon={true}
            hasBlock={false}
            icon={premiumIcon()}
          ></BaseButton>
        </a>
        <div
          className={classes.notifIcon}
          onClick={() => notificationHandler()}
        >
          <NotificationIcon label="" size="large" primaryColor="#44546F" />
          {notificationFlag && (
            <div className={classes.notifDropDown} ref={notificationRef}>
              <Notification />
            </div>
          )}
        </div>

        <div className={classes.profile} onClick={() => profileMenuHandler()}>
          <UserAvatarCircleIcon label="" size="large" primaryColor="#44546F" />
          {profileMenuFlag && (
            <div className={classes.profileDropDown} ref={profileMenuRef}>
              <ProfileMenu />
            </div>
          )}
        </div>
      </div>
      {appMenuFlag && (
        <div className={classes.menuDropDown} ref={appMenuRef}>
          <AppsMenu />
        </div>
      )}
    </div>
  );
};
export default Header;
