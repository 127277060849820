import classes from "./style/CreateAppLayout.module.scss";
import { ReactNode } from "react";
import SimpleHeader from "../core/SimpleHeader";

interface LayoutProps {
  children: ReactNode;
}
const CreateAppLayout = ({ children }: LayoutProps) => {
  return (
    <div className={classes.layout}>
      <div className={classes.header}>
        <SimpleHeader />
      </div>

      <div className={classes.dashboardBody}>
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
};
export default CreateAppLayout;
