import classes from "./style/Textarea.module.scss";
import Form from "react-bootstrap/Form";
interface MyComponentProps {
  title?: string;
  size?: "sm" | "lg";
  placeholder?: string;
  id?: string | undefined;
  name?: string;
  value?: string | number;
  maxLength?: number;
  onChange?: ((e: React.FormEvent<HTMLInputElement>) => void) | undefined;
  disable?: boolean;
  pattern?: string;
  error?: boolean;
  handleChange?: ((e: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  required?: boolean;
  rows?: number;
  defaultValue?: string | number;
}
const TexrArea = (props: MyComponentProps) => {
  return (
    <div className={classes.textareaBox}>
      {props.title && (
        <Form.Label>
          {props.title}
          {props.required && <span className={classes.labelRequired}>*</span>}
          {/* {props.required === false && <span className={classes.labelRequired}>&nbsp;optional</span>} */}
        </Form.Label>
      )}
      <Form.Control
        defaultValue={props.defaultValue}
        rows={props.rows}
        className={classes.textArea}
        as="textarea"
        size={props.size}
        placeholder={props.placeholder}
        id={props.id ? props.id : Math.random() + "textarea"}
        value={props.value}
        disabled={props.disable}
        isInvalid={props.error}
        onChange={props.handleChange}
        required={props.required}
      />
    </div>
  );
};
export default TexrArea;
