import classes from "./style/VersionModal.module.scss";
import ErrorIcon from "@atlaskit/icon/glyph/error";
import { IconColor } from "../../../../types/sharedTypes";
import BaseButton from "../../../../components/base/BaseButton";
import { AxiosError, AxiosResponse } from "axios";
import { RepoFactory } from "../../../../baseRepository/Factory";
import { useDispatch } from "react-redux";
import { RepoType } from "../../../../types/sharedTypes";
import { errorActions } from "../../../../store/error-slice";
import { loadingActions } from "../../../../store/loading-slice";
import { diagramActions } from "../../../Diagram/store/diagram-slice";
const DiagramRepository = RepoFactory.get(RepoType.Diagram);

interface MyComponent {
  onClose: (status: boolean) => void;
  version: number;
  diagramId: number;
}
const VersionModal = (props: MyComponent) => {
  const dispatch = useDispatch();
  const closeHandler = () => {
    if (props.onClose) return props.onClose(false);
  };
  const RemoveVersion = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    let body = {
      version: props.version,
      diagramId: props.diagramId,
    };
    await DiagramRepository.RemoveVersion(body)
      .then((res: AxiosResponse) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: "deleted version",
            statusCode: 200,
          })
        );
        closeHandler();
        getDigramsVersionHistory();
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const getDigramsVersionHistory = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await DiagramRepository.getDigramsVersionHistory(props.diagramId)
      .then((res: AxiosResponse) => {
        dispatch(diagramActions.setSelectedDiagram(res.data.value.diagram));
      })
      .catch((error: AxiosError<any>) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };

  return (
    <div className={classes.versionModalLayout}>
      <div className={classes.header}>
        <ErrorIcon label="" primaryColor={IconColor.danger} />
        <span className={classes.headerTitle}></span>
      </div>
      <div className={classes.content}>
        <span className={classes.contentText}>
          Are you sure you wanted to delete this version?
        </span>
      </div>
      <div className={classes.modalFooter}>
        <BaseButton
          size="sm"
          title="Cancel"
          color="secondary"
          onClickHandler={() => closeHandler()}
        />
        <BaseButton
          size="sm"
          title="Yes,delte it"
          color="danger"
          onClickHandler={(e) => RemoveVersion()}
        />
      </div>
    </div>
  );
};
export default VersionModal;
